import React, { useMemo } from 'react';
import { PLUS } from '../../../icons';
import { useGetProcessQuery } from '../../../redux/ErpServices/processMasterServices';
import secureLocalStorage from 'react-secure-storage';
import { useGetArticleQuery } from '../../../redux/ErpServices/ArticleService';
import { useGetUnitOfMeasurementMasterQuery } from '../../../redux/ErpServices/UnitOfMeasurementServices';
import { priceWithTax } from '../../../Utils/helper';
import ProcessCostingItem from './ProcessCostingItem';
import { useGetStyleFabricTemplateByIdQuery } from '../../../redux/ErpServices/StyleFabricTemplateServices';
import { TRIMS_VALUE } from '../../../Constants/DefaultValues';

const ProcessCostingSet = ({
  styleItemDetails, setStyleItemDetails, currentSelectedStyleId, conversionValue, costingDetails, name, fillCols, readOnly, id, userDocDate
}) => {
  const isArticleShow = ["Yarn", "Trims"].includes(name)
  const isRawMaterial = ["Yarn", "Fabric"].includes(name)
  const isUom = ["Yarn", "Fabric", "Trims", "CMP"].includes(name)

  let params = {
    companyId: secureLocalStorage.getItem(
      sessionStorage.getItem("sessionId") + "userCompanyId"
    ),

  };
  let processParams;
  if (name === "Yarn") {
    processParams = { isYarn: true }
  } else if (name === "Fabric") {
    processParams = { isFabric: true }
  } else if (name === "Trims") {
    processParams = { isTrims: true }
  } else if (name === "CMP") {
    processParams = { isCmt: true }
  } else if (name === "Commercial") {
    processParams = { isCommercial: true }
  }


  const { data: processData } = useGetProcessQuery({ params: { ...params, ...processParams } });
  const { data: artData } = useGetArticleQuery({ params: { ...params, selectedType: name } });
  const { data: uomData } = useGetUnitOfMeasurementMasterQuery({ params });

  const process = processData?.data || [];
  const article = artData?.data || [];
  const uom = uomData?.data || [];
  const consumptionQty =
    useMemo(() => styleItemDetails[currentSelectedStyleId]?.consumptionQty, [styleItemDetails, currentSelectedStyleId]);

  const addRow = () => {
    setStyleItemDetails(prev => {
      let newItems = structuredClone(prev);
      let obj = { process: '', yarnName: '', uomId: isRawMaterial ? (uom.find(i => i.name === "KGS")?.id || '') : "", conversionValue: '', consumptionQty: isRawMaterial ? consumptionQty : 1, rate: '', amount: '', refetch: true }
      if (name === "Yarn") {
        obj["loss"] = newItems[currentSelectedStyleId]['fabricCostingDetails'].reduce((a, c) => (a + parseFloat(c?.loss || 0)), 0);
      } else if (name === "Trims") {
        obj["loss"] = TRIMS_VALUE;
      }
      newItems[currentSelectedStyleId][costingDetails] = [...(newItems[currentSelectedStyleId][costingDetails] || []), obj]
      return newItems;
    })
  };

  const deleteRow = (index) => {
    setStyleItemDetails(prev => {
      let newItems = structuredClone(prev);
      newItems[currentSelectedStyleId][costingDetails] = newItems[currentSelectedStyleId][costingDetails].filter((_, i) => index !== i);
      return newItems;
    })
  };

  const handleInputChange = (index, field, value) => {
    setStyleItemDetails(prev => {
      let newItems = structuredClone(prev);
      newItems[currentSelectedStyleId][costingDetails][index][field] = value;
      if (name === "Fabric") {
        if (newItems?.[currentSelectedStyleId]?.['yarnCostingDetails']) {
          const totalFabricLoss = newItems[currentSelectedStyleId][costingDetails].reduce((a, c) => (a + parseFloat(c?.loss || 0)), 0);
          newItems[currentSelectedStyleId]['yarnCostingDetails'] = newItems[currentSelectedStyleId]['yarnCostingDetails'].map((i, index) => {
            let item = structuredClone(i)
            item['loss'] = totalFabricLoss;
            return item
          })
        }
      }
      return newItems;
    })
  };

  let currentStyleItem = styleItemDetails[currentSelectedStyleId];

  const { data: styleFabricData } = useGetStyleFabricTemplateByIdQuery(currentStyleItem?.fabricId, { skip: (!currentStyleItem?.fabricId) || !(name === "Fabric") })

  const fabricId = styleFabricData?.data?.fabricId || null;

  const total = (currentStyleItem?.[costingDetails] || []).reduce((a, c) => a + priceWithTax(parseFloat(isRawMaterial ? consumptionQty : c.consumptionQty || 0) * parseFloat(c.rate || 0), parseFloat(c.loss || 0)), 0)

  let colCount = 6;
  if (isArticleShow) colCount++;
  if (isUom) colCount++;

  const totalFabricLoss = (currentStyleItem?.['fabricCostingDetails'] || []).reduce((a, c) => a + parseFloat(c?.loss || 0), 0)

  const totalYarnRate = (currentStyleItem?.['yarnCostingDetails'] || []).reduce((a, c) => a + parseFloat(c?.rate || 0), 0);
  const totalFabricRate = (currentStyleItem?.['fabricCostingDetails'] || []).reduce((a, c) => a + parseFloat(c?.rate || 0), 0);
  const totalRawMaterialRate = priceWithTax(totalYarnRate + totalFabricRate, totalFabricLoss);
  return (
    <fieldset className='frame p-1'>
      {/* <legend className='sub-heading'>{name}</legend> */}
      <div className='bg-[#388087] text-white text-center p-1'>{name}</div>
      <table className='w-full text-[11px]'>
        <thead>
          <tr className='h-[40px] bg-gray-300'>
            <th className="border border-gray-400 text-[11px] w-20">
              Process
            </th>
            {isArticleShow &&
              <th className="border border-gray-400 text-[11px] w-20">
                {name}
              </th>
            }
            {isUom &&
              <th className="border border-gray-400 text-[11px] w-10">
                UOM
              </th>
            }
            <th className="border border-gray-400 text-[11px] w-10">
              Con.Qty
            </th>
            <th className="border border-gray-400 text-[11px] w-10">
              Loss(%)
            </th>
            <th className="border border-gray-400 text-[11px] w-10">
              Rate
            </th>
            <th className="border border-gray-400 text-[11px] w-10">
              Amount
            </th>
            <th className="border border-gray-400 text-[11px] w-10">
              Loss.Amt
            </th>
            <th className="border border-gray-400 text-[11px] w-10">
              Total.Amt
            </th>
            {/* <th className="border border-gray-400 text-[11px] w-10">
              Conv. Value
            </th> */}
            {!readOnly
              &&
              <th className="border border-gray-400 text-[11px]  text-green-700 w-5" onClick={addRow}>{PLUS}</th>
            }
          </tr>
        </thead>
        <tbody >
          {(currentStyleItem?.[costingDetails] || []).map((row, index) => (
            <ProcessCostingItem key={index}
              name={name}
              fabricId={fabricId}
              index={index} handleInputChange={handleInputChange} readOnly={readOnly}
              userDocDate={userDocDate}
              article={article} uom={uom}
              row={row} isArticleShow={isArticleShow} isRawMaterial={isRawMaterial} isUom={isUom}
              consumptionQty={consumptionQty} deleteRow={deleteRow} process={process} id={id}
            />
          ))}
          <tr className='h-[40px]'>
            {(name === "Fabric") ?
              <>
                <td className="border border-gray-400 text-[11px] text-center font-bold" colSpan={4} >
                  <div className='grid grid-cols-2'>
                    <div className='text-right'>
                      Total Price -
                    </div>
                    <div className=''>
                      {totalRawMaterialRate.toFixed(3)}
                    </div>
                  </div>
                </td>
                <td className="border border-gray-400 text-[11px] text-center font-bold h-full " colSpan={3} >
                  <div className='grid grid-cols-2 h-full items-center'>
                    <div className=' '>
                      Total Loss-
                    </div>
                    <div>
                      {totalFabricLoss} %
                    </div>
                  </div>
                </td>
              </>
              :
              <td className="border border-gray-400 text-[11px] " colSpan={colCount} >
              </td>
            }
            <td className="border border-gray-400 text-[11px] text-right">
              {total.toFixed(3)}
            </td>
            {/* <td className=' border border-gray-400 text-right'>
              {(total * conversionValue).toFixed(3)}
            </td> */}
            {!readOnly
              &&
              <td className=' border border-gray-400'>
              </td>
            }
          </tr>

        </tbody>
      </table>
    </fieldset>
  );
};

export default ProcessCostingSet;
