import * as React from 'react';
import { COPY_ICON, TICK_ICON } from '../../../icons';

export default function DataTable({
  data,
  setFormula,
  setConsumptionTemplateDetails,
  consumptionTemplateDetails,
  readOnly
}) {

  const [currentActiveFieldIndex, setCurrentActiveFieldIndex] = React.useState({})


  function handleCopyPaste(name) {
    if (currentActiveFieldIndex) {
      setConsumptionTemplateDetails(prev => {
        let newPrev = structuredClone(prev);
        let index = prev.findIndex(i => parseInt(i.fieldId) === parseInt(currentActiveFieldIndex.fieldId))
        newPrev[index]["formula"] = `${newPrev[index]["formula"] || ''} ({${name}})`
        return newPrev
      })
    } else {
      setFormula(prev => `${prev} ({${name}})`)
    }
  }
  function addProcess(id) {

    setConsumptionTemplateDetails(prev => [...prev, { fieldId: id }])
  }
  function removeProcess(id) {

    setConsumptionTemplateDetails(prev => prev.filter(i => parseInt(i.fieldId) !== parseInt(id)))
  }


  function isAllSelected() {
    return data.every(i => isFieldSelected(parseInt(i.id)))
  }
  function selectAll() {
    if (isAllSelected()) {
      data.forEach(field => {
        removeProcess(field.id)
      });
    } else {
      data.forEach(field => {
        addProcess(field.id)
      });
    }
  }
  function isFieldSelected(id) {
    return consumptionTemplateDetails.findIndex(i => parseInt(i.fieldId) === parseInt(id)) !== -1
  }
  function handleChange(id) {
    if (isFieldSelected(id)) {
      removeProcess(id)
    } else {
      addProcess(id)
    }
  }
  function handleInputChange(fieldId, field, value) {
    setConsumptionTemplateDetails(prev => {
      let index = prev.findIndex(i => parseInt(i.fieldId) === parseInt(fieldId))
      let newItems = structuredClone(prev);
      newItems[index][field] = value;
      return newItems
    })

  }

  return (
    <div>
      <fieldset className='frame '>
        <legend className='sub-heading'>Process List</legend>
        <div className='grid grid-cols-1'>
          <table className='w-full h-full '>
            <thead className='thead'>
              <tr className='table-row border-b border-gray-500'>
                <td className='table-data'>
                  <div className='flex flex-col items-center justify-center'>
                    <span>
                      Mark All
                    </span>
                    <span>
                      <input type="checkbox" name="" id="" checked={isAllSelected()} onChange={selectAll} />
                    </span>
                  </div>
                </td>
                <td className='text-center table-data'>Fields</td>
                <td className='text-center table-data w-[500px]' >Formula</td>
                <td className='text-center table-data w-[100px]' >Sequence</td>
                <td className='text-center table-data w-[100px]' >Default Value</td>
                {!readOnly &&
                  <td className='text-center table-data'>Copy</td>
                }
              </tr>
            </thead>
            <tbody>
              {data.map(field =>
                <tr key={field.id} className='table-row'>
                  <td className='flex items-center justify-center table-data'>
                    <input type="checkbox" name="" id="" checked={isFieldSelected(field.id)} onChange={() => { handleChange(field.id) }} />
                  </td>
                  <td>
                    {field.name}
                  </td>
                  <td >
                    {isFieldSelected(field.id) &&
                      <>
                        <input className='w-full' value={consumptionTemplateDetails.find(i => i.fieldId === field.id)?.formula || ""}
                          onChange={(e) => { handleInputChange(field.id, "formula", e.target.value) }}
                          onBlur={() => { if (currentActiveFieldIndex) { currentActiveFieldIndex.e.target.focus() } }}
                          onClick={(e) => { setCurrentActiveFieldIndex({ fieldId: field.id, e }) }}
                          disabled={readOnly}
                        />
                      </>
                    }
                  </td>
                  <td >
                    {isFieldSelected(field.id) &&
                      <>
                        <input className='w-full' value={consumptionTemplateDetails.find(i => i.fieldId === field.id)?.sequence || ""}
                          onChange={(e) => { handleInputChange(field.id, "sequence", e.target.value) }}
                          disabled={readOnly}
                        />
                      </>
                    }
                  </td>
                  <td >
                    {isFieldSelected(field.id) &&
                      <>
                        <input className='w-full' value={consumptionTemplateDetails.find(i => i.fieldId === field.id)?.defaultValue || ""}
                          onChange={(e) => { handleInputChange(field.id, "defaultValue", e.target.value) }}
                          disabled={readOnly}
                        />
                      </>
                    }
                  </td>
                  {!readOnly &&
                    <td>
                      {(currentActiveFieldIndex && currentActiveFieldIndex.fieldId === field.id)
                        ?
                        <button className='bg-green-200 w-full h-full'
                          onClick={() => setCurrentActiveFieldIndex(null)}
                          disabled={readOnly}
                        >
                          {TICK_ICON}
                        </button>
                        :
                        <button className='w-full h-full' onClick={() => handleCopyPaste(field.name)}>
                          {COPY_ICON}
                        </button>
                      }
                    </td>
                  }
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </fieldset>
    </div>
  );
}
