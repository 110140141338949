import React, { useEffect, useState, useRef, useCallback } from "react";
import secureLocalStorage from "react-secure-storage";
import {
    useGetSizeMasterQuery,
} from "../../../redux/ErpServices/SizeMasterServices";

import {
    useGetSizeTemplateQuery,
    useGetSizeTemplateByIdQuery,
    useAddSizeTemplateMutation,
    useUpdateSizeTemplateMutation,
    useDeleteSizeTemplateMutation
} from "../../../redux/ErpServices/SizeTemplateMasterServices";


import FormHeader from "../../../Basic/components/FormHeader";
import FormReport from "../../../Basic/components/FormReportTemplate";
import { toast } from "react-toastify";
import { TextInput, CheckBox } from "../../../Inputs";
import ReportTemplate from '../../../Basic/components/ReportTemplate'
import { Loader } from "../../../Basic/components";
import { findFromList } from "../../../Utils/helper";

const MODEL = "Size Template Master";

export default function Form() {
    const [form, setForm] = useState(false);

    const [readOnly, setReadOnly] = useState(false);
    const [id, setId] = useState("");
    const [name, setName] = useState("");
    const [selectedSizeList, setSelectedSizeList] = useState([]);
    const [active, setActive] = useState(true);
    const [count, setCount] = useState(0);

    const [searchValue, setSearchValue] = useState("");
    const childRecord = useRef(0);

    const params = {
        companyId: secureLocalStorage.getItem(
            sessionStorage.getItem("sessionId") + "userCompanyId"
        ),
    };

    const { data: sizeList, isLoading: isSizeLoading, isFetching: isSizeFetching } = useGetSizeMasterQuery({ params, searchParams: searchValue });


    const { data: allData, isLoading, isFetching } = useGetSizeTemplateQuery({ params, searchParams: searchValue });
    const {
        data: singleData,
        isFetching: isSingleFetching,
        isLoading: isSingleLoading,
    } = useGetSizeTemplateByIdQuery(id, { skip: !id });

    const [addData] = useAddSizeTemplateMutation();
    const [updateData] = useUpdateSizeTemplateMutation();
    const [removeData] = useDeleteSizeTemplateMutation();

    const syncFormWithDb = useCallback(
        (data) => {
            if (id) setReadOnly(true);
            setName(data?.name ? data.name : "");
            setCount(data?.count || 0);
            setSelectedSizeList(data?.SizeTemplateOnSize ? data.SizeTemplateOnSize.map(item => {
                return { label: findFromList(item.sizeId, sizeList ? sizeList.data : [], "name"), value: item.sizeId }
            }
            ) : []);
            setActive(id ? (data?.active ? data.active : false) : true);
        },
        [id, sizeList]
    );

    useEffect(() => {
        syncFormWithDb(singleData?.data);
    }, [isSingleFetching, isSingleLoading, id, syncFormWithDb, singleData]);

    const data = {
        id, name, active, companyId: secureLocalStorage.getItem(sessionStorage.getItem("sessionId") + "userCompanyId"),
        selectedSizeList: selectedSizeList.map(item => item.value), count
    }

    const validateData = (data) => {
        if (data.name && data.count) {
            return true;
        }
        return false;
    }

    const handleSubmitCustom = async (callback, data, text) => {
        try {
            let returnData = await callback(data).unwrap();
            setId(returnData.data.id)
            toast.success(text + "Successfully");
        } catch (error) {
            console.log("handle");
        }
    };

    const saveData = () => {
        if (!validateData(data)) {
            toast.info("Please fill all required fields...!", {
                position: "top-center",
            });
            return;
        }
        if (!window.confirm("Are you sure save the details ...?")) {
            return;
        }
        if (id) {
            handleSubmitCustom(updateData, data, "Updated");
        } else {
            handleSubmitCustom(addData, data, "Added");
        }
    };

    const deleteData = async () => {
        if (id) {
            if (!window.confirm("Are you sure to delete...?")) {
                return;
            }
            try {
                await removeData(id);
                setId("");
                toast.success("Deleted Successfully");
            } catch (error) {
                toast.error("something went wrong");
            }
        }
    };

    const handleKeyDown = (event) => {
        let charCode = String.fromCharCode(event.which).toLowerCase();
        if ((event.ctrlKey || event.metaKey) && charCode === "s") {
            event.preventDefault();
            saveData();
        }
    };

    const onNew = () => {
        setId("");
        setForm(true);
        setSearchValue("");
        syncFormWithDb(undefined);
        setReadOnly(false);
    };

    function onDataClick(id) {
        setId(id);
        setForm(true);
    }

    const tableHeaders = [
        "Name", "Status"
    ];
    const tableDataNames = ["dataObj.name", 'dataObj.active ? ACTIVE : INACTIVE'];

    if (!form)
        return (
            <ReportTemplate
                heading={MODEL}
                tableHeaders={tableHeaders}
                tableDataNames={tableDataNames}
                loading={
                    isLoading || isFetching
                }
                setForm={setForm}
                data={allData?.data}
                onClick={onDataClick}
                onNew={onNew}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
            />
        );

    if (!sizeList || !allData || isLoading || isFetching) return <Loader />;


    const sizeOptions = sizeList?.data.map(item => ({ label: item.name, value: item.id })) || [];

    return (
        <div
            onKeyDown={handleKeyDown}
            className="md:items-start md:justify-items-center grid h-full bg-theme"
        >
            <div className="flex flex-col frame w-full h-full">
                <FormHeader
                    onNew={onNew}
                    onClose={() => {
                        setForm(false);
                        setSearchValue("");
                    }}
                    model={MODEL}
                    saveData={saveData}
                    setReadOnly={setReadOnly}
                    deleteData={deleteData}
                />
                <div className="flex-1 grid grid-cols-1 md:grid-cols-4 gap-x-2 overflow-clip">
                    <div className="col-span-3 grid md:grid-cols-2 border overflow-auto">
                        <div className='col-span-3 grid md:grid-cols-2 border overflow-auto'>
                            <div className='mr-1 md:ml-2'>
                                <fieldset className='frame my-1'>
                                    <legend className='sub-heading'>Size Info</legend>
                                    <div className='grid grid-cols-1 my-2'>
                                        <TextInput name="Size" type="text" value={name} setValue={setName} required={true} readOnly={readOnly} disabled={(childRecord.current > 0)} />
                                        <TextInput name="Count" type="number" value={count} setValue={setCount} required={true} readOnly={readOnly} disabled={(childRecord.current > 0)} />
                                        {/* <MultiSelectDropdown name={"Size"} selected={selectedSizeList} setSelected={setSelectedSizeList}
                                            options={sizeOptions} readOnly={readOnly} /> */}
                                        <CheckBox name="Active" readOnly={readOnly} value={active} setValue={setActive} />

                                    </div>
                                </fieldset>

                            </div>

                        </div>
                    </div>
                    <div className="frame hidden md:block overflow-x-hidden">
                        <FormReport
                            searchValue={searchValue}
                            setSearchValue={setSearchValue}
                            setId={setId}
                            tableHeaders={tableHeaders}
                            tableDataNames={tableDataNames}
                            data={allData?.data}
                            loading={
                                isSizeLoading || isSizeFetching
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
