import React from 'react'
import { DropdownInput, MultiSelectDropdown } from '../../Inputs';
import { dropDownListObject, multiSelectOption } from '../../Utils/contructObject';
import { getCommonParams } from '../../Utils/helper';
import { useGetRatioMasterQuery } from '../../redux/ErpServices/RatioMasterServices';
import { useGetSizeTemplateByIdQuery } from '../../redux/ErpServices/SizeTemplateMasterServices';

const RatioDropdown = ({ multiSelect = true, withoutLabel = true, readOnly, name, selected, setSelected, sizeTemplateId }) => {
    const { token, ...params } = getCommonParams();
    const { data: sizeTemplateData } = useGetSizeTemplateByIdQuery(sizeTemplateId, { skip: !sizeTemplateId })
    const { data } = useGetRatioMasterQuery({ params });
    let ratioList = data?.data || [];
    if (sizeTemplateData?.data?.count) {
        ratioList = ratioList.filter(i => parseInt(i?.count) === parseInt(sizeTemplateData?.data?.count))
    }
    return (
        <>
            {withoutLabel ?
                <select value={selected} onChange={(e) => setSelected(e.target.value)} className='w-full table-data-input'>
                    <option value="">Select</option>
                    {(ratioList || []).map(item =>
                        <option key={item.id} value={item.id}>{item.name}</option>
                    )}
                </select>
                :
                <>
                    {multiSelect ?
                        <MultiSelectDropdown readOnly={readOnly} name={name} selected={selected} setSelected={setSelected}
                            options={multiSelectOption(ratioList ? ratioList : [], "name", "id")} />
                        :
                        <DropdownInput readOnly={readOnly} name={name} value={selected} setValue={setSelected}
                            options={dropDownListObject(ratioList ? ratioList : [], "name", "id")} />
                    }
                </>
            }
        </>
    )
}

export default RatioDropdown
