import React, { useEffect, useState } from 'react'
import { useGetYarnMasterQuery } from "../../../redux/ErpServices/YarnMasterServices";
import { useGetUnitOfMeasurementMasterQuery } from "../../../redux/ErpServices/UnitOfMeasurementServices";
import secureLocalStorage from 'react-secure-storage';
import { Loader } from '../../../Basic/components';
import { useGetColorMasterQuery } from '../../../redux/ErpServices/ColorMasterServices';
import { findFromList, getDateFromDateTime, getItemFullNameFromShortCode, pageNumberToReactPaginateIndex, reactPaginateIndexToPageNumber, substract, sumArray } from '../../../Utils/helper';
import { useGetProcessQuery } from '../../../redux/ErpServices/processMasterServices';
import { useGetStockQuery } from '../../../redux/ErpServices/StockServices';
import ReactPaginate from 'react-paginate';
import { showEntries } from '../../../Utils/DropdownData';


const YarnStockFillGrid = ({ setLocalRawMaterials, getIssuedProperty, rawMaterialType, localRawMaterials, storeId, id }) => {
    const [searchColor, setSearchColor] = useState("");
    const [searchUom, setSearchUom] = useState("");
    const [searchPrevProcess, setSearchPrevProcess] = useState("");
    const [searchYarnAliasName, setSearchYarnAliasName] = useState("");
    const [searchLotNo, setSearchLotNo] = useState("");
    const [dataPerPage, setDataPerPage] = useState("10");
    const [totalCount, setTotalCount] = useState(0);
    const [currentPageNumber, setCurrentPageNumber] = useState(1);

    function addItem(item) {
        setLocalRawMaterials(localInwardItems => {
            let newItems = structuredClone(localInwardItems);
            newItems.push(item);
            return newItems
        });
    }
    function removeItem(removeItem) {
        setLocalRawMaterials(localInwardItems => {
            return localInwardItems.filter(item =>
                !(removeItem.yarnId === item.yarnId
                    &&
                    removeItem.colorId === item.colorId
                    &&
                    removeItem.uomId === item.uomId
                    &&
                    removeItem.lotNo == item.lotNo
                    &&
                    removeItem.processId === item.processId
                    &&
                    removeItem.storeId === item.storeId
                )
            )
        });
    }

    function isItemChecked(checkItem) {
        let item = localRawMaterials.find(item =>
            checkItem.yarnId === item.yarnId
            &&
            checkItem.colorId === item.colorId
            &&
            checkItem.uomId === item.uomId
            &&
            checkItem.lotNo == item.lotNo
            &&
            checkItem.processId === item.processId
            &&
            checkItem.storeId === item.storeId
        )
        if (!item) return false
        return true
    }

    const companyId = secureLocalStorage.getItem(
        sessionStorage.getItem("sessionId") + "userCompanyId"
    )
    const branchId = secureLocalStorage.getItem(
        sessionStorage.getItem("sessionId") + "currentBranchId"
    )
    const searchFields = { searchColor, searchUom, searchLotNo, searchPrevProcess, searchYarnAliasName }

    const { data: stockData, isLoading, isFetching
    } = useGetStockQuery({
        params: {
            branchId, itemType: rawMaterialType, isGetStock: true,
            ...searchFields,
            pagination: true,
            pageNumber: currentPageNumber,
            dataPerPage,
            storeId,
            rawMaterialSalesId: id
        }
    });

    useEffect(() => {
        if (stockData?.totalCount) {
            setTotalCount(stockData?.totalCount)
        }
    }, [stockData, isLoading, isFetching])


    const params = {
        companyId: secureLocalStorage.getItem(
            sessionStorage.getItem("sessionId") + "userCompanyId"
        )
    }
    const { data: yarnList, isLoading: yarnListLoading, isFetching: yarnListFetching } = useGetYarnMasterQuery({ params })
    const { data: uomList, isLoading: uomLoading, isFetching: uomFetching } = useGetUnitOfMeasurementMasterQuery({ params })
    const { data: colorList, isLoading: colorLoading, isFetching: colorFetching } = useGetColorMasterQuery({ params })
    const { data: processList, isLoading: isProcessLoading, isFetching: isProcessFetching } = useGetProcessQuery({ params });

    if (yarnListLoading || yarnListFetching || uomFetching || uomLoading ||
        colorFetching || colorLoading || isProcessFetching || isProcessLoading) {
        return <tr>
            <td>
                <Loader />
            </td>
        </tr>
    }

    function handleCheckBoxChange(value, item) {
        console.log(item, "item ")
        if (value) {
            addItem(item)
        } else {
            removeItem(item)
        }
    }

    function handleSelectAllChange(value) {
        if (value) {
            (stockData?.data ? stockData.data : []).forEach(item => addItem(item))
        } else {
            (stockData?.data ? stockData.data : []).forEach(item => removeItem(item))
        }
    }

    function getSelectAll() {
        return (stockData?.data ? stockData.data : []).every(item => isItemChecked(item))
    }


    let count = 1;
    return (
        <div className='h-full'>
            <div className='table-data'>
                <div className="md:flex md:items-center md:justify-between page-heading p-1">
                    <div className="heading text-center md:mx-10 text-xs"> Store Items</div>
                    <div className=" sub-heading justify-center md:justify-start items-center">
                        <label className="text-white text-xs rounded-md m-1  border-none">Show Entries</label>
                        <select value={dataPerPage}
                            onChange={(e) => setDataPerPage(e.target.value)} className='h-6 w-40 border border-gray-500 rounded mr-9'>
                            {showEntries.map((option) => <option value={option.value} >{option.show}</option>)}
                        </select>
                    </div>
                </div>
                <table className='table-fixed w-full text-xs max-h-full'>
                    <thead className='sticky top-0'>
                        <tr className='bg-blue-200'>
                            <th className='w-12'>
                                Mark All
                                <input type="checkbox" className='w-full' onChange={(e) => handleSelectAllChange(e.target.checked)}
                                    checked={getSelectAll()}
                                />
                            </th>
                            <th className='table-data  w-12'>
                                S.no
                            </th>
                            <th className='table-data '>
                                Yarn Name
                            </th>
                            <th className='table-data  w-32'>
                                Color
                            </th>
                            <th className='table-data  w-16'>
                                Uom
                            </th>
                            <th className='table-data  w-16'>
                                Lot No.
                            </th>
                            <th className='table-data  w-16'>
                                Prev. Process
                            </th>
                            <th className='table-data  w-16'>
                                Stock Bags
                            </th>
                            <th className='table-data  w-16'>
                                Stock qty
                            </th>
                        </tr>
                        <tr className='table-row bg-gray-500'>
                            <td className=' table-data '>
                            </td>
                            <td className=' table-data'>
                            </td>
                            <td className=' table-data  w-full'>
                                <input className='w-full p-2 table-data-input border ' onFocus={(e) => e.target.select()} type="text" placeholder='Search'
                                    onChange={(e) => {
                                        setSearchYarnAliasName(e.target.value)
                                    }} />
                            </td>
                            <td className=' table-data  '>
                                <input className='w-full p-2 table-data-input border' type="text"
                                    onFocus={(e) => e.target.select()}
                                    onChange={(e) => {
                                        setSearchColor(e.target.value)
                                    }}
                                />
                            </td>
                            <td className=' table-data  '>
                                <input className='w-full p-2 table-data-input border' type="text"
                                    onFocus={(e) => e.target.select()}
                                    onChange={(e) => {
                                        setSearchUom(e.target.value);
                                    }}
                                />
                            </td>
                            <td className=' table-data  '>
                                <input className='w-full p-2 table-data-input border' type="text"
                                    onFocus={(e) => e.target.select()}
                                    onChange={(e) => {
                                        setSearchLotNo(e.target.value);
                                    }}
                                />
                            </td>
                            <td className=' table-data  '>
                                <input className='w-full p-2 table-data-input border' type="text"
                                    onFocus={(e) => e.target.select()}
                                    onChange={(e) => {
                                        setSearchPrevProcess(e.target.value);
                                    }}
                                />
                            </td>
                            <td className=''>
                            </td>
                            <td className=''>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        {(stockData?.data ? stockData.data : []).map((item, index) =>
                            <tr key={index} className='table-row' onClick={() => {
                                handleCheckBoxChange(!isItemChecked(item), {
                                    ...item, stockQty: item._sum.qty,
                                    stockBags: item._sum.noOfBags, stockPrice: item.price, price: 0, taxPercent: 0
                                })
                            }}>
                                <td className='table-data'>
                                    <input type="checkbox" className='w-full table-data-input'
                                        checked={isItemChecked(item)} />
                                </td>
                                <td className=' table-data  '>
                                    {count++}
                                </td>
                                <td className=' table-data  '>
                                    {findFromList(item.yarnId, yarnList.data, "aliasName")}
                                </td>
                                <td className=' table-data  '>
                                    {findFromList(item.colorId, colorList.data, "name")}
                                </td>
                                <td className=' table-data  '>
                                    {findFromList(item.uomId, uomList.data, "name")}
                                </td>
                                <td className=''>
                                    {item.lotNo}
                                </td>
                                <td className=' px-1 table-data shadow-xl '>
                                    {findFromList(item.processId, processList.data, "name")}
                                </td>
                                <td className=' table-data  text-right'>
                                    {substract(item._sum.noOfBags, getIssuedProperty(item, "noOfBags")).toFixed(3)}
                                </td>
                                <td className=' table-data  text-right'>
                                    {substract(item._sum.qty, getIssuedProperty(item, "qty")).toFixed(3)}
                                </td>
                            </tr>
                        )}
                        <tr className='bg-blue-200 w-full font-bold bottom-0 sticky'>
                            <td className="table-data   w-10"></td>
                            <td className="table-data   w-10"></td>
                            <td className="table-data   w-10"></td>
                            <td className="table-data   w-10"></td>
                            <td className=''></td>
                            <td className=''></td>
                            <td className="table-data   w-10 font-bold">Total</td>
                            <td className="table-data   w-10 text-right">
                                {substract(
                                    sumArray(localRawMaterials.map(item => ({ noOfBags: item?._sum?.noOfBags ? item?._sum.noOfBags : 0 })), "noOfBags")
                                    , sumArray(localRawMaterials.map(item => ({ issueQty: getIssuedProperty(item, "noOfBags") })), "issueQty")
                                ).toFixed(3)}
                            </td>
                            <td className="table-data   w-10 text-right">
                                {substract(sumArray(localRawMaterials.map(item => ({ qty: item?._sum?.qty ? item?._sum.qty : 0 })), "qty")
                                    , sumArray(localRawMaterials.map(item => ({ issueQty: getIssuedProperty(item, "qty") })), "issueQty")
                                ).toFixed(3)}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                forcePage={pageNumberToReactPaginateIndex(currentPageNumber)}
                pageCount={Math.ceil(totalCount / dataPerPage)}
                marginPagesDisplayed={1}
                onPageChange={(e) => {
                    setCurrentPageNumber(reactPaginateIndexToPageNumber(e.selected));
                }}
                containerClassName={"flex justify-center mt-10 gap-5 items-center w-full absolute bottom-14 z-10"}
                pageClassName={"border custom-circle text-center"}
                disabledClassName={"p-1 bg-gray-200"}
                previousLinkClassName={"border p-1 text-center"}
                nextLinkClassName={"border p-1"}
                activeClassName={"bg-[#388087] text-white px-2"} />
        </div>

    )
}

export default YarnStockFillGrid