import React, { useEffect, useMemo } from 'react'
import { PLUS } from '../../../icons'
import { capitalizeFirstLetter, findFromList, getCommonParams } from '../../../Utils/helper';
import { useGetStyleMasterQuery } from '../../../redux/ErpServices/StyleMasterServices';
import { isNumeric } from 'validator';
import StyleItem from './StyleItem';
import { useGetStyleFabricTemplateQuery } from '../../../redux/ErpServices/StyleFabricTemplateServices';
import { useGetArticleQuery } from '../../../redux/ErpServices/ArticleService';
import { useGetProcessQuery } from '../../../redux/ErpServices/processMasterServices';
import { useGetUnitOfMeasurementMasterQuery } from '../../../redux/ErpServices/UnitOfMeasurementServices';
import { TRIMS_VALUE } from '../../../Constants/DefaultValues';

const StyleItems = ({ styleItemDetails, setStyleItemDetails, readonly, currentSelectedStyleId, setCurrentSelectedStyleId, templateDetails, id, singleData }) => {
    const { token, ...params } = getCommonParams();
    const { data: styleData } = useGetStyleMasterQuery({ params });
    const { data: styleFabricData } = useGetStyleFabricTemplateQuery({ params });

    const templateFields = useMemo(() => templateDetails?.data?.ConsumptionTemplateDetails || [], [templateDetails]);

    const { data: articleData } = useGetArticleQuery({ params: { ...params, selectedType: 'Trims' } })
    const { data: processData } = useGetProcessQuery({ params: { ...params } })
    const { data: uomData } = useGetUnitOfMeasurementMasterQuery({ params: { ...params } })

    useEffect(() => {
        if (templateFields.length === 0) return
        if (!articleData?.data) return
        if (!processData?.data) return
        if (!uomData?.data) return
        if (!styleData?.data) return
        let noneStyleId = (styleData?.data || []).find(i => (i?.sku || '').toLowerCase() === "none")?.id || ''
        let purchaseId = (processData?.data || []).find(i => i?.isPurchase)?.id || '';
        let pcsId = (uomData?.data || []).find(i => i?.name === "PCS")?.id || '';
        let trimsData = (articleData?.data || []).filter(i => (i.isDefault) && i.selectedType === 'Trims').map(i => ({
            processId: purchaseId,
            articleId: i.id,
            uomId: pcsId,
            consumptionQty: '1', loss: TRIMS_VALUE, refetch: true
        }))
        let cmtData = (processData?.data || []).filter(i => i?.isCmt && i?.isDefault).map(i => ({
            processId: i?.id,
            uomId: pcsId,
            consumptionQty: '1', refetch: true
        }))
        setStyleItemDetails(prev => (prev.length === 0) ?
            [...prev, {
                styleId: noneStyleId, fabricId: '', templateFields: templateFields.map(i => ({ ...i, value: i?.defaultValue || '' })),
                yarnCostingDetails: [],
                fabricCostingDetails: [],
                accessoryCostingDetails: trimsData,
                cmtCostingDetails: cmtData
            }]
            : prev);
    }, [setStyleItemDetails, templateFields, articleData, processData, uomData, styleData])

    function getSubGridValue(index, fieldIndex) {
        if (!styleItemDetails[index]) return;
        if (!styleItemDetails[index]['templateFields']) return;
        if (!styleItemDetails[index]['templateFields'][fieldIndex]) return;
        return styleItemDetails[index]['templateFields'][fieldIndex]?.value || '0';
    }

    function getConsumption(index) {
        if (!styleItemDetails[index]) return 0;
        return styleItemDetails[index]['consumption']
    }


    function addRow() {
        let purchaseId = (processData?.data || []).find(i => i?.isPurchase)?.id || '';
        let pcsId = (uomData?.data || []).find(i => i?.name === "PCS")?.id || '';
        let noneStyleId = (styleData?.data || []).find(i => (i?.sku || '').toLowerCase() === "none")?.id || ''
        let trimsData = (articleData?.data || []).filter(i => (i.isDefault) && i.selectedType === 'Trims').map(i => ({
            processId: purchaseId,
            articleId: i.id,
            uomId: pcsId,
            consumptionQty: '1', loss: TRIMS_VALUE, refetch: true
        }))
        let cmtData = (processData?.data || []).filter(i => i.isCmt && i?.isDefault).map(i => ({
            processId: i.id,
            uomId: pcsId,
            consumptionQty: '1', refetch: true
        }))
        setStyleItemDetails(prev => [...prev, {
            styleId: noneStyleId,
            fabricId: "",
            templateFields: templateFields.map(i => ({ ...i, value: i?.defaultValue || '' })),
            yarnCostingDetails: [],
            fabricCostingDetails: [],
            accessoryCostingDetails: trimsData,
            cmtCostingDetails: cmtData
        }])
    }

    const { data: styleTemplateData } = useGetStyleFabricTemplateQuery({})

    const styleTemplateList = useMemo(() => styleTemplateData?.data, [styleTemplateData]);

    function deleteRow(index) {
        setStyleItemDetails(prev => prev.filter((_, i) => i !== index))
    }
    const handleChange = (index, field, value) => {
        setStyleItemDetails(prev => {
            let newPrev = structuredClone(prev);
            newPrev[index][field] = value;
            if (field === "fabricId") {
                let gsm = findFromList(value, styleTemplateList, 'gsm') || 0;
                if (gsm) {
                    let gsmIndex = templateFields.findIndex(i => (i?.FieldMaster?.name || '').toLowerCase() === "gsm")
                    if (gsmIndex !== -1) {
                        newPrev[index]['templateFields'] = newPrev[index]['templateFields'].map((i, index) => {
                            if (index !== gsmIndex) return i
                            return { ...i, value: gsm }
                        })
                        // handleChangeOnSubGrid(index, gsmIndex, gsm)
                    }
                }
            }
            if (field === 'isTrimFabric' && value) {
                newPrev[index]['accessoryCostingDetails'] = [];
                newPrev[index]['cmtCostingDetails'] = [];
            }
            setCurrentSelectedStyleId(index);
            return calculateFormula(newPrev, index)
        })
    }

    const handleChangeOnSubGrid = (index, fieldIndex, value) => {
        setStyleItemDetails(prev => {
            let newPrev = structuredClone(prev);
            newPrev[index]['templateFields'][fieldIndex]['value'] = value;
            return calculateFormula(newPrev, index)
        })
    }
    function calculateFormula(newPrev, index) {
        let formula = templateDetails?.data?.formula || '';
        const templateFields = templateDetails?.data?.ConsumptionTemplateDetails || [];
        let tempArr = [];
        newPrev[index]['templateFields'].forEach((i, index, currentArr) => {
            const field = templateFields.find(tF => parseInt(tF?.fieldId) === parseInt(i.fieldId));
            if (!field) return tempArr.push(i)
            if (!field?.formula) return tempArr.push(i);
            let value = { ...i, value: parseFloat(getEval(getRegex(field.formula, currentArr)) || '0').toFixed(3) }
            currentArr[index] = value;
            tempArr.push(value)
        })
        newPrev[index]['templateFields'] = tempArr;
        function getRegex(formula, currentArr) {
            if (!formula) return ''
            let input = formula;
            const words = formula.match(/\{(.*?)\}/g);
            if (!words) return formula;
            words.forEach(element => {
                input = input.replace(element, getFormula(element.slice(1, -1), currentArr))
            });
            return getRegex(input, currentArr)
        }
        function getFormula(formula, currentArr) {
            const fieldId = templateFields.find(i => i?.FieldMaster?.name === formula)?.fieldId;
            if (!fieldId) return '0';
            return currentArr.find(i => parseInt(i.fieldId) === parseInt(fieldId))?.value;
        }
        function getEval(value) {
            try {
                return eval(value)
            } catch (err) {
                return value
            }
        }
        const result = (getEval(getRegex(formula, newPrev[index]['templateFields'] || [])) || 0).toFixed(3);
        newPrev[index]['consumption'] = isNumeric(result.toString()) ? result : 0;
        newPrev[index]['consumptionQty'] = newPrev[index]['consumption'];
        if (Boolean(parseFloat(newPrev[index]['manualConsumption']))) {
            newPrev[index]['consumptionQty'] = newPrev[index]['manualConsumption'];
        }
        return newPrev
    }

    return (
        <div className='w-full'>
            <table className="border border-gray-400 text-[11px] w-full">
                <thead className="border border-gray-400">
                    <tr className='bg-gray-300'>
                        <th className="border border-gray-400 w-[250px] text-[11px] p-0.5 text-center">Style</th>
                        <th className="border border-gray-400 w-[250px] text-[11px] p-0.5 text-center">Fabric</th>
                        <th className="border border-gray-400 w-[50px] text-[11px] p-0.5 text-center">Trim Fabric</th>
                        {templateFields.map((field, index) => (
                            <th key={index} className={`border-x border-gray-400`} >
                                <span className='first-letter:text-red-500 first-letter:font-bold first-letter:uppercase'>
                                    {capitalizeFirstLetter(field?.FieldMaster?.name || '')}
                                </span>
                            </th>
                        ))}
                        <th className='border-x border-gray-400 w-24'>
                            Consumption
                        </th>
                        <th className='border-x border-gray-400 w-24'>
                            Manual Consumption
                        </th>
                        <th className='border-x border-gray-400 w-24'>
                            Cad. Weight
                        </th>
                        <th className="border border-gray-400 w-24 text-[11px] p-0.5">Active</th>
                        {!readonly &&
                            <th className="border border-gray-400 w-16 text-[11px] p-0.5">
                                <button type='button' className="text-green-700 text-[11px] " onClick={addRow} >
                                    {PLUS}
                                </button>
                            </th>
                        }
                    </tr>
                </thead>
                <tbody>
                    {styleItemDetails.map((value, valueIndex) =>
                        <StyleItem key={valueIndex} valueIndex={valueIndex} readonly={readonly} handleChange={handleChange}
                            styleData={styleData} styleFabricData={styleFabricData} value={value} templateFields={templateFields} handleChangeOnSubGrid={handleChangeOnSubGrid}
                            setStyleItemDetails={setStyleItemDetails}
                            getConsumption={getConsumption} setCurrentSelectedStyleId={setCurrentSelectedStyleId} getSubGridValue={getSubGridValue} currentSelectedStyleId={currentSelectedStyleId} deleteRow={deleteRow} />
                    )}
                </tbody>
            </table>
        </div >
    )
}

export default StyleItems
