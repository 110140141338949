import React, { useEffect, useState, useRef, useCallback } from "react";
import {
    useAddArticleMutation, useGetArticleQuery, useGetArticleByIdQuery, useUpdateArticleMutation, useDeleteArticleMutation

} from "../../../redux/ErpServices/ArticleService";
import FormHeader from "../../../Basic/components/FormHeader";
import FormReport from "../../../Basic/components/FormReportTemplate";
import { toast } from "react-toastify";
import { TextInput, CheckBox, DropdownInput } from "../../../Inputs";
import ReportTemplate from '../../../Basic/components/ReportTemplate'
import { getCommonParams } from "../../../Utils/helper";
import { useDispatch } from "react-redux";

const MODEL = "Article Master";

export default function Form() {
    const [form, setForm] = useState(false);

    const [readOnly, setReadOnly] = useState(false);
    const [id, setId] = useState("");
    const [name, setName] = useState("");
    const [active, setActive] = useState(true);
    const [searchValue, setSearchValue] = useState("");
    const [selectedType, setSelectedType] = useState("");
    const [isDefault, setIsDefault] = useState('');

    const childRecord = useRef(0);
    const { companyId, branchId } = getCommonParams()

    const { data: allData, isLoading, isFetching } = useGetArticleQuery({ params: { companyId }, searchParams: searchValue });
    const {
        data: singleData,
        isFetching: isSingleFetching,
        isLoading: isSingleLoading,
    } = useGetArticleByIdQuery(id, { skip: !id });


    const [addData] = useAddArticleMutation();
    const [updateData] = useUpdateArticleMutation();
    const [removeData] = useDeleteArticleMutation();




    const syncFormWithDb = useCallback(
        (data) => {
            if (id) setReadOnly(true);
            setName(data?.name ? data.name : "");
            setSelectedType(data?.selectedType || "");
            setIsDefault(data?.isDefault || false);
            setActive(id ? (data?.active ? data.active : false) : true);
        },
        [id]
    );

    useEffect(() => {
        syncFormWithDb(singleData?.data);
    }, [isSingleFetching, isSingleLoading, id, syncFormWithDb, singleData]);

    const data = {
        name, active, companyId, selectedType, id, isDefault, branchId
    }


    const validateData = (data) => {
        if (data.name) {
            return true;
        }
        return false;
    }

    const dispatch = useDispatch();



    const handleSubmitCustom = async (callback, data, text) => {
        try {
            let returnData = await callback(data).unwrap();
            if (returnData?.statusCode === 1) {
                toast.warning(returnData?.message);
            } else {
                setId("")
                syncFormWithDb(undefined)
                toast.success(text + "Successfully");
                dispatch({
                    type: `StyleFabricTemplate/invalidateTags`,
                    payload: ['StyleFabricTemplate'],
                });
            }
        } catch (error) {
            console.log("handle");
        }
    };

    const saveData = () => {
        if (!validateData(data)) {
            toast.info("Please fill all required fields...!", {
                position: "top-center",
            });
            return;
        }
        if (!window.confirm("Are you sure save the details ...?")) {
            return;
        }
        if (id) {
            handleSubmitCustom(updateData, data, "Updated");
        } else {
            handleSubmitCustom(addData, data, "Added");
        }
    };

    const deleteData = async () => {
        if (id) {
            if (!window.confirm("Are you sure to delete...?")) {
                return;
            }
            try {
                await removeData(id)
                setId("");
                toast.success("Deleted Successfully");
            } catch (error) {
                toast.error("something went wrong");
            }
        }
    };

    const handleKeyDown = (event) => {
        let charCode = String.fromCharCode(event.which).toLowerCase();
        if ((event.ctrlKey || event.metaKey) && charCode === "s") {
            event.preventDefault();
            saveData();
        }
    };

    const onNew = () => {
        setId("");
        setForm(true);
        setSearchValue("");
        syncFormWithDb(undefined)
        setReadOnly(false);
    };

    function onDataClick(id) {
        setId(id);
        setForm(true);
    }

    const Types = [
        { value: 'Yarn', show: "Yarn" },
        { value: 'Trims', show: "Trims" },
        { value: 'Fabric', show: "Fabric" },
        // { value: 'CMT', show: "CMT" },
        // { value: 'Commercial', show: "Commercial" },
    ]

    const tableHeaders = [
        "Code", "Name", "Status"
    ]
    const tableDataNames = ["dataObj.selectedType", "dataObj.name", 'dataObj.active ? ACTIVE : INACTIVE']

    if (!form)
        return (
            <ReportTemplate
                heading={MODEL}
                tableHeaders={tableHeaders}
                tableDataNames={tableDataNames}
                loading={
                    isLoading || isFetching
                }
                setForm={setForm}
                data={allData?.data}
                onClick={onDataClick}
                onNew={onNew}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
            />
        );

    return (
        <div
            onKeyDown={handleKeyDown}
            className="md:items-start md:justify-items-center grid h-full bg-theme"
        >
            <div className="flex flex-col frame w-full h-full">
                <FormHeader
                    onNew={onNew}
                    onClose={() => {
                        setForm(false);
                        setSearchValue("");
                    }}
                    model={MODEL}
                    saveData={saveData}
                    setReadOnly={setReadOnly}
                    deleteData={deleteData}

                />
                <div className="flex-1 grid grid-cols-1 md:grid-cols-4 gap-x-2 overflow-clip">
                    <div className="col-span-3 grid md:grid-cols-2 border overflow-auto">
                        <div className='col-span-3 grid md:grid-cols-2 border overflow-auto'>
                            <div className='mr-1 md:ml-2'>
                                <fieldset className='frame my-1'>
                                    <legend className='sub-heading'>Article Info</legend>
                                    <div className='grid grid-cols-1 my-2'>
                                        <TextInput name="Article Name" type="text" value={name} setValue={setName} required={true} readOnly={readOnly} disabled={(childRecord.current > 0)} />
                                        <DropdownInput name={"Article Type"} value={selectedType}
                                            setValue={setSelectedType}
                                            options={Types} readOnly={readOnly} />
                                        <CheckBox
                                            name="Default"
                                            readOnly={readOnly}
                                            value={isDefault}
                                            setValue={setIsDefault}
                                        />
                                        <CheckBox
                                            name="Active"
                                            readOnly={readOnly}
                                            value={active}
                                            setValue={setActive}
                                        />
                                        {/* <Dropdown Types={Types} selectedType={selectedType} setSelectedType={setSelectedType} /> */}
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                        {/* <Converter/> */}
                    </div>
                    <div className="frame hidden md:block overflow-x-hidden">
                        <FormReport
                            searchValue={searchValue}
                            setSearchValue={setSearchValue}
                            setId={setId}
                            tableHeaders={tableHeaders}
                            tableDataNames={tableDataNames}
                            data={allData?.data}
                            loading={
                                isLoading || isFetching
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
