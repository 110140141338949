import React from 'react';
import { DELETE, PLUS } from '../../../icons';
import { useGetProcessQuery } from '../../../redux/ErpServices/processMasterServices';
import { DropdownWithSearch } from '../../../SelectDropDown/Dropdown';
import secureLocalStorage from 'react-secure-storage';
import { useGetArticleQuery } from '../../../redux/ErpServices/ArticleService';
import { useGetUnitOfMeasurementMasterQuery } from '../../../redux/ErpServices/UnitOfMeasurementServices';
import { DropdownInput } from '../../../Inputs';
import { dropDownListObject } from '../../../Utils/contructObject';

const ProcessCostingSet = ({
  costingDetails, setCostingDetails, name, readOnly
}) => {
  const isArticleShow = ["Yarn", "Trims"].includes(name)
  const isRawMaterial = ["Yarn", "Fabric"].includes(name)
  const isUom = ["Yarn", "Fabric", "Trims", "CMT"].includes(name)

  let params = {
    companyId: secureLocalStorage.getItem(
      sessionStorage.getItem("sessionId") + "userCompanyId"
    ),

  };
  let processParams;
  if (name === "Yarn") {
    processParams = { isYarn: true }
  } else if (name === "Fabric") {
    processParams = { isFabric: true }
  } else if (name === "Trims") {
    processParams = { isTrims: true }
  } else if (name === "CMT") {
    processParams = { isCmt: true }
  } else if (name === "Commercial") {
    processParams = { isCommercial: true }
  }


  const { data: processData } = useGetProcessQuery({ params: { ...params, ...processParams } });
  const { data: artData } = useGetArticleQuery({ params: { ...params, selectedType: name } });
  const { data: uomData } = useGetUnitOfMeasurementMasterQuery({ params });

  const process = processData?.data || [];
  const article = artData?.data || [];
  const uom = uomData?.data || [];

  const addRow = () => {
    setCostingDetails(prev => {
      let newItems = structuredClone(prev);
      newItems = [...newItems, { process: '', yarnName: '', uomId: isRawMaterial ? (uom.find(i => i.name === "KGS")?.id || '') : "", conversionValue: '' }]
      return newItems;
    })
  };

  const deleteRow = (index) => {
    setCostingDetails(prev => prev.filter((_, i) => i !== index))
  };

  const handleInputChange = (index, field, value) => {
    setCostingDetails(prev => {
      let newItems = structuredClone(prev);
      newItems[index][field] = value;
      return newItems;
    })
  };

  return (
    <fieldset className='frame p-1'>
      <div className='bg-[#388087] text-white text-center p-1'>{name}</div>
      <table className='w-full text-[11px]'>
        <thead>
          <tr className='h-[40px] bg-gray-300'>
            <th className="border border-gray-400 text-[11px] w-20">
              Process
            </th>
            {isArticleShow &&
              <th className="border border-gray-400 text-[11px] w-20">
                {name}
              </th>
            }
            {isUom &&
              <th className="border border-gray-400 text-[11px] w-10">
                UOM
              </th>
            }
            <th className="border border-gray-400 text-[11px] w-10">
              Rate
            </th>
            <th className="border border-gray-400 text-[11px] w-10">
              Loss(%)
            </th>
            {!readOnly
              &&
              <th className="border border-gray-400 text-[11px]  text-green-700 w-5" onClick={addRow}>{PLUS}</th>
            }
          </tr>
        </thead>
        <tbody >
          {(costingDetails).map((row, index) => (
            <tr key={index} className='h-[30px]'>
              <td className="border border-gray-400 text-[11px] ">
                <DropdownWithSearch
                  value={row.processId}
                  setValue={(val) => handleInputChange(index, 'processId', val)}
                  options={process}
                  readOnly={readOnly}
                />
              </td>
              {isArticleShow &&
                <td className="border border-gray-400 text-[11px] ">
                  <DropdownWithSearch
                    value={row.articleId}
                    setValue={(val) => handleInputChange(index, 'articleId', val)}
                    options={article}
                    readOnly={readOnly}
                  />
                </td>
              }
              {isUom &&
                <td className="border border-gray-400 text-[11px] ">
                  <DropdownInput
                    value={row.uomId}
                    setValue={(val) => handleInputChange(index, 'uomId', val)}
                    options={dropDownListObject(uom, "name", "id")}
                    readOnly={readOnly}
                  />
                </td>
              }
              <td className="border border-gray-400 text-[11px] h-full">
                <input
                  type="text"
                  onFocus={(e) => { e.target.select() }}
                  disabled={readOnly}
                  value={row.rate}
                  className='text-right w-full h-full'
                  onChange={(e) => handleInputChange(index, 'rate', e.target.value)}
                />
              </td>
              <td className="border border-gray-400 text-[11px] h-full">
                <input
                  type="text"
                  onFocus={(e) => { e.target.select() }}
                  disabled={readOnly}
                  value={row.loss}
                  className='text-right w-full h-full'
                  onChange={(e) => handleInputChange(index, 'loss', e.target.value)}
                />
              </td>
              {!readOnly &&
                <td className=' border border-gray-400'>
                  <div className='flex items-center justify-center w-full'>
                    <button
                      className='text-red-500'
                      onClick={() => deleteRow(index)}
                    >
                      {DELETE}
                    </button>
                  </div>
                </td>
              }
            </tr>
          ))}
        </tbody>
      </table>
    </fieldset>
  );
};

export default ProcessCostingSet;
