import React, { useState } from 'react'
import { useGetCostingByIdQuery } from '../../../redux/ErpServices/CostingServices';
import { getImageUrlPath } from '../../../Constants';
import { IMAGE_ICON } from '../../../icons';

const RemarksUpdateOrderConfirmation = ({ handleSubmit, id, name = "Confirm Order" }) => {
    const [remarks, setRemarks] = useState('');
    const handleSubmitForm = async (e) => {
        e.preventDefault();
        await handleSubmit(remarks)
    }
    const { data } = useGetCostingByIdQuery(id, { skip: !id })

    return (
        <form className="max-w-sm mx-auto" onSubmit={handleSubmitForm}>
            <div className={`text-xl text-center bg-green-600 text-gray-200 mb-2 rounded`}>{name}</div>
            <div className="mb-5 flex items-center justify-center">
                {data?.data?.styleImage ?
                    <img alt='' className='h-[100px] w-[100px] rounded-full border' src={getImageUrlPath(data?.data?.styleImage)} />
                    :
                    <div className='h-[100px] w-[100px] rounded-full border'>
                        {IMAGE_ICON}
                    </div>
                }
            </div>
            <div className="mb-5">
                <input type="text" value={data?.data?.styleRefNo} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Remarks" required disabled />
            </div>
            <div className="mb-5">
                <input type="text" value={data?.data?.docId} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Remarks" required disabled />
            </div>
            <div className="mb-5">
                <input type="text" id="" value={remarks} onChange={(e) => { setRemarks(e.target.value) }} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Remarks" required />
            </div>
            <button type="submit" className={`text-white bg-green-600 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center`}>Submit</button>
        </form>
    )
}

export default RemarksUpdateOrderConfirmation
