import React from 'react';
import { priceWithTax } from '../../../Utils/helper';

const CostingSummary = ({
  styleItemDetails, currentSelectedStyleId, conversionValue, readOnly
}) => {
  const currentStyleItem = styleItemDetails[currentSelectedStyleId];

  const currentStyleItems = styleItemDetails.filter(i => parseInt(i?.styleId || 0) === parseInt(currentStyleItem?.styleId || 0))

  const summary = [
    {
      name: "Yarn",
      value: currentStyleItems.reduce((acc, currentStyleItem) => acc + (currentStyleItem?.yarnCostingDetails || []).reduce((a, c) => a + (priceWithTax((parseFloat(currentStyleItem?.consumptionQty || 0)) * c.rate, parseFloat(c?.loss || 0))), 0), 0)
    }, {
      name: "Fabric",
      value: currentStyleItems.reduce((acc, currentStyleItem) => acc + (currentStyleItem?.fabricCostingDetails || []).reduce((a, c) => a + (priceWithTax((parseFloat(currentStyleItem?.consumptionQty || 0)) * c.rate, parseFloat(c?.loss || 0))), 0), 0)
    }, {
      name: "Trims",
      value: currentStyleItems.reduce((acc, currentStyleItem) => acc + (currentStyleItem?.accessoryCostingDetails || []).reduce((a, c) => a + (priceWithTax((parseFloat(c?.consumptionQty || 0)) * c.rate, parseFloat(c?.loss || 0))), 0), 0)
    }, {
      name: "CMP",
      value: currentStyleItems.reduce((acc, currentStyleItem) => acc + (currentStyleItem?.cmtCostingDetails || []).reduce((a, c) => a + (priceWithTax((parseFloat(c?.consumptionQty || 0)) * c.rate, parseFloat(c?.loss || 0))), 0), 0)
    }
  ]

  return (
    <fieldset className='frame rounded-tr-lg rounded-bl-lg w-full border border-gray-600 p-1'>
      <div className='bg-[#388087] text-white text-center p-1'>Summary</div>
      <table className='w-full text-[11px]'>
        <thead>
          <tr className='h-[40px] bg-gray-300'>
            <th className="border border-gray-400 text-[11px] w-20">
              Process
            </th>
            <th className="border border-gray-400 text-[11px] w-10">
              Amount
            </th>
            <th className="border border-gray-400 text-[11px] w-10">
              Conv. Value
            </th>
          </tr>
        </thead>
        <tbody >
          {summary.map((row, index) => (
            <tr key={index} className='h-[30px]'>
              <td className="border border-gray-400 text-[11px] pl-1 w-20">
                {row.name}
              </td>
              <td className="border border-gray-400 text-[11px] text-right w-10">
                {parseFloat(row.value).toFixed(3)}
              </td>
              <td className="border border-gray-400 text-[11px] h-full text-right w-10">
                {(parseFloat(row.value) * conversionValue).toFixed(3)}
              </td>
            </tr>
          ))}
          <tr className='h-[30px] border-green-600 border-2'>
            <td className="border border-gray-400 text-[11px] font-bold pl-1">
              Costing
            </td>
            <td className="border border-gray-400 text-[11px] h-full text-right">
              {summary.reduce((a, c) => a + parseFloat(c.value), 0).toFixed(3)}
            </td>
            <td className="border border-gray-400 text-[11px] h-full text-right">
              {(summary.reduce((a, c) => a + parseFloat(c.value), 0) * parseFloat(conversionValue)).toFixed(3)}
            </td>
          </tr>
        </tbody>
      </table>
    </fieldset>
  );
};

export default CostingSummary;
