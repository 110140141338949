import React, { useEffect, useMemo } from 'react';
import { DELETE, PLUS } from '../../../icons';
import { useGetProcessQuery } from '../../../redux/ErpServices/processMasterServices';
import { DropdownWithSearch } from '../../../SelectDropDown/Dropdown';
import secureLocalStorage from 'react-secure-storage';
import { discountTypes } from '../../../Utils/DropdownData';
import { DropdownInput } from '../../../Inputs';
import { findFromList, priceWithTax } from '../../../Utils/helper';
import { useGetStyleMasterQuery } from '../../../redux/ErpServices/StyleMasterServices';
import { minValue, stepValue } from '../../../Constants';

const CommercialCosting = ({
  conversionValue, name = "Commercial", commercialCostingDetails, setCommercialCostingDetails, styleItemDetails, readOnly, targetRate, PartyData, id
}) => {
  let params = {
    companyId: secureLocalStorage.getItem(
      sessionStorage.getItem("sessionId") + "userCompanyId"
    ),
  };

  const { data: processData } = useGetProcessQuery({ params: { ...params, isCommercial: true } });

  const process = useMemo(() => processData?.data || [], [processData]);

  useEffect(() => {
    if (id) return
    // if (!PartyData?.data?.PayTerm?.financeCostPercent) return;
    setCommercialCostingDetails(() => {
      const financeCostPercent = PartyData?.data?.PayTerm?.financeCostPercent || 0;
      let financeProcess = process.filter(i => i.isFinance).map((process) => ({ processId: process.id, type: "Percentage", rate: financeCostPercent }))
      let oversHeads = process.filter(i => i.isOversHeads).map((process) => ({ processId: process.id, type: "Percentage", rate: '12' }))
      return [...financeProcess, ...oversHeads]
    })
  }, [setCommercialCostingDetails, process, PartyData, id])

  const addRow = () => {
    setCommercialCostingDetails(prev => [...prev, { process: '', yarnName: '', uom: '', conversionValue: '', rate: '', amount: '' }]);
  };

  const deleteRow = (index) => {
    const newRows = commercialCostingDetails.slice();
    newRows.splice(index, 1);
    setCommercialCostingDetails(newRows);
  };

  const handleInputChange = (index, field, value) => {
    setCommercialCostingDetails(prev => {
      const newRows = structuredClone(prev);
      if (field === "processId") {
        let processMatched = process.find(i => parseInt(i.id) === parseInt(value));
        if (processMatched.isOversHeads) {
          newRows[index]['rate'] = '12';
          newRows[index]['type'] = 'Percentage';
        }
      }
      newRows[index][field] = value;
      return newRows;
    });
  };
  function getAmount(type, rate) {
    if (type === "Percentage") return (totalAmount / 100) * parseFloat(rate)
    if (type === "Flat") return parseFloat(rate)
    return 0
  }

  let colCount = 3;

  function getStyleWiseTotal(styleId) {
    const currentStyleItems = styleItemDetails.filter(i => parseInt(i.styleId) === parseInt(styleId));
    const summary = [
      {
        name: "Yarn",
        value: currentStyleItems.reduce((a, currentStyleItem) => a + (currentStyleItem?.yarnCostingDetails || []).reduce((a, c) => a + (priceWithTax((parseFloat(currentStyleItem.consumptionQty) || 0) * c.rate, parseFloat(c.loss || 0))), 0), 0)
      }, {
        name: "Fabric",
        value: currentStyleItems.reduce((a, currentStyleItem) => a + (currentStyleItem?.fabricCostingDetails || []).reduce((a, c) => a + (priceWithTax((parseFloat(currentStyleItem.consumptionQty) || 0) * c.rate, parseFloat(c.loss || 0))), 0), 0)
      },
      {
        name: "Trims",
        value: currentStyleItems.reduce((a, currentStyleItem) => a + (currentStyleItem?.accessoryCostingDetails || []).reduce((a, c) => a + (priceWithTax((parseFloat(c.consumptionQty) || 0) * c.rate, parseFloat(c.loss || 0))), 0), 0)
      }, {
        name: "CMT",
        value: currentStyleItems.reduce((a, currentStyleItem) => a + (currentStyleItem?.cmtCostingDetails || []).reduce((a, c) => a + (priceWithTax((parseFloat(c.consumptionQty) || 0) * c.rate, parseFloat(c.loss || 0))), 0), 0)
      }
    ]
    return summary.reduce((a, c) => a + parseFloat(c.value), 0)
  }
  let distinctStyleItems = [...new Set(styleItemDetails.map(i => parseInt(i.styleId)))];
  const { data: styleData } = useGetStyleMasterQuery({ params });
  const styleList = styleData?.data || [];
  let overAllSummary = distinctStyleItems.map(styleId => ({
    name: findFromList(styleId, styleList, "name"),
    value: getStyleWiseTotal(styleId).toFixed(3)
  }))
  // const totalAmount = overAllSummary.reduce((a, c) => a + parseFloat(c.value), 0)
  const totalAmount = parseFloat(targetRate || 0) / conversionValue;
  const total = commercialCostingDetails.reduce((a, c) => a + (getAmount(c.type, c.rate)), 0)

  return (
    <fieldset className='frame rounded-tr-lg rounded-bl-lg w-full border border-gray-600 p-1'>
      {/* <legend className='sub-heading'>{name}</legend> */}
      <div className='bg-[#388087] text-white text-center p-1'>{name}</div>
      <table className='w-full text-[11px]'>
        <thead>
          <tr className='h-[40px] bg-gray-300'>
            <th className="border border-gray-400 text-[11px] w-20">
              Process
            </th>
            <th className="border border-gray-400 text-[11px] w-10">
              Type
            </th>
            <th className="border border-gray-400 text-[11px] w-10">
              Rate
            </th>
            <th className="border border-gray-400 text-[11px] w-10">
              Amount
            </th>
            <th className="border border-gray-400 text-[11px] w-10">
              Conv. Value
            </th>
            {!readOnly
              &&
              <th className="border border-gray-400 text-[11px]  text-green-700 w-5" onClick={addRow}>{PLUS}</th>
            }
          </tr>
        </thead>
        <tbody >
          {commercialCostingDetails.map((row, index) => (
            <tr key={index} className='h-[30px]'>
              <td className="border border-gray-400 text-[11px] ">
                <DropdownWithSearch
                  required
                  className='w-[100px]'
                  value={row.processId}
                  setValue={(val) => handleInputChange(index, 'processId', val)}
                  options={process}
                  readOnly={readOnly}
                />
              </td>
              <td className="border border-gray-400 text-[11px] ">

                <DropdownInput
                  required
                  value={row.type}
                  setValue={(val) => handleInputChange(index, 'type', val)}
                  options={discountTypes}
                  readOnly={readOnly}
                />
              </td>
              <td className="border border-gray-400 text-[11px] h-full">
                <input
                  required
                  type="number"
                  min={minValue}
                  step={stepValue}
                  onFocus={(e) => { e.target.select() }}
                  disabled={readOnly}
                  value={row.rate}
                  className='text-right w-full h-full'
                  onChange={(e) => handleInputChange(index, 'rate', e.target.value)}
                />
              </td>
              <td className="border border-gray-400 text-[11px] ">
                <input
                  type="text"
                  onFocus={(e) => { e.target.select() }}

                  className='text-right h-full w-full'
                  value={getAmount(row.type, row.rate).toFixed(3)}
                  disabled
                />
              </td>
              <td className="border border-gray-400 text-[11px] ">
                <input
                  type="text"
                  onFocus={(e) => { e.target.select() }}

                  className='text-right h-full w-full'
                  value={(getAmount(row.type, row.rate) * parseFloat(conversionValue || 0)).toFixed(3)}
                  disabled
                />
              </td>
              {!readOnly
                &&
                <td className=' border border-gray-400'>
                  <div className='flex items-center justify-center w-full'>
                    <button
                      className='text-red-500'
                      onClick={() => deleteRow(index)}
                    >
                      {DELETE}
                    </button>
                  </div>
                </td>
              }
            </tr>
          ))}
          <tr className='h-[40px]'>
            <td className="border border-gray-400 text-[11px] " colSpan={colCount} >
            </td>
            <td className="border border-gray-400 text-[11px] text-right">
              {total.toFixed(3)}
            </td>
            <td className=' border border-gray-400 text-right'>
              {(total * conversionValue).toFixed(3)}
            </td>
            {!readOnly
              &&
              <td className=' border border-gray-400'>
              </td>
            }
          </tr>
        </tbody>
      </table>
    </fieldset>
  );
};

export default CommercialCosting;
