import React from 'react'
import ProcessCostingSet from './ProcessCosting';
import CostingSummary from './CostingSummay';
import CommercialCosting from './ComercialCosting';

const StyleWiseDetails = ({ templateDetails, consumptionTemplateId, setConsumptionTemplateId, styleItemDetails, setStyleItemDetails, currentSelectedStyleId, conversionValue, commercialCostingDetails, setCommercialCostingDetails, readOnly, targetRate, id, PartyData, userDocDate }) => {
    const currentStyleItem = styleItemDetails[currentSelectedStyleId];
    let tab2Cols = 0;
    if (currentStyleItem) {
        tab2Cols = Math.max((currentStyleItem.accessoryCostingDetails || []).length, (currentStyleItem.cmtCostingDetails || []).length)
    }
    return (
        <fieldset className='w-full text-xs grid grid-cols-2 gap-3 frame rounded-tr-lg rounded-bl-lg border border-gray-600 my-2'>
            <legend className='sub-Heading'>Style-wise</legend>
            <div className='grid grid-cols-2 col-span-2 gap-1'>
                <ProcessCostingSet userDocDate={userDocDate} id={id} readOnly={readOnly} name={"Yarn"} templateDetails={templateDetails} consumptionTemplateId={consumptionTemplateId} setConsumptionTemplateId={setConsumptionTemplateId} styleItemDetails={styleItemDetails} setStyleItemDetails={setStyleItemDetails} currentSelectedStyleId={currentSelectedStyleId} conversionValue={conversionValue} costingDetails="yarnCostingDetails" />
                <ProcessCostingSet userDocDate={userDocDate} id={id} readOnly={readOnly} name={"Fabric"} templateDetails={templateDetails} consumptionTemplateId={consumptionTemplateId} setConsumptionTemplateId={setConsumptionTemplateId} styleItemDetails={styleItemDetails} setStyleItemDetails={setStyleItemDetails} currentSelectedStyleId={currentSelectedStyleId} conversionValue={conversionValue} costingDetails="fabricCostingDetails" />
                <ProcessCostingSet userDocDate={userDocDate} id={id} readOnly={readOnly} name={"Trims"} fillCols={tab2Cols} templateDetails={templateDetails} consumptionTemplateId={consumptionTemplateId} setConsumptionTemplateId={setConsumptionTemplateId} styleItemDetails={styleItemDetails} setStyleItemDetails={setStyleItemDetails} currentSelectedStyleId={currentSelectedStyleId} conversionValue={conversionValue} costingDetails="accessoryCostingDetails" />
                <ProcessCostingSet userDocDate={userDocDate} id={id} readOnly={readOnly} name={"CMP"} fillCols={tab2Cols} templateDetails={templateDetails} consumptionTemplateId={consumptionTemplateId} setConsumptionTemplateId={setConsumptionTemplateId} styleItemDetails={styleItemDetails} setStyleItemDetails={setStyleItemDetails} currentSelectedStyleId={currentSelectedStyleId} conversionValue={conversionValue} costingDetails="cmtCostingDetails" />
                <CostingSummary readOnly={readOnly} styleItemDetails={styleItemDetails} currentSelectedStyleId={currentSelectedStyleId} conversionValue={conversionValue} />
                <CommercialCosting key={PartyData} PartyData={PartyData} targetRate={targetRate} id={id} readOnly={readOnly} styleItemDetails={styleItemDetails} conversionValue={conversionValue} commercialCostingDetails={commercialCostingDetails} setCommercialCostingDetails={setCommercialCostingDetails} />
            </div>

        </fieldset>

    )
}

export default StyleWiseDetails