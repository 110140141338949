import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "../baseQuery";
import { COSTING_API } from "../../Api";


const CostingApi = createApi({
  reducerPath: "Costing",
  baseQuery,
  tagTypes: ["Costing"],
  endpoints: (builder) => ({
    getCosting: builder.query({
      query: ({ params, searchParams }) => {
        if (searchParams) {
          return {
            url: COSTING_API + "/search/" + searchParams,
            method: "GET",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            },
            params
          };
        }
        return {
          url: COSTING_API,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          params
        };
      },
      providesTags: ["Costing"],
    }),
    getCostingById: builder.query({
      query: (id) => {
        return {
          url: `${COSTING_API}/${id}`,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
      providesTags: ["Costing"],
    }),
    addCosting: builder.mutation({
      query: (payload) => ({
        url: COSTING_API,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Costing"],
    }),
    updateCosting: builder.mutation({
      query: (payload) => {
        const { id, ...body } = payload;
        return {
          url: `${COSTING_API}/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["Costing"],
    }),
    deleteCosting: builder.mutation({
      query: (id) => ({
        url: `${COSTING_API}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Costing"],
    }),
    uploadCostingImage: builder.mutation({
      query: ({ id, data }) => ({
        url: `${COSTING_API}/imageUpload/${id}`,
        method: "PATCH",
        body: data
      }),
      invalidatesTags: ["Costing"],
    }),
    removeCostingImage: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `${COSTING_API}/imageRemove/${id}`,
        method: "DELETE",
        body
      }),
      invalidatesTags: ["Costing"],
    }),
  }),
});

export const {
  useGetCostingQuery,
  useGetCostingByIdQuery,
  useAddCostingMutation,
  useUpdateCostingMutation,
  useDeleteCostingMutation,
  useUploadCostingImageMutation,
  useRemoveCostingImageMutation
} = CostingApi;

export default CostingApi;
