import React from 'react'
import { DropdownInput, MultiSelectDropdown } from '../../Inputs';
import { dropDownListObject, multiSelectOption } from '../../Utils/contructObject';
import { getCommonParams } from '../../Utils/helper';
import { useGetSizeTemplateQuery } from '../../redux/ErpServices/SizeTemplateMasterServices';

const SizeTemplateDropdown = ({ multiSelect = true, withoutLabel = true, readOnly, name, selected, setSelected }) => {
    const { token, ...params } = getCommonParams();
    const { data: templateList } = useGetSizeTemplateQuery({ params });
    return (
        <>
            {withoutLabel ?
                <select value={selected} onChange={(e) => setSelected(e.target.value)} className='w-full table-data-input'>
                    <option value="">Select</option>
                    {(templateList ? templateList.data : []).map(item =>
                        <option key={item.id} value={item.id}>{item.name}</option>
                    )}
                </select>
                :
                <>
                    {multiSelect ?
                        <MultiSelectDropdown readOnly={readOnly} name={name} selected={selected} setSelected={setSelected}
                            options={multiSelectOption(templateList ? templateList.data : [], "name", "id")} />
                        :
                        <DropdownInput readOnly={readOnly} name={name} value={selected} setValue={setSelected}
                            options={dropDownListObject(templateList ? templateList.data : [], "name", "id")} />
                    }
                </>
            }
        </>
    )
}

export default SizeTemplateDropdown
