import React, { useEffect, useState, useCallback } from "react";
import secureLocalStorage from "react-secure-storage";
import {
    useGetStyleMasterQuery,
    useGetStyleMasterByIdQuery,
    useAddStyleMasterMutation,
    useUpdateStyleMasterMutation,
    useDeleteStyleMasterMutation,
} from "../../../redux/ErpServices/StyleMasterServices";
import { useGetFabricMasterQuery } from "../../../redux/ErpServices/FabricMasterServices"
import FormHeader from "../../../Basic/components/FormHeader";
import FormReport from "../../../Basic/components/FormReportTemplate";
import { toast } from "react-toastify";
import { TextInput, CheckBox, MultiSelectDropdown, DropdownInput } from "../../../Inputs";
import ReportTemplate from '../../../Basic/components/ReportTemplate'
import { dropDownListObject, multiSelectOption } from "../../../Utils/contructObject";
import BrowseSingleImage from "../../../Basic/components/BrowseSingleImage";
import { useGetSizeTemplateQuery } from "../../../redux/ErpServices/SizeTemplateMasterServices";
import { useGetColorMasterQuery } from "../../../redux/ErpServices/ColorMasterServices";
import { Loader } from "../../../Basic/components";
import { findFromList, viewBase64String } from "../../../Utils/helper";
import Portion from "./Portion";


const MODEL = "Style Master";

export default function Form() {
    const [form, setForm] = useState(false);

    const [readOnly, setReadOnly] = useState(false);
    const [id, setId] = useState("");
    const [image, setImage] = useState("")
    const [name, setName] = useState("");
    const [sku, setSku] = useState("");
    const [productType, setProductType] = useState("");
    const [seoTitle, setSeoTitle] = useState("");
    const [sleeve, setSleeve] = useState("");
    const [pattern, setPattern] = useState("");
    const [occassion, setOccassion] = useState("");
    const [material, setMaterial] = useState("");
    const [washCare, setWashCare] = useState("");
    const [active, setActive] = useState(true);
    const [fabricId, setFabricId] = useState("");
    const [sizeTemplateId, setSizeTemplateId] = useState("")
    const [selectedColorsList, setSelectedColorsList] = useState([])
    const [portionDetails, setPortionDetails] = useState([]);
    const [hsn, setHsn] = useState("")

    const [searchValue, setSearchValue] = useState("");


    const params = {
        companyId: secureLocalStorage.getItem(
            sessionStorage.getItem("sessionId") + "userCompanyId"
        ),
    };

    const { data: fabricList } =
        useGetFabricMasterQuery({ params: { ...params, active: true } });

    const { data: allData, isLoading, isFetching } = useGetStyleMasterQuery({ params, searchParams: searchValue });
    const {
        data: singleData,
        isFetching: isSingleFetching,
        isLoading: isSingleLoading,
    } = useGetStyleMasterByIdQuery(id, { skip: !id });

    const [addData] = useAddStyleMasterMutation();
    const [updateData] = useUpdateStyleMasterMutation();
    const [removeData] = useDeleteStyleMasterMutation();

    const syncFormWithDb = useCallback(
        (data) => {
            if (id) setReadOnly(true);
            setImage(data?.imageBase64 ? viewBase64String(data.imageBase64) : "");
            setSku(data?.sku ? data.sku : "");
            setHsn(data?.hsn ? data.hsn : "");
            setProductType(data?.productType ? data.productType : "");
            setName(data?.name ? data.name : "");
            setSeoTitle(data?.seoTitle ? data.seoTitle : "");
            setSleeve(data?.sleeve ? data.sleeve : "");
            setFabricId(data?.fabricId ? data.fabricId : "");
            setPattern(data?.pattern ? data.pattern : "");
            setOccassion(data?.occassion ? data.occassion : "");
            setMaterial(data?.material ? data.material : "");
            setWashCare(data?.washCare ? data.washCare : "");
            setActive(id ? (data?.active ? data.active : false) : true);
            setSizeTemplateId(data?.sizeTemplateId ? data.sizeTemplateId : "")
            setSelectedColorsList(data?.StyleOnColor ? data.StyleOnColor.map(item => {
                return { value: item.colorId, label: item.Color.name }
            }) : [])
            setPortionDetails(data?.portionDetails ? data.portionDetails : [])
        },
        [id]
    );
    const { data: sizeTemplateList } = useGetSizeTemplateQuery({ params, searchParams: searchValue });
    const { data: colorList } =
        useGetColorMasterQuery({ params });
    useEffect(() => {
        syncFormWithDb(singleData?.data);
    }, [isSingleFetching, isSingleLoading, id, syncFormWithDb, singleData]);

    const data = {
        id, sku, productType, name, seoTitle, sleeve, fabricId, sizeTemplateId,
        pattern, occassion, material, active, washCare, hsn,
        companyId: secureLocalStorage.getItem(sessionStorage.getItem("sessionId") + "userCompanyId")
    }

    const validateData = (data) => {
        if (data.name) {
            return true;
        }
        return false;
    }

    const handleSubmitCustom = async (callback, data, text) => {
        try {
            const formData = new FormData()
            for (let key in data) {
                formData.append(key, data[key]);
            }
            formData.append("selectedColorsList", JSON.stringify(selectedColorsList.map(item => item.value)))
            formData.append("portionDetails", JSON.stringify(portionDetails))
            if (image instanceof File) {
                formData.append("image", image);
            } else if (!image) {
                formData.append("isDeleteImage", true);
            }
            let returnData;
            if (text === "Updated") {
                returnData = await callback({ id, body: formData }).unwrap();
            } else {
                returnData = await callback(formData).unwrap();
            }
            setId("")
            syncFormWithDb(undefined)
            toast.success(text + "Successfully");
        } catch (error) {
            console.log("handle");
        }
    };

    const saveData = () => {
        if (!validateData(data)) {
            toast.info("Please fill all required fields...!", {
                position: "top-center",
            });
            return;
        }
        if (!window.confirm("Are you sure save the details ...?")) {
            return;
        }
        if (id) {
            handleSubmitCustom(updateData, data, "Updated");
        } else {
            handleSubmitCustom(addData, data, "Added");
        }
    };

    const deleteData = async () => {
        if (id) {
            if (!window.confirm("Are you sure to delete...?")) {
                return;
            }
            try {
                await removeData(id)
                setId("");
                toast.success("Deleted Successfully");
            } catch (error) {
                toast.error("something went wrong");
            }
        }
    };

    const handleKeyDown = (event) => {
        let charCode = String.fromCharCode(event.which).toLowerCase();
        if ((event.ctrlKey || event.metaKey) && charCode === "s") {
            event.preventDefault();
            saveData();
        }
    };

    const onNew = () => {
        setId("");
        setForm(true);
        setSearchValue("");
        syncFormWithDb(undefined)
        setReadOnly(false);
    };

    function onDataClick(id) {
        setId(id);
        setForm(true);
    }

    const tableHeaders = [
        "SKU", "Name", "Status"
    ]
    const tableDataNames = ["dataObj.sku", "dataObj.name", 'dataObj.active ? ACTIVE : INACTIVE']

    if (!form)
        return (
            <ReportTemplate
                heading={MODEL}
                tableHeaders={tableHeaders}
                tableDataNames={tableDataNames}
                loading={
                    isLoading || isFetching
                }
                setForm={setForm}
                data={allData?.data ? allData?.data : []}
                onClick={onDataClick}
                onNew={onNew}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
            />
        );

    return (
        <div
            onKeyDown={handleKeyDown}
            className="md:items-start md:justify-items-center grid h-full bg-theme"
        >
            <div className="flex flex-col frame w-full h-full">
                <FormHeader
                    onNew={onNew}
                    onClose={() => {
                        setForm(false);
                        setSearchValue("");
                    }}
                    model={MODEL}
                    saveData={saveData}
                    setReadOnly={setReadOnly}
                    deleteData={deleteData}
                    childRecord={0}
                />
                <div className="flex-1 grid grid-cols-1 md:grid-cols-4 gap-x-2 overflow-clip w-full">
                    <div className="col-span-3 border overflow-auto">
                        <div className='col-span-3'>
                            <div className='mr-1 md:ml-2 flex gap-5 min-h-80'>
                                <fieldset className='frame my-1 w-72'>
                                    <legend className='sub-heading'>Upload Image</legend>
                                    <BrowseSingleImage picture={image} setPicture={setImage} readOnly={readOnly} />
                                </fieldset>
                                <fieldset className='frame my-1 w-full flex flex-col gap-10'>
                                    <legend className='sub-heading'>Style Info</legend>
                                    <div className="grid grid-cols-2">
                                        <TextInput name="SKU / Style code" type="text" value={sku} setValue={setSku} required={true} readOnly={readOnly} />
                                        <TextInput name="Product Type" type="text" value={productType} setValue={setProductType} required={true} readOnly={readOnly} />
                                    </div>
                                    <div className="grid grid-cols-2">
                                        <TextInput name="Item Name" type="text" value={name} setValue={setName} required={true} readOnly={readOnly} />
                                        <TextInput name="SEO Title" type="text" value={seoTitle} setValue={setSeoTitle} required={true} readOnly={readOnly} />
                                    </div>
                                    <div className="flex flex-col">
                                        <DropdownInput name="Size Template" options={sizeTemplateList ? dropDownListObject(id ? sizeTemplateList.data : sizeTemplateList.data.filter(item => item.active), "name", "id") : []}
                                            value={sizeTemplateId} setValue={setSizeTemplateId} readOnly={readOnly} />
                                        <MultiSelectDropdown name={"Colors"}
                                            setSelected={setSelectedColorsList}
                                            selected={selectedColorsList}
                                            options={(colorList?.data) ? multiSelectOption(id ? colorList.data : colorList.data.filter(item => item.active), "name", "id") : []} readOnly={readOnly} />
                                    </div>
                                    <div className="grid grid-cols-2">
                                        <CheckBox name="Active" value={active} setValue={setActive} />
                                        <Portion readonly={readOnly} portionDetails={portionDetails} setPortionDetails={setPortionDetails} />
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                        <div className='mr-1 md:ml-2 col-span-3 overflow-auto'>
                            <fieldset className='frame h-60 w-full flex flex-col gap-5'>
                                <legend className='sub-heading'>Description</legend>
                                <DropdownInput name="Fabric" options={dropDownListObject((fabricList?.data ? fabricList?.data : []), "aliasName", "id")} value={fabricId} setValue={(value) => { setFabricId(value); }} readOnly={readOnly} required={true} />
                                <div className="grid grid-cols-2">
                                    <TextInput name="Sleeve" type="text" value={sleeve} setValue={setSleeve} required={true} readOnly={readOnly} />
                                    <TextInput name="Pattern" type="text" value={pattern} setValue={setPattern} required={true} readOnly={readOnly} />
                                </div>
                                <div className="grid grid-cols-2">
                                    <TextInput name="Occassion" type="text" value={occassion} setValue={setOccassion} required={true} readOnly={readOnly} />
                                    <TextInput name="Material" type="text" value={material} setValue={setMaterial} required={true} readOnly={readOnly} />
                                </div>
                                <div className="grid grid-cols-2">
                                    <TextInput name="Wash Care" type="text" value={washCare} setValue={setWashCare} required={true} readOnly={readOnly} />
                                    <TextInput name="Hsn" type="text" value={hsn} setValue={setHsn} required={true} readOnly={readOnly} />
                                </div>
                            </fieldset>
                        </div>
                    </div>
                    <div className="frame hidden md:block overflow-x-hidden">
                        <FormReport
                            searchValue={searchValue}
                            setSearchValue={setSearchValue}
                            setId={setId}
                            tableHeaders={tableHeaders}
                            tableDataNames={tableDataNames}
                            data={allData?.data ? allData?.data : []}
                            loading={
                                isLoading || isFetching
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
