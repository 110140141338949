import React, { useEffect, useState, useRef, useCallback } from "react";
import secureLocalStorage from "react-secure-storage";
import {
    useAddProcessGroupMasterMutation, useDeleteProcessGroupMasterMutation, useGetProcessGroupMasterByIdQuery, useGetProcessGroupMasterQuery, useUpdateProcessGroupMasterMutation

} from "../../../redux/ErpServices/ProcessGroupMasterServices";
import { useGetProcessQuery } from "../../../redux/ErpServices/processMasterServices"
import FormHeader from "../../../Basic/components/FormHeader";
import FormReport from "../../../Basic/components/FormReportTemplate";
import { toast } from "react-toastify";
import { TextInput, CheckBox } from "../../../Inputs";
import ReportTemplate from '../../../Basic/components/ReportTemplate'
import Modal from "../../../UiComponents/Modal";
import ProcessFillGrid from "./ProcessFillGrid";
import { DELETE, PLUS } from "../../../icons";
import { findFromList, getCommonParams } from "../../../Utils/helper";
const MODEL = "Process Group Master";

export default function Form() {
    const [form, setForm] = useState(false);

    const [readOnly, setReadOnly] = useState(false);
    const [id, setId] = useState("");
    const [name, setName] = useState("");
    const [code, setCode] = useState("");
    const [active, setActive] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [processDetails, setProcessDetails] = useState([]);
    const [details, setDetails] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const childRecord = useRef(0);


    const params = {
        companyId: secureLocalStorage.getItem(
            sessionStorage.getItem("sessionId") + "userCompanyId"
        ),
    };
    const { data: allData, isLoading, isFetching } = useGetProcessGroupMasterQuery({ params, searchParams: searchValue });

    const {
        data: singleData,
        isFetching: isSingleFetching,
        isLoading: isSingleLoading,
    } = useGetProcessGroupMasterByIdQuery(id, { skip: !id });



    const [addData] = useAddProcessGroupMasterMutation();
    const [updateData] = useUpdateProcessGroupMasterMutation();
    const [removeData] = useDeleteProcessGroupMasterMutation();



    const syncFormWithDb = useCallback(
        (data) => {
            if (id) setReadOnly(true);
            setName(data?.name ? data.name : "");
            setCode(data?.code ? data.code : "");
            setActive(id ? (data?.active ? data.active : false) : true);
            setProcessDetails((data?.ProcessGroupDetails || []).map(detail => ({
                processId: detail.processId,
                sequence: detail.sequence,
            })))
        },
        [id]
    );

    useEffect(() => {
        syncFormWithDb(singleData?.data);
    }, [isSingleFetching, isSingleLoading, id, syncFormWithDb, singleData]);

    const data = {
        id, name,
        processDetails: processDetails
            ? processDetails.map(item => ({ processId: item.processId, sequence: item.sequence }))
            : undefined

    }


    const validateData = (data) => {
        if (data.name) {
            return true;
        }
        return false;
    }

    const handleSubmitCustom = async (callback, data, text) => {
        try {
            let returnData = await callback(data).unwrap();
            setId(returnData.data.id)
            toast.success(text + "Successfully");
        } catch (error) {
            console.log("handle");
        }
    };

    const saveData = () => {
        if (!validateData(data)) {
            toast.info("Please fill all required fields...!", {
                position: "top-center",
            });
            return;
        }

        if (!window.confirm("Are you sure save the details ...?")) {
            return;
        }
        if (id) {
            handleSubmitCustom(updateData, data, "Updated");
        } else {
            handleSubmitCustom(addData, data, "Added");
        }
    };

    const deleteData = async () => {
        if (id) {
            if (!window.confirm("Are you sure to delete...?")) {
                return;
            }
            try {
                await removeData(id)
                setId("");
                toast.success("Deleted Successfully");
            } catch (error) {
                toast.error("something went wrong");
            }
        }
    };

    const handleKeyDown = (event) => {
        let charCode = String.fromCharCode(event.which).toLowerCase();
        if ((event.ctrlKey || event.metaKey) && charCode === "s") {
            event.preventDefault();
            saveData();
        }
    };

    const onNew = () => {
        setId("");
        setForm(true);
        setSearchValue("");
        syncFormWithDb(undefined)
        setReadOnly(false);
    };

    function onDataClick(id) {
        setId(id);
        setForm(true);
    }

    const tableHeaders = [
        "Code", "Name", "Status"
    ]
    const tableDataNames = ["dataObj.code", "dataObj.name", 'dataObj.active ? ACTIVE : INACTIVE']


    const openModal = () => {
        setIsOpen(true);
        console.log("clicked")
    };

    const closeModal = () => {
        setIsOpen(false);
    };
    function removeProcess(id) {
        setProcessDetails(prev => prev.filter(i => parseInt(i.processId) !== parseInt(id)))
    }
    function handleChangeGrid(index, field, value) {
        setProcessDetails(prev => {
            let newData = [...prev];
            newData[index][field] = value;
            return newData
        })
    }
    const { companyId } = getCommonParams();

    const { data: processData } = useGetProcessQuery({ params: { companyId } });
    const processList = processData?.data || [];

    if (!form)
        return (
            <ReportTemplate
                heading={MODEL}
                tableHeaders={tableHeaders}
                tableDataNames={tableDataNames}
                loading={
                    isLoading || isFetching
                }
                setForm={setForm}
                data={allData?.data}
                onClick={onDataClick}
                onNew={onNew}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
            />
        );

    return (
        <>
            <Modal isOpen={isOpen} onClose={closeModal} widthClass={"w-[300px] "}>
                <ProcessFillGrid onClose={closeModal} processDetails={processDetails} setProcessDetails={setProcessDetails} />
            </Modal>
            <div
                onKeyDown={handleKeyDown}
                className="md:items-start md:justify-items-center grid h-full bg-theme"
            >
                <div className="flex flex-col frame w-full h-full">
                    <FormHeader
                        onNew={onNew}
                        onClose={() => {
                            setForm(false);
                            setSearchValue("");
                        }}
                        model={MODEL}
                        saveData={saveData}
                        setReadOnly={setReadOnly}
                        deleteData={deleteData}

                    />
                    <div className="flex-1 grid grid-cols-1 md:grid-cols-4 gap-x-2 overflow-clip">
                        <div className="col-span-3 grid md:grid-cols-2 border overflow-auto">
                            <div className='col-span-3 grid md:grid-cols-2 border overflow-auto'>
                                <div className='mr-1 md:ml-2'>
                                    <fieldset className='frame my-1'>
                                        <legend className='sub-heading'>Currency Info</legend>
                                        <div className='grid grid-cols-1 my-2'>
                                            <TextInput name="Process Group" type="text" value={name} setValue={setName} required={true} readOnly={readOnly} disabled={(childRecord.current > 0)} />
                                            <CheckBox name="Active" readOnly={readOnly} value={active} setValue={setActive} />
                                        </div>
                                    </fieldset>
                                </div>
                                <div className='mr-1 md:ml-2'>
                                    <fieldset className='frame my-1'>
                                        <legend className='sub-heading'>Process Info</legend>
                                        <div className='grid grid-cols-1 my-2'>
                                            <table className='w-full h-full '>
                                                <thead className='thead'>
                                                    <tr className='table-row'>
                                                        <td>Process</td>
                                                        <td>Sequence</td>
                                                        <td onClick={openModal} className="text-green-700">{PLUS}</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {processDetails.map((processDetail, index) =>
                                                        <tr key={processDetail.processId} className='table-row'>
                                                            <td>
                                                                {findFromList(processDetail.processId, processList, "name")}
                                                            </td>
                                                            <td>
                                                                <input type="text" value={processDetail?.sequence}
                                                                    onChange={(e) => { handleChangeGrid(index, "sequence", e.target.value) }} />
                                                            </td>
                                                            <td onClick={() => { removeProcess(processDetail.processId) }}>
                                                                {DELETE}
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>

                        </div>
                        <div className="frame hidden md:block overflow-x-hidden">
                            <FormReport
                                searchValue={searchValue}
                                setSearchValue={setSearchValue}
                                setId={setId}
                                tableHeaders={tableHeaders}
                                tableDataNames={tableDataNames}
                                data={allData?.data}
                                loading={
                                    isLoading || isFetching
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
