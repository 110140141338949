import React, { useMemo, useRef } from 'react'
import { useEffect, useState } from "react";
import { findFromList, getCommonParams, getDateFromDateTimeToDisplay } from "../../../Utils/helper";
import { approvalFilterOptions, showEntries } from '../../../Utils/DropdownData';
import secureLocalStorage from 'react-secure-storage';
import { pageNumberToReactPaginateIndex, reactPaginateIndexToPageNumber } from '../../../Utils/helper';
import ReactPaginate from 'react-paginate';
import { useGetCostingQuery, useUpdateCostingMutation } from '../../../redux/ErpServices/CostingServices';
import { toast } from 'react-toastify';
import Modal from '../../../UiComponents/Modal';
import RemarksUpdate from './RemarksUpdate';
import RemarksUpdateBackToReview from './RemarksUpdateBackToReview';
import { DOUBLE_NEXT_ICON, DOUBLE_PREV_ICON, IMAGE_ICON, TICK_ICON } from '../../../icons';
import { sendNotification } from '../../../CustomHooks/useNotification';
import { useDispatch, useSelector } from 'react-redux';
import RemarksUpdateOrderConfirmation from './RemarksUpdateOrderConfirmation';
import RemarksUpdateOrderCancellation from './RemarksUpdateOrderCancellation';
import { exportComponentAsJPEG } from 'react-component-export-image';
import CostingImage from './CostingImage';
import { push } from '../../../redux/features/opentabs';
import { useGetUserByIdQuery } from '../../../redux/services/UsersMasterService';
const CostingApproval = () => {
  const openTabs = useSelector((state) => state.openTabs);
  const previewCostingId = useMemo(() => openTabs.tabs.find(i => i.name === "COSTING APPROVAL")?.previewId, [openTabs]);
  const branchId = secureLocalStorage.getItem(
    sessionStorage.getItem("sessionId") + "currentBranchId"
  )
  const [searchDocId, setSearchDocId] = useState("");
  const [searchDocDate, setSearchDocDate] = useState("");
  const [searchPartyName, setSearchPartyName] = useState("");
  const [searchStyleRefNo, setSearchStyleRefNo] = useState("")
  const [searchUpdatedDate, setSearchUpdatedDate] = useState('');
  const [currentSelectedCostingId, setCurrentSelectedCostingId] = useState('');
  const [backToReviewCostingId, setBackToReviewCostingId] = useState("");
  const [orderConfirmedCostingId, setOrderConfirmedCostingId] = useState('')
  const [orderCancellationCostingId, setOrderCancellationCostingId] = useState('');

  const [imagePreview, setImagePreview] = useState(false);

  const [previewItems, setPreviewItems] = useState([]);

  const [approvalFilter, setApprovalFilter] = useState('');

  const [dataPerPage, setDataPerPage] = useState("10");
  const [totalCount, setTotalCount] = useState(0);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const handleOnclick = (e) => {
    setCurrentPageNumber(reactPaginateIndexToPageNumber(e.selected));
  }
  const searchFields = useMemo(() => ({ searchDocId, searchDocDate, searchPartyName, searchStyleRefNo, searchUpdatedDate }), [searchDocId, searchDocDate, searchPartyName, searchStyleRefNo, searchUpdatedDate])

  const { finYearId, userId } = getCommonParams()

  let approvalParams = {}
  if (
    approvalFilter === "Order Confirmed"
  ) {
    approvalParams = { isOrderConfirmed: true }
  } else if (approvalFilter === "Approved") {
    approvalParams = { isOrderConfirmed: false, isApproved: true }
  } else if (approvalFilter === "Waiting For Approval") {
    approvalParams = { isOrderConfirmed: false, isApproved: false, submitToApproval: true }
  }

  const { data: allData, isLoading, isFetching } = useGetCostingQuery({ params: { branchId, userId, ...searchFields, pagination: true, dataPerPage, pageNumber: currentPageNumber, finYearId, approvalReport: true, submitToApproval: true, ...approvalParams } });


  useEffect(() => {
    setCurrentPageNumber(1)
  }, [searchFields, approvalFilter])


  useEffect(() => {
    setTotalCount(allData?.totalCount || 0)
  }, [allData, isLoading, isFetching])


  const [update] = useUpdateCostingMutation()

  async function approve(remarks) {
    if (!currentSelectedCostingId) return
    if (!remarks) return toast.info("Remarks Should not Empty...!!!")
    const returnData = await update({ id: currentSelectedCostingId, isApproved: true, remarks, userId }).unwrap();
    if (returnData?.statusCode === 0) {
      sendNotification(findFromList(currentSelectedCostingId, allData?.data || [], 'updatedById'))
      setCurrentSelectedCostingId("");
      return toast.success(returnData.message)
    } else {
      return toast.error(returnData.message)
    }
  }

  async function amend(remarks) {
    if (!currentSelectedCostingId) return
    if (!remarks) return toast.info("Remarks Should not Empty...!!!")
    const returnData = await update({ id: currentSelectedCostingId, isAmendCosting: true, remarks, userId }).unwrap();
    if (returnData?.statusCode === 0) {
      sendNotification(findFromList(currentSelectedCostingId, allData?.data || [], 'updatedById'))
      setCurrentSelectedCostingId("");
      return toast.success(returnData.message)
    } else {
      return toast.error(returnData.message)
    }
  }
  const isApproved = findFromList(currentSelectedCostingId, allData?.data || [], 'isApproved');

  async function handleSubmit(remarks) {
    if (!currentSelectedCostingId) return
    if (isApproved) {
      await amend(remarks)
    } else {
      await approve(remarks)
    }
  }

  async function backToReview(remarks) {
    if (!backToReviewCostingId) return
    if (!remarks) return toast.info("Remarks Should not Empty...!!!")
    const returnData = await update({ id: backToReviewCostingId, backToReview: true, remarks, userId }).unwrap();
    if (returnData?.statusCode === 0) {
      sendNotification(findFromList(backToReviewCostingId, allData?.data || [], 'updatedById'))
      setBackToReviewCostingId("");
      return toast.success(returnData.message)
    } else {
      return toast.error(returnData.message)
    }
  }

  async function confirmOrder(remarks) {
    if (!orderConfirmedCostingId) return
    if (!remarks) return toast.info("Remarks Should not Empty...!!!")
    const returnData = await update({ id: orderConfirmedCostingId, isOrderConfirmed: true, remarks, userId }).unwrap();
    if (returnData?.statusCode === 0) {
      sendNotification(findFromList(orderConfirmedCostingId, allData?.data || [], 'updatedById'))
      setOrderConfirmedCostingId("");
      return toast.success(returnData.message)
    } else {
      return toast.error(returnData.message)
    }
  }
  async function cancelOrder(remarks) {
    if (!orderCancellationCostingId) return
    if (!remarks) return toast.info("Remarks Should not Empty...!!!")
    const returnData = await update({ id: orderCancellationCostingId, orderCancelled: true, remarks, userId }).unwrap();
    if (returnData?.statusCode === 0) {
      sendNotification(findFromList(orderCancellationCostingId, allData?.data || [], 'updatedById'))
      setOrderCancellationCostingId("");
      return toast.success(returnData.message)
    } else {
      return toast.error(returnData.message)
    }
  }


  const columns = [
    { name: "S.No.", sticky: true, className: 'left-5 w-[10] text-center' },
    {
      name: "Doc No.", search: true, searchValue: searchDocId,
      setSearchValue: (value) => {
        if (previewCostingId) {
          dispatch(push({ name: "COSTING APPROVAL", previewId: null }));
        }
        setSearchDocId(value)
      }, sticky: true, className: 'left-[55px]'
    },
    {
      name: "Updated.Date", search: true, searchValue: searchUpdatedDate, setSearchValue: setSearchUpdatedDate,
      sticky: true, className: 'left-[140px] w-[50px]'
    },
    {
      name: "Customer", search: true, searchValue: searchPartyName, setSearchValue: setSearchPartyName,
      sticky: true, className: 'left-[220px] w-[100px]'
    },
    {
      name: "Product Style/Desc", search: true, searchValue: searchStyleRefNo, setSearchValue: setSearchStyleRefNo,
      sticky: true, className: 'left-[300px]'
    },
    // { name: "Size Range / Ratio" },
    { name: "Fabric Desc.", className: 'min-w-[200px]' },
    { name: "Fabric Cost" },
    { name: "Trim Fabric Cost" },
    { name: "Total Fabric Cost /Gmt" },
    { name: "Cmt" },
    { name: "Trims" },
    { name: "Print + Emb / Value addition" },
    { name: "Process Loss" },
    { name: "Transport" },
    { name: "Over Heads & Finance Cost" },
    { name: "Total Cost" },
    { name: "Quote" },
    { name: "Profit Value / %" },
    { name: "Remarks" },
    { name: "Status" },
  ]
  const { data: userData } = useGetUserByIdQuery(userId)
  const isAdmin = userData?.data?.isAdmin;
  if (isAdmin) {
    columns.push({ name: "Actions" });
  }
  columns.push({ name: "Approval Remarks" });

  const dispatch = useDispatch()


  useEffect(() => {
    if (!previewCostingId) return
    let searchDocId = findFromList(previewCostingId, allData?.data || [], "docId")
    console.log(searchDocId);
    setSearchDocId(searchDocId);
    // dispatch(push({ name: "COSTING APPROVAL", previewId: null }));
  }, [previewCostingId, allData, dispatch])


  function isSelected(id) {
    return previewItems.findIndex(i => parseInt(i.id) === parseInt(id)) !== -1
  }

  function isAllSelected() {
    return (allData?.data || []).every(i => isSelected(i.id))
  }

  function selectAll() {
    if (isAllSelected()) {
      (allData?.data || []).forEach(item => {
        removeItem(item)
      });
    } else {
      (allData?.data || []).forEach(item => {
        addItem(item)
      });
    }
  }

  function addItem(item) {
    setPreviewItems(prev => [...prev, item])
  }
  function removeItem(item) {
    setPreviewItems(prev => prev.filter(i => parseInt(i.id) !== parseInt(item.id)))
  }
  function handleChange(item) {
    if (isSelected(item.id)) {
      removeItem(item)
    } else {
      addItem(item)
    }
  }

  const componentRef = useRef();


  return (
    <div className='relative overflow-y-clip h-full w-full'>
      <Modal widthClass={""} isOpen={Number.isInteger(currentSelectedCostingId)} onClose={() => { setCurrentSelectedCostingId('') }}  >
        <RemarksUpdate id={currentSelectedCostingId} isApproved={isApproved} handleSubmit={handleSubmit} onClose={() => { setCurrentSelectedCostingId('') }} />
      </Modal>
      <Modal widthClass={""} isOpen={Number.isInteger(backToReviewCostingId)} onClose={() => { setBackToReviewCostingId('') }}  >
        <RemarksUpdateBackToReview id={backToReviewCostingId} handleSubmit={backToReview} onClose={() => { setBackToReviewCostingId('') }} />
      </Modal>
      <Modal widthClass={""} isOpen={Number.isInteger(orderConfirmedCostingId)} onClose={() => { setOrderConfirmedCostingId('') }}  >
        <RemarksUpdateOrderConfirmation id={orderConfirmedCostingId} handleSubmit={confirmOrder} onClose={() => { setOrderConfirmedCostingId('') }} />
      </Modal>
      <Modal widthClass={""} isOpen={Number.isInteger(orderCancellationCostingId)} onClose={() => { setOrderCancellationCostingId('') }}  >
        <RemarksUpdateOrderCancellation id={orderCancellationCostingId} handleSubmit={cancelOrder} onClose={() => { setOrderCancellationCostingId('') }} />
      </Modal>
      <Modal widthClass={"w-[90%] h-[90%]"} isOpen={imagePreview} onClose={() => { setImagePreview(false) }}  >
        <CostingImage data={previewItems} ref={componentRef} download={() => exportComponentAsJPEG(componentRef)} />
      </Modal>
      <div className='flex flex-col overflow-y-clip'>
        <div className="md:flex md:items-center md:justify-between page-heading p-1">
          <div className="heading text-center md:mx-10">Costing Approval</div>
          <div className='flex'>
            <button onClick={() => { setImagePreview(true); }} className='w-[150px] h-[30px] flex items-center gap-1' >
              <span>Download</span>
              <span className='w-[30px] h-[30px]'>
                {IMAGE_ICON}
              </span>
            </button>
            <div className=" sub-heading justify-center md:justify-start items-center">
              <label className="text-white text-xs rounded-md m-1  border-none">Status</label>
              <select value={approvalFilter}
                onChange={(e) => setApprovalFilter(e.target.value)} className='h-6 w-40 border border-gray-500 rounded mr-9'>
                {approvalFilterOptions.map((option) => <option key={option.value} value={option.value} >{option.show}</option>)}
              </select>
            </div>
            <div className=" sub-heading justify-center md:justify-start items-center">
              <label className="text-white text-xs rounded-md m-1  border-none">Show Entries</label>
              <select value={dataPerPage}
                onChange={(e) => setDataPerPage(e.target.value)} className='h-6 w-40 border border-gray-500 rounded mr-9'>
                {showEntries.map((option) => <option key={option.value} value={option.value} >{option.show}</option>)}
              </select>
            </div>
          </div>
        </div>
        <div className='w-full overflow-x-auto max-h-[500px] overflow-y-auto'>
          <table id='table-to-xl' className="min-w-full h-full relative">
            <thead className="sticky top-0 page-heading z-40 bg-[#388087]">
              <tr className=' bg-[#388087]'>
                <th
                  scope="col"
                  className={`text-center sticky left-0 bg-[#388087] text-gray-200 text-xs font-medium  
                    `}
                >
                  <input type='checkbox' checked={isAllSelected()} onChange={selectAll} />
                </th>
                {columns.map((i) =>
                  <th
                    key={i.name}
                    scope="col"
                    className={`px-1 text-center bg-[#388087] text-gray-200 text-xs font-medium  
                  ${i.sticky ? `sticky` : ""}
                    ${i.className}`}
                  >
                    <div className='text-center'>
                      {i.name}
                    </div>
                    {i.search &&
                      <input
                        type="text"
                        className="text-black p-1 focus:outline-none w-[80px] bg-gray-200 rounded-lg"
                        placeholder="Search"
                        value={i.searchValue}
                        onChange={(e) => {
                          i.setSearchValue(e.target.value);
                        }}
                      />
                    }
                  </th>
                )}
              </tr>
            </thead>
            <tbody className="">
              {(allData?.data || []).map((dataObj, index) =>
                <tr key={dataObj.id} className={`${parseInt(previewCostingId) === parseInt(dataObj.id) ? "bg-green-200" : ""}`}  >

                  <td className="px-1 py-1 whitespace-nowrap border-r border-gray-300 text-left sticky left-0 stick-bg">
                    <div >
                      <input type='checkbox' checked={isSelected(dataObj.id)} onChange={() => { handleChange(dataObj) }} />
                    </div>
                  </td>
                  <td className="px-1 py-1 whitespace-nowrap border-r border-gray-300 text-left sticky left-5 stick-bg">
                    <div className='text-center' >
                      {(index + 1) + (dataPerPage * (currentPageNumber - 1))}
                    </div>
                  </td>
                  <td className={`px-1 py-1 whitespace-nowrap border-r border-gray-300 sticky ${columns[1]?.['className']} stick-bg`}>
                    <div className="flex items-center">
                      <div className="ml-4">
                        <button className="text-xs font-medium underline text-blue-500" onClick={() => {
                          dispatch(push({ name: "COSTING", previewId: dataObj?.id }));
                          dispatch({
                            type: `Costing/invalidateTags`,
                            payload: ['Costing'],
                          });
                        }} >{(dataObj?.docId || '').split("/")?.at(-1)}</button>
                      </div>
                    </div>
                  </td>
                  {/* <td className={` py-1 whitespace-nowrap border-r border-gray-300 sticky ${columns[2]?.['className']} stick-bg`}>
                  <div className="text-xs text-gray-900">
                    {getDateFromDateTimeToDisplay(dataObj.createdAt)}
                  </div>
                </td> */}
                  <td className={` py-1 whitespace-nowrap border-r border-gray-300 sticky ${columns[2]?.['className']} stick-bg`}>
                    <div className="text-xs text-gray-900">
                      {getDateFromDateTimeToDisplay(dataObj.updatedAt)}
                    </div>
                  </td>
                  <td className={` py-1 border-r border-gray-300 text-xs text-gray-500 sticky ${columns[3]?.['className']} stick-bg`}>
                    {dataObj?.Party?.aliasName}
                  </td>
                  <td className={` py-1 whitespace-nowrap border-r border-gray-300 text-xs text-gray-500 sticky ${columns[4]?.['className']} stick-bg`}>
                    <div className="flex items-center flex-col">
                      {/* {dataObj?.styleImage &&
                        <img
                          className="h-[100px] w-[100px] rounded-full"
                          src={getImageUrlPath(dataObj?.styleImage)}
                          alt=""
                        />
                      } */}
                      <div className="text-xs font-medium text-gray-900">
                        {dataObj?.styleRefNo}
                      </div>
                    </div>
                  </td>
                  {/* <td className=" py-1 whitespace-nowrap border-r border-gray-300  text-xs font-medium">
                    {dataObj?.SizeTemplate?.name}/{dataObj?.Ratio?.name}
                  </td> */}

                  <td className={`py-1 border-r border-gray-300  text-wrap text-[10px] font-medium ${columns?.[7]?.className}`}>
                    {dataObj?.fabricDesc}
                  </td>
                  <td className=" py-1 whitespace-nowrap border-r border-gray-300  text-xs font-medium text-right">
                    {dataObj?.fabricCostPerGmt}
                  </td>
                  <td className=" py-1 whitespace-nowrap border-r border-gray-300  text-xs font-medium text-right">
                    {dataObj?.trimFabricCostPerGmt}
                  </td>
                  <td className=" py-1 whitespace-nowrap border-r border-gray-300  text-xs font-medium text-right">
                    {dataObj?.totalFabricCostPerGmt}
                  </td>
                  <td className=" py-1 whitespace-nowrap border-r border-gray-300  text-xs font-medium text-right">
                    {dataObj?.cmtCost}
                  </td>
                  <td className=" py-1 whitespace-nowrap border-r border-gray-300  text-xs font-medium text-right">
                    {dataObj?.trimsCost}
                  </td>
                  <td className=" py-1 whitespace-nowrap border-r border-gray-300  text-xs font-medium text-right">
                    {dataObj?.printingAndEmbCost}
                  </td>
                  <td className=" py-1 whitespace-nowrap border-r border-gray-300  text-xs font-medium text-right">
                    {dataObj?.garmentCost}
                  </td>
                  <td className=" py-1 whitespace-nowrap border-r border-gray-300  text-xs font-medium text-right">
                    {dataObj?.transportCost}
                  </td>
                  <td className=" py-1 whitespace-nowrap border-r border-gray-300  text-xs font-medium text-right">
                    {dataObj?.overHeadsAndFinance}
                  </td>
                  <td className=" py-1 whitespace-nowrap border-r border-gray-300  text-xs font-medium text-right">
                    {dataObj?.totalCost}
                  </td>
                  <td className=" py-1 whitespace-nowrap border-r border-gray-300  text-xs text-right font-bold">
                    {dataObj?.quoteCost}
                  </td>
                  <td className=" py-1 whitespace-nowrap border-r border-gray-300  text-xs font-medium text-right">
                    {dataObj?.marginCost} / {dataObj?.marginPercent}%
                  </td>
                  <td className=" py-1 whitespace-nowrap border-r border-gray-300  text-xs font-medium ">
                    {dataObj?.costingRemarks}
                  </td>
                  <td className=" py-1 whitespace-nowrap border-r border-gray-300 text-center text-xs font-medium ">
                    {dataObj.isOrderConfirmed ?
                      <div className='bg-green-600 text-white p-1 rounded' >
                        Order Confirmed
                      </div>
                      :
                      <>
                        {dataObj.isApproved ?
                          <div className='bg-green-400 text-white p-1 rounded' >
                            Approved
                          </div>
                          :
                          <div className='bg-yellow-600 text-white p-1 rounded'>
                            Waiting For Approval
                          </div>
                        }
                      </>
                    }
                  </td>
                  {isAdmin &&
                    <td className=" py-1 whitespace-nowrap border-r border-gray-300  text-xs font-medium">
                      <div className='grid gap-2'>
                        {dataObj.isOrderConfirmed ?
                          <div className='flex gap-3 justify-center'>
                            <button title='Order Cancelled' className='bg-orange-600 p-1 text-white rounded' onClick={() => { setOrderCancellationCostingId(dataObj.id) }}>
                              {DOUBLE_PREV_ICON}
                            </button>
                          </div>
                          :
                          <>
                            {!(dataObj.isApproved)
                              ?
                              <div className='flex gap-3 justify-center'>
                                <button title='Back To Review' className='bg-yellow-600 p-1 text-white rounded' onClick={() => { setBackToReviewCostingId(dataObj.id) }}>
                                  {DOUBLE_PREV_ICON}
                                </button>
                                <button title='Click to Approve' className='bg-blue-500 p-1 text-white rounded' onClick={() => { setCurrentSelectedCostingId(dataObj.id) }}>
                                  {TICK_ICON}
                                </button>
                              </div>
                              :
                              <div className='flex gap-3 justify-center'>
                                <button title='Revert Approval' className='bg-red-800 p-1 text-white rounded' onClick={() => { setCurrentSelectedCostingId(dataObj.id) }}>
                                  {DOUBLE_PREV_ICON}
                                </button>
                                <button title='Confirm Order' className='bg-green-600 p-1 text-white rounded' onClick={() => { setOrderConfirmedCostingId(dataObj.id) }}>
                                  {DOUBLE_NEXT_ICON}
                                </button>
                              </div>
                            }
                          </>
                        }

                      </div>
                    </td>
                  }
                  <td className=" py-1 whitespace-nowrap border-r border-gray-300  text-xs font-medium ">
                    {dataObj?.remarks}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className='bg-gray-50'>
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            forcePage={pageNumberToReactPaginateIndex(currentPageNumber)}
            pageCount={Math.ceil(totalCount / dataPerPage)}
            marginPagesDisplayed={1}
            onPageChange={handleOnclick}
            containerClassName={"flex justify-center m-2 gap-5 items-center"}
            pageClassName={"border custom-circle text-center"}
            disabledClassName={"p-1 bg-gray-200"}
            previousLinkClassName={"border p-1 text-center"}
            nextLinkClassName={"border p-1"}
            activeClassName={"bg-[#388087] text-white px-2"} />
        </div>
        {/* <div className='-mt-96 -z-50 absolute -top-96 
      ' ref={componentRef}>
          <CostingImage data={previewItems} />
        </div> */}
      </div>
    </div>
  )
}

export default CostingApproval