import React, { Fragment, useRef } from 'react'
import { useEffect, useState } from "react";
import { Loader } from "../../../Basic/components";
import { getDateFromDateTimeToDisplay, substract } from "../../../Utils/helper";
import { showEntries } from '../../../Utils/DropdownData';
import secureLocalStorage from 'react-secure-storage';
import {
  useGetPoByIdQuery,
  useGetPoQuery
} from "../../../redux/ErpServices/PoServices"
import { pageNumberToReactPaginateIndex, reactPaginateIndexToPageNumber } from '../../../Utils/helper';
import ReactPaginate from 'react-paginate';
import YarnPoItemSelection from './YarnPoItemSelection';
import FabricPoItemSelection from './FabricPoItemSelection';
import AccessoryPoItemSelection from './AccessoryPoItemSelection';
import { useGetPartyQuery } from '../../../redux/ErpServices/PartyMasterServices';
import parameterIcon from "../../../assets/icons8-filter.gif"
import Modal from '../../../UiComponents/Modal';
import Parameter from './Parameter';
import { exportFileToCsv } from "../../../Utils/excelHelper"
import { ExcelButton } from '../../../Buttons';


const PurchaseRegister = () => {
  const [partyId, setPartyId] = useState("");
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [parameter, setParameter] = useState(false);

  const [excelData, setExcelData] = useState([]);


  const [filterParties, setFilterParties] = useState([])
  const [filterPoTypes, setFilterPoTypes] = useState([])

  const branchId = secureLocalStorage.getItem(
    sessionStorage.getItem("sessionId") + "currentBranchId"
  )
  const [poNo, setPoNo] = useState("");
  const [searchPoDate, setPoDate] = useState("");
  const [searchDueDate, setDueDate] = useState("");
  const [searchPoType, setSearchPoType] = useState("");
  const [supplier, setSupplier] = useState("");
  const [dataPerPage, setDataPerPage] = useState("10");
  const [totalCount, setTotalCount] = useState(0);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  const [currentOpenNumber, setCurrentOpenNumber] = useState("");

  const [currentSelectedPoDetail, setCurrentSelectedPoDetail] = useState("");

  const handleOnclick = (e) => {
    setCurrentPageNumber(reactPaginateIndexToPageNumber(e.selected));
  }
  const searchFields = { searchDocId: poNo, searchPoDate, searchSupplierAliasName: supplier, searchPoType, searchDueDate }

  const companyId = secureLocalStorage.getItem(
    sessionStorage.getItem("sessionId") + "userCompanyId"
  )
  const params = {
    branchId, companyId
  };

  const { data: supplierList, isLoading: isSupplierLoading, isFetching: isSupplierFetching } =
    useGetPartyQuery({ params: { companyId, active: true } });


  const { data: paginatedData, isLoading, isFetching } = useGetPoQuery({
    params: {
      branchId, ...searchFields, pagination: true, dataPerPage, pageNumber: currentPageNumber, supplierId: partyId, startDate, endDate,
      filterParties: filterParties.map(item => item.value),
      filterPoTypes: filterPoTypes.map(item => item.value)
    }
  });

  const { data: allData, isLoading: isAllDataLoading, isFetching: isAllDataFetching } = useGetPoQuery({
    params: {
      branchId, ...searchFields,
      supplierId: partyId, startDate, endDate,
      filterParties: filterParties.map(item => item.value),
      filterPoTypes: filterPoTypes.map(item => item.value)
    }
  })

  useEffect(() => {
    if (paginatedData?.totalCount) {
      setTotalCount(paginatedData?.totalCount)
    } else {
      setTotalCount(0)
    }
  }, [paginatedData, isLoading, isFetching])

  useEffect(() => {
    setCurrentPageNumber(1)
  }, [dataPerPage, poNo, searchPoDate, supplier, searchPoType, searchDueDate])

  const allSuppliers = supplierList ? supplierList.data : []

  const tableRef = useRef(null);

  const { data: poDetails, isLoading: isLoadingPoDetails, isFetching: isFetchingPoDetails } = useGetPoByIdQuery(currentSelectedPoDetail, { skip: !currentSelectedPoDetail });
  useEffect(() => {
    if (!poDetails?.data || !currentSelectedPoDetail) return
    let excelPoItems = poDetails.data.PoItems.map(poItem => {
      let poQty = parseFloat(poItem.qty).toFixed(3)
      let cancelQty = poItem.alreadyCancelData?._sum.qty ? poItem.alreadyCancelData._sum.qty : "0.000";
      let alreadyInwardedQty = poItem.alreadyInwardedData?._sum?.qty ? parseFloat(poItem.alreadyInwardedData._sum.qty).toFixed(3) : "0.000";
      let alreadyReturnedQty = poItem.alreadyReturnedData?._sum?.qty ? parseFloat(poItem.alreadyReturnedData._sum.qty).toFixed(3) : "0.000";
      let balanceQty = substract(substract(poQty, cancelQty), substract(alreadyInwardedQty, alreadyReturnedQty)).toFixed(3)
      let poBags = poItem.noOfBags
      let cancelBags = poItem.alreadyCancelData?._sum.noOfBags ? poItem.alreadyCancelData._sum.noOfBags : 0.000;
      let alreadyInwardedBags = poItem.alreadyInwardedData?._sum?.noOfBags ? poItem.alreadyInwardedData._sum.noOfBags : 0.000;
      let alreadyReturnedBags = poItem.alreadyReturnedData?._sum?.noOfBags ? parseFloat(poItem.alreadyReturnedData._sum.noOfBags).toFixed(3) : "0.000";
      let balanceBags = substract(substract(poBags, cancelBags), substract(alreadyInwardedBags, alreadyReturnedBags))

      let alreadyBillQty = poItem?.alreadyBillData?._sum?.qty ? poItem?.alreadyBillData._sum.qty : 0;
      let balBillQty = substract(poQty, alreadyBillQty);
      balBillQty = balBillQty > 0 ? balBillQty : 0

      let newItem = {}
      if (poDetails.data.transType === "GreyYarn" || poDetails.data.transType === "DyedYarn") {
        newItem["Item"] = poItem.Yarn.aliasName;
        newItem["Po Bags"] = poBags;
        newItem["Cancel Bags"] = cancelBags;
        newItem["Inward Bags"] = alreadyInwardedBags;
        newItem["Return Bags"] = alreadyReturnedBags;
        newItem["Balance Bags"] = balanceBags;
      } else if (poDetails.data.transType === "GreyFabric" || poDetails.data.transType === "DyedFabric") {
        newItem["Item"] = poItem.Fabric.aliasName;
        newItem["Design"] = poItem.Design.name;
        newItem["Gauge"] = poItem.Gauge.name;
        newItem["K-Dia"] = poItem.KDia.name;
        newItem["F-Dia"] = poItem.FDia.name;
      } else {
        newItem["Item"] = poItem.Accessory.aliasName;
        newItem["Accessory Item"] = poItem.Accessory.accessoryItem.name;
        newItem["Accessory Group"] = poItem.Accessory.accessoryItem.AccessoryGroup.name
      }
      newItem["Uom"] = poItem.Uom.name
      newItem["Color"] = poItem.Color.name
      newItem["Po Qty"] = poQty;
      newItem["Cancel Qty"] = cancelQty;
      newItem["Inward Qty"] = alreadyInwardedQty;
      newItem["Return Qty"] = alreadyReturnedQty;
      newItem["Balance Qty"] = balanceQty;
      newItem["Bill Qty"] = alreadyBillQty;
      newItem["Bal. Bill Qty"] = balBillQty;
      return newItem
    })
    exportFileToCsv([{ "Po No": poDetails.data.docId, "Supplier": poDetails?.data?.supplier?.aliasName }, ...excelPoItems,], `${poDetails.data.docId}`);
    setCurrentSelectedPoDetail("");
  }, [isLoadingPoDetails, poDetails, isFetchingPoDetails, currentSelectedPoDetail])

  useEffect(() => {
    if (allData?.data) {
      setExcelData(allData.data.map(item => {
        let newItem = {};
        newItem["Po. No"] = item?.docId;
        newItem["Po. Date"] = getDateFromDateTimeToDisplay(item?.createdAt);
        newItem["Supplier"] = item?.supplier?.aliasName;
        newItem["Po Type"] = item?.transType;
        newItem["Due Date"] = getDateFromDateTimeToDisplay(item?.dueDate);
        newItem["Po Qty"] = item?.poQty ? item?.poQty : 0;
        return newItem
      }))
    }
  }, [allData, isAllDataLoading, isAllDataFetching])

  if (isLoading || isFetching || isSupplierFetching || isSupplierLoading || isAllDataFetching || isAllDataLoading) return <Loader />

  const isLoadingIndicator = isLoading || isFetching
  return (
    <>
      <Modal isOpen={parameter} widthClass={"bg-gray-200"} onClose={() => { setParameter(false) }} >
        <Parameter onClose={() => { setParameter(false) }} startDate={startDate} setStartDate={setStartDate} allSuppliers={allSuppliers} endDate={endDate} setEndDate={setEndDate}
          filterPoTypes={filterPoTypes} setFilterPoTypes={setFilterPoTypes}
          filterParties={filterParties} setFilterParties={setFilterParties}
          partyId={partyId} setPartyId={setPartyId} />
      </Modal>
      <div className="flex flex-col w-full h-[95%] overflow-auto">
        <div className="flex items-center justify-between w-full page-heading p-1 text-black">
          <div className="heading text-center whitespace-nowrap">Purchase Register</div>
          <span className='flex gap-4'>
            <ExcelButton onClick={() => exportFileToCsv(excelData, "Purchase Register")} width={40} />
            <button className='w-7 h-8' onClick={() => setParameter(true)}>
              <img src={parameterIcon} alt='parameter' />
            </button>
            <div className=" sub-heading justify-center md:justify-start items-center w-72 flex">
              <p className="text-white text-sm rounded-md m-1  border-none">Show Entries</p>
              <select value={dataPerPage}
                onChange={(e) => setDataPerPage(e.target.value)} className='h-6 w-40 border border-gray-500 rounded mr-9'>
                {showEntries.map((option, index) => <option key={index} value={option.value} >{option.show}</option>)}
              </select>
            </div>
          </span>
        </div>
        <>
          <div
            className="h-[500px] overflow-auto"
          >
            <table ref={tableRef} id="table-to-xls" className="table-fixed text-center w-full">
              <thead className=" table-header">
                <tr className='h-2'>
                  <th
                    className="  top-0 stick-bg w-10">
                    S. no.
                  </th>
                  <th
                    className="  top-0 stick-bg table-data "
                  >
                    <label>Po.No</label>
                    {/* <input
                    type="text"
                    className="text-black h-6 focus:outline-none border md:ml-3 border-gray-400 rounded-lg "
                    placeholder="Search"
                    value={poNo}
                    onChange={(e) => {
                      setPoNo(e.target.value);
                    }}
                  /> */}
                  </th>
                  <th
                    className="  top-0 stick-bg table-data"
                  >
                    <label>Po.Date</label>
                    {/* <input
                    type="text"
                    className="text-black h-6 focus:outline-none border md:ml-3 border-gray-400 rounded-lg"
                    placeholder="Search"
                    value={searchPoDate}
                    onChange={(e) => {
                      setPoDate(e.target.value);
                    }}
                  /> */}
                  </th>
                  <th

                    className=" table-data top-0 stick-bg "
                  >
                    <label>Supplier</label>
                    {/* <input
                    type="text"
                    className="text-black h-6 focus:outline-none border md:ml-3 border-gray-400 rounded-lg"
                    placeholder="Search"
                    value={supplier}
                    onChange={(e) => {
                      setSupplier(e.target.value);
                    }}
                  /> */}
                  </th>
                  <th
                    className="  top-0 stick-bg table-data "
                  >
                    <label>Po Type</label>
                    {/* <input
                    type="text"
                    className="text-black h-6 focus:outline-none border md:ml-3 border-gray-400 rounded-lg"
                    placeholder="Search"
                    value={searchPoType}
                    onChange={(e) => {
                      setSearchPoType(e.target.value);
                    }}
                  /> */}
                  </th>
                  <th className="  top-0 stick-bg table-data">
                    <label>Due Date</label>
                    {/* <input
                    type="text"
                    className="text-black h-6 focus:outline-none border md:ml-3 border-gray-400 rounded-lg"
                    placeholder="Search"
                    value={searchDueDate}
                    onChange={(e) => {
                      setDueDate(e.target.value);
                    }}
                  /> */}
                  </th>
                  <th className="  top-0 stick-bg table-data">
                    Qty
                  </th>
                  <th className=" top-0 stick-bg table-data w-10">
                  </th>
                </tr>
              </thead>
              {isLoadingIndicator ?
                <tbody>
                  <tr>
                    <td>
                      <Loader />
                    </td>
                  </tr>
                </tbody>
                :
                <tbody className="">
                  {paginatedData.data.map((dataObj, index) => (
                    <Fragment key={index}>
                      <tr
                        className={` table-row ${(currentOpenNumber === index) ? "border-2 border-black" : ""}`}
                        onClick={() => {
                          if (index === currentOpenNumber) {
                            setCurrentOpenNumber("")
                          } else {
                            setCurrentOpenNumber(index)
                          }
                        }}>
                        <td className='py-1 '> {(index + 1) + (dataPerPage * (currentPageNumber - 1))}</td>
                        <td className='py-1 text-left'> {dataObj.docId}</td>
                        <td className='py-1 text-left'>{getDateFromDateTimeToDisplay(dataObj.createdAt)} </td>
                        <td className='py-1 text-left'>{dataObj?.supplier?.aliasName}</td>
                        <td className='py-1 text-left'>{dataObj.transType}</td>
                        <td className='py-1 text-left'>{getDateFromDateTimeToDisplay(dataObj.dueDate)}</td>
                        <td className='py-1 text-right'>{(dataObj?.poQty ? dataObj?.poQty : 0).toFixed(3)}</td>
                        <td className='py-1 text-center w-8'>
                          <ExcelButton onClick={(e) => {
                            e.stopPropagation();
                            setCurrentSelectedPoDetail(dataObj.id);
                          }} width={18} />
                        </td>
                      </tr>
                      {(currentOpenNumber === index)
                        &&
                        <tr className={""}>
                          <td colSpan={8}>
                            {(dataObj?.transType === "GreyYarn") || (dataObj?.transType === "DyedYarn") ?
                              <YarnPoItemSelection id={dataObj.id} dataObj={dataObj} />
                              :
                              <>
                                {(dataObj?.transType === "GreyFabric") || (dataObj?.transType === "DyedFabric") ?
                                  <FabricPoItemSelection id={dataObj.id} dataObj={dataObj} />
                                  :
                                  <AccessoryPoItemSelection id={dataObj.id} dataObj={dataObj} />
                                }
                              </>
                            }
                          </td>
                        </tr>
                      }
                    </Fragment>
                  ))}
                </tbody>
              }
            </table>
          </div>
        </>
        <ReactPaginate
          previousLabel={"<"}
          nextLabel={">"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          forcePage={pageNumberToReactPaginateIndex(currentPageNumber)}
          pageCount={Math.ceil(totalCount / dataPerPage)}
          marginPagesDisplayed={1}
          onPageChange={handleOnclick}
          containerClassName={"flex justify-center m-2 gap-5 items-center"}
          pageClassName={"border custom-circle text-center"}
          disabledClassName={"p-1 bg-gray-200"}
          previousLinkClassName={"border p-1 text-center"}
          nextLinkClassName={"border p-1"}
          activeClassName={"bg-[#388087] text-white px-2"} />
      </div>
    </>
  )
}

export default PurchaseRegister