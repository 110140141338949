import React from 'react';

const TargetComparision = ({
  conversionValue, targetRate, setTargetRate, readOnly, overAllTotal
}) => {

  const fobValueIndianValue = (isFinite(parseFloat(targetRate) / conversionValue) ? parseFloat(targetRate) / conversionValue : 0)
  const margin = fobValueIndianValue - overAllTotal;

  const marginPercent = (isFinite(margin / fobValueIndianValue) ? (margin / fobValueIndianValue) : 0) * 100;

  return (
    <fieldset className='frame rounded-tr-lg rounded-bl-lg w-full border border-gray-600 p-1'>
      <div className='bg-[#388087] text-white text-center p-1'>Comparision</div>
      <div className='flex flex-col mt-3 items-center justify-center ' >
        <div className='flex flex-col items-center justify-center p-1 w-full '>
          <div className='grid grid-cols-3 items-center justify-center text-right w-full text-sm '>
            <div className=' bg-green-600 text-white text-center'>Garment Cost</div>
            <div className=' border border-green-600'>{(overAllTotal).toFixed(3)}</div>
            <div className=' border border-green-600'>{(overAllTotal * conversionValue).toFixed(3)}</div>
          </div>
          <div className='grid grid-cols-3 items-center justify-center text-right w-full text-sm '>
            <div className=' bg-violet-600 text-white text-center'>Quote&nbsp;/&nbsp;FOB</div>
            <div className=' border border-violet-600'>{
              (isFinite(parseFloat(targetRate || 0) / parseFloat(conversionValue || 0))
                ?
                parseFloat(targetRate || 0) / parseFloat(conversionValue || 0)
                :
                0
              ).toFixed(3)}</div>
            <div className=' border border-violet-600'>
              <input type="text" className='text-right w-full'
                value={targetRate}
                onFocus={(e) => { e.target.select() }}
                onChange={(e) => setTargetRate(e.target.value)}
                onBlur={(e) => setTargetRate(parseFloat(e.target.value).toFixed(3))}
                disabled={readOnly}
              /></div>
          </div>
          <div className='grid grid-cols-3 items-center justify-center text-right w-full text-sm '>
            <div className=' bg-blue-600 text-white text-center'>Margin</div>
            <div className=' border border-blue-600'>{(margin).toFixed(3)}</div>
            <div className=' border border-blue-600'>{parseFloat(margin * conversionValue).toFixed(3)}</div>
          </div>
          <div className='grid grid-cols-3 items-center justify-center text-right w-full text-sm '>
            <div className=' bg-blue-600 text-white text-center'>Margin %</div>
            <div className=' border border-blue-600'>{(marginPercent).toFixed(3)}</div>
            <div className=' border border-blue-600'>{(marginPercent).toFixed(3)}</div>
          </div>
        </div>
      </div>
    </fieldset>
  );
};

export default TargetComparision;
