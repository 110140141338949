import React, { useEffect } from 'react'
import { getCommonParams, getDateFromDateTimeToDisplay } from "../../../Utils/helper";
import { getImageUrlPath } from '../../../Constants';
import { useGetBranchByIdQuery } from '../../../redux/services/BranchMasterService';
import { IMAGE_ICON } from '../../../icons';

const CostingImage = React.forwardRef((props, ref) => {
    const columns = [
        { name: "S.No." },
        { name: "Costing.No.", className: "w-[60px] p-2" },
        {
            name: "Updated.Date", className: "w-[50px] p-2"
        },
        {
            name: "Customer"
        },
        {
            name: "Product Style/Desc",
        },
        { name: "Size Range / Ratio", className: "w-[100px] p-2" },
        { name: "Order Qty", className: "w-[80px] p-2" },
        { name: "Fabric Content", className: "w-[100px] p-2" },
        { name: "Quote" },
        { name: "Incoterms" },
        { name: "Remarks" },
    ]
    const { branchId } = getCommonParams()
    const { data: branchData } = useGetBranchByIdQuery(branchId, { skip: !branchId })
    useEffect(() => {
        props.download()
    }, [props])
    return (
        <div>
            <div className='w-full flex items-center justify-end'>
                <button onClick={props.download} className='w-[150px] h-[30px] flex items-center gap-1' >
                    <div className='page-heading flex items-center p-1 rounded-md text-xs'>
                        <span>Download</span>
                        <span className='w-[30px] h-[30px]'>
                            {IMAGE_ICON}
                        </span>
                    </div>
                </button>
            </div>
            <div ref={ref} className='flex flex-col text-[10px] w-full lg:p-3 h-[400px]'>
                <div className='flex items-center justify-between'>
                    <div className='text-[#388087] font-bold'>
                        Final Quote
                    </div>
                    <div>
                        <div className='text-[10px] text-[#388087] font-bold'>
                            {branchData?.data?.branchName}
                        </div>
                        <div className='w-[400px] '>
                            {branchData?.data?.address}
                        </div>
                    </div>
                </div>
                <div className='w-full'>
                    <table className="w-full text-[8px] lg:text-[10px]">
                        <thead className=" ">
                            <tr className='border-t border-gray-500'>
                                {columns.map((i) =>
                                    <th
                                        key={i.name}
                                        className={`text-center bg-[#388087] text-gray-200 ${i.className} font-medium  border-r border-gray-500
                            `}
                                    >
                                        <div>
                                            {i.name}
                                        </div>
                                    </th>
                                )}
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {(props?.data || []).filter(i => i.isApproved).map((dataObj, index) =>
                                <tr key={dataObj.id}>
                                    <td className=" border-r border-gray-300 text-left ">
                                        <div className=" text-gray-900">
                                            {(index + 1)}
                                        </div>
                                    </td>
                                    <td className="  border-r border-gray-300 text-[8px] whitespace-nowrap">
                                        {dataObj?.docId}
                                    </td>
                                    <td className={`  border-r border-gray-300  `}>
                                        <div className=" text-gray-900">
                                            {getDateFromDateTimeToDisplay(dataObj?.updatedAt)}
                                        </div>
                                    </td>
                                    <td className={` border-r border-gray-300  text-gray-500 `}>
                                        {dataObj?.Party?.aliasName}
                                    </td>
                                    <td className={`border-r border-gray-300  text-gray-500  `}>
                                        <div className="flex items-center flex-col">
                                            {dataObj?.styleImage &&
                                                <img
                                                    className="h-[70px] w-[70px] rounded-full"
                                                    src={getImageUrlPath(dataObj?.styleImage)}
                                                    alt=""
                                                />
                                            }
                                            <div className=" font-medium text-gray-900">
                                                {dataObj?.styleRefNo}
                                            </div>
                                        </div>
                                    </td>
                                    <td className="  border-r border-gray-300   font-medium">
                                        {dataObj?.SizeTemplate?.name}/{dataObj?.Ratio?.name}
                                    </td>
                                    <td className="  border-r border-gray-300   font-medium text-right">
                                        {dataObj?.orderQty}
                                    </td>
                                    <td className=" border-r border-gray-300   font-medium ">
                                        {dataObj?.fabricDescForBuyer}
                                    </td>
                                    <td className="  border-r border-gray-300 text-center font-bold text-lg">
                                        <div className='flex items-center justify-center gap-1'>
                                            <span>{dataObj?.currencyIcon}</span>
                                            <span className='text-center'>
                                                {dataObj?.quoteCost}
                                            </span>
                                        </div>
                                    </td>
                                    <td className="  border-r border-gray-300 text-center">
                                        {dataObj?.incoterms}
                                    </td>
                                    <td className="  border-r border-gray-300   font-medium ">
                                        {dataObj?.costingRemarks}
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <div className='flex-1'>
                </div>
            </div>
        </div>
    )
});


export default CostingImage