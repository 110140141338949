import React, { useState } from 'react'
import { useGetProcessQuery } from '../../../redux/ErpServices/processMasterServices';
import { getCommonParams } from '../../../Utils/helper';
import { CancelButton, DoneButton } from '../../../Buttons';

const ProcessFillGrid = ({ processDetails, setProcessDetails, onClose }) => {
  const [localProcessDetails, setLocalProcessDetails] = useState(processDetails);

  const { companyId } = getCommonParams();
  const { data: processData } = useGetProcessQuery({ params: { companyId } });
  const processList = processData?.data || [];

  const handleDone = () => {
    setProcessDetails(localProcessDetails);
    onClose()
  };

  const handleCancel = () => {
    onClose()
  };
  function addProcess(id) {
   
    setLocalProcessDetails(prev => [...prev, { processId: id }])
  }
  function removeProcess(id) {
    
    setLocalProcessDetails(prev => prev.filter(i => parseInt(i.processId) !== parseInt(id)))
  }


  function isAllSelected() {
    return processList.every(i => isProcessSelected(parseInt(i.id)))
  }
  function selectAll() {
    if (isAllSelected()) {
      processList.forEach(process => {
        removeProcess(process.id)
      });
    } else {
      processList.forEach(process => {
        addProcess(process.id)
      });
    }
  }
  function isProcessSelected(id) {
   
    return localProcessDetails.findIndex(i => parseInt(i.processId) === parseInt(id)) !== -1
  }
  function handleChange(id) {
    if (isProcessSelected(id)) {
      removeProcess(id)
    } else {
      addProcess(id)
    }
  }
  return (
    <div>
      <fieldset className='frame my-1'>
        <legend className='sub-heading'>Process List</legend>
        <div className='grid grid-cols-1 my-2'>
          <table className='w-full h-full '>
            <thead className='thead'>
              <tr className='table-row'>
                <td className='table-data'>
                  <div className='flex flex-col items-center justify-center'>
                    <span>
                      Mark All
                    </span>
                    <span>
                      <input type="checkbox" name="" id="" checked={isAllSelected()} onChange={selectAll} />
                    </span>
                  </div>
                </td>
                <td>Process</td>
              </tr>
            </thead>
            <tbody>
              {processList.map(process =>
                <tr key={process.id} className='table-row'>
                  <td className='flex items-center justify-center table-data'>
                    <input type="checkbox" name="" id="" checked={isProcessSelected(process.id)} onChange={() => { handleChange(process.id) }} />
                  </td>
                  <td>
                    {process.name}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className='flex justify-between'>
          <DoneButton onClick={handleDone} />
          <CancelButton onClick={handleCancel} />
        </div>
      </fieldset>
    </div>
  )
}

export default ProcessFillGrid