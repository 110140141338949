import React, { useEffect } from 'react'
import { useGetPoItemByIdQuery } from '../../../redux/ErpServices/PoServices'
import { Loader } from '../../../Basic/components'
import { DELETE } from '../../../icons'
import { substract } from '../../../Utils/helper'
import { discountTypes } from '../../../Utils/DropdownData';
import { toast } from 'react-toastify'

const AccessoryPoItem = ({ item, index, handleInputChange, readOnly, removeItem, billEntryId }) => {
   
    const { data, isLoading, isFetching } = useGetPoItemByIdQuery({ id: item.poItemsId, billEntryId }, { skip: !item.poItemsId })
    useEffect(() => {
        if (!data?.data) return
        handleInputChange(data.data.price, index, "price");
        handleInputChange(data.data.taxPercent, index, "taxPercent");
    }, [data, isLoading, isFetching])

    if (isLoading || isFetching) return <Loader />
    const poItem = data.data
    let poQty = parseFloat(poItem.qty).toFixed(3)
    let cancelQty = poItem.alreadyCancelData?._sum.qty ? poItem.alreadyCancelData._sum.qty : "0.000";
    let alreadyInwardedQty = poItem.alreadyInwardedData?._sum?.qty ? parseFloat(poItem.alreadyInwardedData._sum.qty).toFixed(3) : "0.000";
    let alreadyReturnedQty = poItem.alreadyReturnedData?._sum?.qty ? parseFloat(poItem.alreadyReturnedData._sum.qty).toFixed(3) : "0.000";
    let alreadyBilledQty = poItem.alreadyBillData?._sum?.qty ? parseFloat(poItem.alreadyBillData._sum.qty).toFixed(3) : "0.000";
    let balanceQty = substract(substract(alreadyInwardedQty, alreadyReturnedQty), alreadyBilledQty).toFixed(3)
    let billAmount = (parseFloat(poItem.price) * parseFloat(item.qty)).toFixed(2)
    let discountAmount = (item.discountType === "Percentage") ? (billAmount) / 100 * parseInt(item.discountValue) : item.discountValue
    let amount = substract(billAmount, discountAmount).toFixed(2)
    return (
        <tr key={item.poItemsId} className='table-row'>
            <td className='text-left   table-data'>{index + 1}</td> 
            <td className='text-left   table-data'>{poItem.Po.docId}</td>
            <td className='text-left   table-data'>{poItem.Accessory.aliasName}</td>
            <td className='text-left   table-data'>{poItem.Accessory.accessoryItem.name}</td>
            <td className='text-left   table-data'>{poItem.Accessory.accessoryItem.AccessoryGroup.name}</td>
            <td className='text-left   table-data'>{poItem.Color.name}</td>
            <td className='text-right   table-data'>{poItem.Size.name}</td>
            <td className='text-left   table-data'>{poItem.Uom.name}</td>
            <td className='text-right  table-data'>{poQty}</td>
            <td className='text-right  table-data'>{alreadyInwardedQty}</td>
            <td className='text-right  table-data'>{alreadyReturnedQty}</td>
            <td className='text-right  table-data'>{cancelQty}</td>
            <td className='text-right  table-data'>{parseFloat(poItem.price).toFixed(2)}</td>
            <td className='text-right  table-data'>{alreadyBilledQty}</td>
            <td className='text-right  table-data'>{balanceQty}</td>
            <td className='table-data text-right'>
                <input
                    onKeyDown={e => {
                        if (e.code === "Minus" || e.code === "NumpadSubtract") e.preventDefault()
                        if (e.altKey) { e.preventDefault() }
                    }}
                    min={"0"}
                    type="number"
                    className="text-right rounded   w-full py-1 table-data-input"
                    autoFocus={index === 0}
                    value={item.qty}
                    disabled={readOnly}
                    onChange={(event) => {
                        if (event.target.value < 0) return
                        if(parseFloat(event.target.value) > parseFloat(balanceQty)){
                            toast.info("Bill Qty  Cannot be more than Balance Qty", {position:"top-center"});
                            return
                        }
                        if (!event.target.value) {
                            handleInputChange(0, index, "qty");
                            return
                        }
                        handleInputChange(event.target.value, index, "qty");
                    }}

                    onBlur={(e) => {
                        if (!e.target.value) {
                            handleInputChange(0.000, index, "qty");
                            return
                        }
                        handleInputChange(parseFloat(e.target.value).toFixed(3), index, "qty")
                    }}
                />
            </td>
            <td className='   table-data text-right'>
                {(poItem.price).toFixed(2)}
            </td>
            <td className='text-right  table-data'>{billAmount}</td>
            <td className='   table-data text-right'>
                <select name='type' disabled={readOnly} className='text-center rounded   w-full py-1 table-data-input'
                    value={item.discountType}
                    onChange={(event) => {
                        if (event.target.value < 0) return
                        if (!event.target.value) {
                            handleInputChange(0, index, "discountType");
                            return
                        }
                        handleInputChange(event.target.value, index, "discountType");
                    }}
                >
                    <option hidden>
                    </option>
                    {discountTypes.map((option, index) => <option key={index} value={option.value} >
                        {option.show}
                    </option>)}
                </select>
            </td>
            <td className='table-data text-right'>
                <input
                    onKeyDown={e => {
                        if (e.code === "Minus" || e.code === "NumpadSubtract") e.preventDefault()
                        if (e.altKey) { e.preventDefault() }
                    }}
                    min={"0"}
                    type="number"
                    className="text-right rounded   w-full py-1 table-data-input"
                    value={item.discountValue}
                    disabled={readOnly}
                    onChange={(event) => {
                        if (event.target.value < 0) return
                        if (!event.target.value) {
                            handleInputChange(0, index, "discountValue");
                            return
                        }
                        handleInputChange(event.target.value, index, "discountValue");
                    }}

                    onBlur={(e) => {
                        if (!e.target.value) {
                            handleInputChange(0.000, index, "discountValue");
                            return
                        }
                        handleInputChange(parseFloat(e.target.value), index, "discountValue")
                    }}
                />
            </td>
            <td className='text-right  table-data'>{amount}</td>
            <td className='text-right  table-data'>{poItem.taxPercent}</td>
            <td className='text-right  table-data'>
                <input
                    type="text"
                    className="text-right rounded   w-full py-1 table-data-input"
                    value={item.notes}
                    disabled={readOnly}
                    onChange={(event) => {
                        handleInputChange(event.target.value, index, "notes", true);
                    }}
                />
            </td>
            {!readOnly &&
                <td className='table-data w-12'>
                    <div tabIndex={-1} onClick={() => removeItem(item.isPoItem ? item.poItemsId : item.directItemsId, item?.isPoItem ? true : false)} className='flex justify-center px-2 py-1.5 items-center cursor-pointer bg-gray-300'>
                        {DELETE}
                    </div>
                </td>
            }
        </tr>
    )
}

export default AccessoryPoItem
