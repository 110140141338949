import React from 'react';
import empty from "../../../assets/empty.png"
import { DeleteButton } from '../../../Buttons';
import { getImageUrlPath } from '../../../Constants';

const BrowseSingleImage = ({ image, setImage, uploadImage, removeImage, readOnly }) => {
    const imageFormatter = () => {
        if (image) {
            if (image instanceof File) {
                return URL.createObjectURL(image)
            } else {
                return getImageUrlPath(image)
            }
        }
        return null
    }
    const imageWidth = "150px"
    const imageHeight = "150px";
    return (
        <div className='flex gap-1 flex-col items-center border border-gray-500 p-1'>
            <input autoFocus id='paste' onPaste={(e) => {
                e.persist()
                setImage(e.clipboardData.files[0])
            }} placeholder='Paste Image Here...!' readOnly />
            <label htmlFor='paste' >
                {Boolean(image) ?
                    <img style={{ height: imageHeight, width: imageWidth, objectFit: 'cover' }}
                        src={imageFormatter()}
                        alt=''
                    />
                    :
                    <img src={empty} className='w-36' alt='' />
                }
            </label>
            <div className='flex flex-col gap-2'>
                <div className='flex justify-center gap-2 my-3'>
                    {!readOnly &&
                        <>
                            <div className='flex items-center border border-gray-700 hover:border-lime-500 rounded-md h-8 px-1'>
                                <input type="file" id="profileImage" className='hidden' onChange={(e) => {
                                    setImage(e.target.files[0])
                                }} />

                                <label htmlFor="profileImage" className="text-xs w-full"> Browse</label>
                            </div>
                            <div className='border border-gray-700 rounded-md h-8 px-2 text-xs hover:border-red-400'>
                                {<DeleteButton onClick={removeImage} />}
                            </div>
                        </>
                    }
                </div>
                {uploadImage && (image instanceof File) &&
                    <button className='bg-green-500 text-white rounded-sm p-1 ' onClick={uploadImage}>Upload</button>
                }
            </div >
        </div>
    );
};

export default BrowseSingleImage;