import React, { useEffect, useState, useRef, useCallback } from "react";
import secureLocalStorage from "react-secure-storage";
import {
    useGetProcessQuery,
    useGetProcessByIdQuery,
    useAddProcessMutation,
    useUpdateProcessMutation,
    useDeleteProcessMutation,
} from "../../../redux/ErpServices/processMasterServices";
import FormHeader from "../../../Basic/components/FormHeader";
import FormReport from "../../../Basic/components/FormReportTemplate";
import { toast } from "react-toastify";
import { TextInput, CheckBox } from "../../../Inputs";
import ReportTemplate from '../../../Basic/components/ReportTemplate'

const MODEL = "Process Master";

export default function Form() {
    const [form, setForm] = useState(false);

    const [readOnly, setReadOnly] = useState(false);
    const [id, setId] = useState("");
    const [name, setName] = useState("");
    const [io, setIo] = useState("");
    const [active, setActive] = useState(true);

    const [isYarn, setIsYarn] = useState(false);
    const [isFabric, setIsFabric] = useState(false);
    const [isTrims, setIsTrims] = useState(false);
    const [isCmt, setIsCmt] = useState(false);
    const [isCommercial, setIsCommercial] = useState(false);
    const [isPrintingAndEmb, setIsPrintingAndEmb] = useState(false);
    const [isTransportation, setIsTransportation] = useState(false);
    const [isOversHeads, setIsOversHeads] = useState(false);
    const [isFinance, setIsFinance] = useState(false);
    const [isProcessLoss, setIsProcessLoss] = useState(false);
    const [isFinish, setIsFinish] = useState(false);
    const [isSpecialFinish, setIsSpecialFinish] = useState(false);
    const [isPurchase, setIsPurchase] = useState(false);
    const [isDefault, setIsDefault] = useState(false);
    const [isKnitting, setIsKnitting] = useState(false);
    const [isDyeing, setIsDyeing] = useState(false);
    const [rate, setRate] = useState(0);
    const [loss, setLoss] = useState(0);


    const [isCutting, setIsCutting] = useState(false);
    const [isPacking, setIsPacking] = useState(false);
    const [isPcsStage, setIsPcsStage] = useState(false);
    const [isPrintingJobWork, setIsPrintingJobWork] = useState(false);
    const [code, setCode] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const childRecord = useRef(0);

    const params = {
        companyId: secureLocalStorage.getItem(
            sessionStorage.getItem("sessionId") + "userCompanyId"
        ),
    };
    const { data: allData, isLoading, isFetching } = useGetProcessQuery({ params, searchParams: searchValue });
    const {
        data: singleData,
        isFetching: isSingleFetching,
        isLoading: isSingleLoading,
    } = useGetProcessByIdQuery(id, { skip: !id });


    const [addData] = useAddProcessMutation();
    const [updateData] = useUpdateProcessMutation();
    const [removeData] = useDeleteProcessMutation();

    const syncFormWithDb = useCallback(
        (data) => {
            if (id) {
                setReadOnly(true);
            } else {
                setReadOnly(false);
            }
            setName(data?.name ? data.name : "");
            setCode(data?.code ? data.code : "");
            setIo(data?.io ? data.io : "");
            setIsYarn(data?.isYarn || false);
            setIsFabric(data?.isFabric || false);
            setIsTrims(data?.isTrims || false);
            setIsCmt(data?.isCmt || false);
            setIsCommercial(data?.isCommercial || false);
            setIsPrintingAndEmb(data?.isPrintingAndEmb || false);
            setIsTransportation(data?.isTransportation || false);
            setIsProcessLoss(data?.isProcessLoss || false);
            setActive(id ? (data?.active ? data.active : false) : true);
            setIsOversHeads(data?.isOversHeads || false);
            setIsFinance(data?.isFinance || false);
            setIsFinish(data?.isFinish || false);
            setIsPurchase(data?.isPurchase || false);
            setIsSpecialFinish(data?.isSpecialFinish || false);
            setIsDefault(data?.isDefault || false);
            setIsKnitting(data?.isKnitting || false);
            setIsDyeing(data?.isDyeing || false);
            setRate(data?.rate || 0);
            setLoss(data?.loss || 0);
        },
        [id]
    );

    useEffect(() => {
        syncFormWithDb(singleData?.data);
    }, [isSingleFetching, isSingleLoading, id, syncFormWithDb, singleData]);

    const data = {
        id, name, io, code, active, isCutting, isPacking, isPcsStage, isPrintingJobWork, isYarn, isFabric, isTrims, isCmt, isCommercial,
        isPrintingAndEmb, isOversHeads, isFinance, isTransportation, isProcessLoss, isFinish, isSpecialFinish, isPurchase, isDefault, isKnitting, isDyeing,
        companyId: secureLocalStorage.getItem(sessionStorage.getItem("sessionId") + "userCompanyId"), rate, loss
    }

    const validateData = (data) => {
        return data.name && data.code
    }

    const handleSubmitCustom = async (callback, data, text) => {
        try {
            let returnData = await callback(data).unwrap();
            setId(returnData.data.id)
            toast.success(text + "Successfully");
        } catch (error) {
            console.log("handle");
        }
    };

    const saveData = () => {
        if (!validateData(data)) {
            toast.info("Please fill all required fields...!", {
                position: "top-center",
            });
            return;
        }
        if (!window.confirm("Are you sure save the details ...?")) {
            return;
        }
        if (id) {
            handleSubmitCustom(updateData, data, "Updated");
        } else {
            handleSubmitCustom(addData, data, "Added");
        }
    };

    const deleteData = async () => {
        if (id) {
            if (!window.confirm("Are you sure to delete...?")) {
                return;
            }
            try {
                await removeData(id)
                setId("");
                toast.success("Deleted Successfully");
            } catch (error) {
                toast.error("something went wrong");
            }
        }
    };

    const handleKeyDown = (event) => {
        let charCode = String.fromCharCode(event.which).toLowerCase();
        if ((event.ctrlKey || event.metaKey) && charCode === "s") {
            event.preventDefault();
            saveData();
        }
    };

    const onNew = () => {
        setId("");
        setReadOnly(false);
        setForm(true);
        syncFormWithDb(undefined)
        setSearchValue("");
    };

    function onDataClick(id) {
        setId(id);
        setForm(true);
    }

    const tableHeaders = [
        "Name", "Status"
    ]
    const tableDataNames = ["dataObj.name", 'dataObj.active ? ACTIVE : INACTIVE']

    if (!form)
        return (
            <ReportTemplate
                heading={MODEL}
                tableHeaders={tableHeaders}
                tableDataNames={tableDataNames}
                loading={
                    isLoading || isFetching
                }
                setForm={setForm}
                data={allData?.data}
                onClick={onDataClick}
                onNew={onNew}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
            />
        );

    return (
        <div
            onKeyDown={handleKeyDown}
            className="md:items-start md:justify-items-center grid h-full bg-theme"
        >
            <div className="flex flex-col frame w-full h-full">
                <FormHeader
                    onNew={onNew}
                    onClose={() => {
                        setForm(false);
                        setSearchValue("");
                    }}
                    model={MODEL}
                    saveData={saveData}
                    setReadOnly={setReadOnly}
                    deleteData={deleteData}

                />
                <div className="flex-1 grid grid-cols-1 md:grid-cols-4 gap-x-2 overflow-clip">
                    <div className="col-span-3 grid md:grid-cols-2 border overflow-auto">
                        <div className='col-span-3 grid md:grid-cols-2 border overflow-auto'>
                            <div className='mr-1 md:ml-2'>
                                <fieldset className='frame my-1'>
                                    <legend className='sub-heading'>Process Info</legend>
                                    <div className='grid grid-cols-1 my-2'>
                                        <TextInput name="Process name" type="text" value={name} setValue={setName} required={true} readOnly={readOnly} disabled={(childRecord.current > 0)} />
                                        <TextInput name="Code" type="text" value={code} setValue={setCode} required={true} readOnly={readOnly} disabled={(childRecord.current > 0)} />
                                        <TextInput name={"Price"} type={'number'} value={rate} setValue={setRate} readOnly={readOnly} />
                                        <TextInput name={"Loss"} type={'number'} value={loss} setValue={setLoss} readOnly={readOnly} />
                                        {/* <DropdownInput name="IO" options={ProcessIOOptions} value={io} setValue={setIo} required={true} readOnly={readOnly} disabled={(childRecord.current > 0)} /> */}
                                        {/* <CheckBox name="PcsStage" readOnly={readOnly} value={isPcsStage} setValue={setIsPcsStage} />
                                        <CheckBox name="Cutting" readOnly={readOnly} value={isCutting} setValue={setIsCutting} />
                                        <CheckBox name="Packing" readOnly={readOnly} value={isPacking} setValue={setIsPacking} />
                                        <CheckBox name="Printing Job Work" readOnly={readOnly} value={isPrintingJobWork} setValue={setIsPrintingJobWork} /> */}
                                        <CheckBox name="Purchase" readOnly={readOnly} value={isPurchase} setValue={setIsPurchase} />
                                        <CheckBox name="Yarn" readOnly={readOnly} value={isYarn} setValue={setIsYarn} />
                                        <CheckBox name="Fabric" readOnly={readOnly} value={isFabric} setValue={setIsFabric} />
                                        <CheckBox name="Trims" readOnly={readOnly} value={isTrims} setValue={setIsTrims} />
                                        <CheckBox name="CMT" readOnly={readOnly} value={isCmt} setValue={setIsCmt} />
                                        <CheckBox name="Commercial" readOnly={readOnly} value={isCommercial} setValue={setIsCommercial} />
                                        <CheckBox name="Printing And Ewb" readOnly={readOnly} value={isPrintingAndEmb} setValue={setIsPrintingAndEmb} />
                                        {/* <CheckBox name="Transportation" readOnly={readOnly} value={isTransportation} setValue={setIsTransportation} /> */}
                                        <CheckBox name="OverHeads" readOnly={readOnly} value={isOversHeads} setValue={setIsOversHeads} />
                                        <CheckBox name="Finance" readOnly={readOnly} value={isFinance} setValue={setIsFinance} />
                                        <CheckBox name="Finish" readOnly={readOnly} value={isFinish} setValue={setIsFinish} />
                                        <CheckBox name="SpecialFinish" readOnly={readOnly} value={isSpecialFinish} setValue={setIsSpecialFinish} />
                                        <CheckBox name="Knitting" readOnly={readOnly} value={isKnitting} setValue={setIsKnitting} />
                                        <CheckBox name="Dyeing" readOnly={readOnly} value={isDyeing} setValue={setIsDyeing} />
                                        <CheckBox name="Default" readOnly={readOnly} value={isDefault} setValue={setIsDefault} />
                                        {/* <CheckBox name="Process Loss" readOnly={readOnly} value={isProcessLoss} setValue={setIsProcessLoss} /> */}
                                        <CheckBox name="Active" readOnly={readOnly} value={active} setValue={setActive} />
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                    <div className="frame hidden md:block overflow-x-hidden">
                        <FormReport
                            searchValue={searchValue}
                            setSearchValue={setSearchValue}
                            setId={setId}
                            tableHeaders={tableHeaders}
                            tableDataNames={tableDataNames}
                            data={allData?.data}
                            loading={
                                isLoading || isFetching
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
