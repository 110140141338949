import React from 'react'
import { DELETE, VIEW } from '../../../icons'
import { useState } from 'react'
import Modal from '../../../UiComponents/Modal';
import { TextInput } from '../../../Inputs';
import { toast } from 'react-toastify';

const AttachmentsListForCreate = ({ attachments, setAttachments }) => {
    const [name, setName] = useState(null)
    const [newFile, setNewFile] = useState(null)
    const [previewFileName, setPreviewName] = useState(null);
    return (
        <>
            <Modal isOpen={previewFileName} onClose={() => setPreviewName(false)} widthClass={"w-[80%] h-[80%]"}>
                <iframe src={(previewFileName instanceof File) ? URL.createObjectURL(previewFileName) : ''} title={previewFileName?.name} width="100%" height="500px" />
            </Modal>
            <fieldset className='frame rounded-tr-lg rounded-bl-lg w-full border border-gray-600 p-1 min-w-[500px]'>
                <legend className='sub-heading'>Attachments</legend>
                <div className='text-2xl text-center bg-[#388087] text-white'>Attachments</div>
                <div className='grid grid-cols-2 my-3'>
                    <TextInput name={"Name"} value={name} setValue={setName} />
                    <input autoFocus id='pastedata' onPaste={(e) => {
                        e.persist()
                        setNewFile(e.clipboardData.files[0])
                        if (e.clipboardData.files?.[0]?.name) {
                            setName(e.clipboardData.files?.[0]?.name)
                        }
                    }}
                        readOnly
                        placeholder='Paste File Here...!' />
                    <input type="text" value={newFile instanceof File ? newFile.name : newFile} />
                    <input type="file" id="selectedFile" className='hidden' onChange={(e) => {
                        setNewFile(e.target.files[0]);
                        if (e.target.files?.[0].name) {
                            setName(e.target.files?.[0].name);
                        }
                    }} />
                    <input type="button" value="Browse..." onClick={() => { document.getElementById("selectedFile").click() }} />
                </div>
                <table className='w-full text-xs'>
                    <thead>
                        <tr className='h-[40px]'>
                            <th className="border border-gray-500 text-xs w-20">
                                File
                            </th>
                            <th className="border border-gray-500 text-xs w-10">
                                View
                            </th>
                            <th className="border border-gray-500 text-xs w-10 bg-green-700 text-white" onClick={() => {
                                if (!name) return toast.info("Name is Empty...!");
                                if (!(newFile instanceof File)) return toast.info("Add a File...!");
                                setAttachments(prev => [...prev, { name, filePath: newFile }])
                                setNewFile(null); setName("");
                            }
                            } >
                                ADD
                            </th>
                        </tr>
                    </thead>
                    <tbody >
                        {attachments.map((row, index) => (
                            <tr key={index} className='h-[30px]'>
                                <td className="border border-gray-500 text-xs pl-1 text-center">
                                    {row.name}
                                </td>
                                <td className="border border-gray-500 text-xs text-center" onClick={() => setPreviewName(row.filePath)}>
                                    {VIEW}
                                </td>
                                <td className="border border-gray-500 text-xs text-center" onClick={() =>
                                    setAttachments(prev => prev.filter((_, i) => i !== index))}>
                                    {DELETE}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </fieldset>
        </>

    )
}

export default AttachmentsListForCreate
