import React from 'react'
import { useGetPoItemByIdQuery } from '../../../redux/ErpServices/PoServices'
import { Loader } from '../../../Basic/components'
import { DELETE } from '../../../icons'
import { substract } from '../../../Utils/helper'
import { toast } from 'react-toastify'

const AccessoryPoItem = ({ poItemId, index, handleInputChange, readOnly, qty, removeItem, purchaseInwardId, stockId }) => {
    const { data, isLoading, isFetching } = useGetPoItemByIdQuery({ id: poItemId, purchaseInwardId, stockId }, { skip: !poItemId })


    const allowedReturnQty = (alreadyInwardQty, alreadyReturnQty, stockQty) => {
        const allowedReturn = substract(alreadyInwardQty, alreadyReturnQty)
        return Math.min(stockQty, allowedReturn)
    }

    if (isLoading || isFetching) return <Loader />
    const poItem = data.data

    let stockQty = poItem.stockData?._sum?.qty ? poItem.stockData?._sum?.qty : 0
    let poQty = parseFloat(poItem.qty).toFixed(3)
    let cancelQty = poItem.alreadyCancelData?._sum.qty ? poItem.alreadyCancelData._sum.qty : "0.000";
    let alreadyInwardedQty = poItem.alreadyInwardedData?._sum?.qty ? parseFloat(poItem.alreadyInwardedData._sum.qty).toFixed(3) : "0.000";
    let alreadyReturnedQty = poItem.alreadyReturnedData?._sum?.qty ? parseFloat(poItem.alreadyReturnedData._sum.qty).toFixed(3) : "0.000";
    let balanceQty = substract(substract(poQty, cancelQty), substract(alreadyInwardedQty, alreadyReturnedQty)).toFixed(3)
    return (
        <tr key={poItemId} className='table-row'>
            <td className='text-left px-1  table-data'>{index + 1}</td>
            <td className='text-left px-1  table-data'>{poItem.Po.docId}</td>
            <td className='text-left px-1  table-data'>{poItem.Accessory.aliasName}</td>
            <td className='text-left px-1  table-data'>{poItem.Accessory.accessoryItem.name}</td>
            <td className='text-left px-1  table-data'>{poItem.Accessory.accessoryItem.AccessoryGroup.name}</td>
            <td className='text-left px-1  table-data'>{poItem.Color.name}</td>
            <td className='text-right px-1  table-data'>{poItem.Size.name}</td>
            <td className='text-left px-1  table-data'>{poItem.Uom.name}</td>
            <td className='text-right px-1 table-data'>{stockQty}</td>
            <td className='text-right px-1 w-24 table-data'>{alreadyInwardedQty}</td>
            <td className='text-right px-1 w-24 table-data'>{alreadyReturnedQty}</td>
            <td className='text-right px-1 w-20 table-data'>{allowedReturnQty(alreadyInwardedQty, alreadyReturnedQty, stockQty)}</td>
            <td className=' px-1  table-data text-right'>
                <input
                    type="number"
                    className="text-right rounded  px-1 w-full py-1 table-data-input"
                    value={qty}
                    disabled={readOnly}
                    onChange={(event) => {
                        if (!event.target.value) {
                            handleInputChange(0, index, "qty");
                            return
                        }
                        if(event.target.value > allowedReturnQty(alreadyInwardedQty, alreadyReturnedQty, stockQty)){
                            toast.info("Return Qty Cannot be More than Allowed Qty",{position:"top-center"})
                            return
                        }
                        handleInputChange(event.target.value, index, "qty");
                    }}
                    onBlur={(e) => {
                        if (!e.target.value) {
                            handleInputChange(0.000, index, "qty");
                            return
                        }
                        handleInputChange(parseFloat(e.target.value).toFixed(3), index, "qty")
                    }}
                />
            </td>
            <td className='text-right px-1 w-12 table-data'>{parseFloat(poItem.price).toFixed(2)}</td>
            <td className='text-right px-1 w-12 table-data'>{parseFloat(poItem.price * qty).toFixed(2)}</td>
            {!readOnly &&
                <td className='table-data w-12'>
                    <div tabIndex={-1} onClick={() => removeItem(poItemId)} className='flex justify-center px-2 py-1.5 items-center cursor-pointer bg-gray-300'>
                        {DELETE}
                    </div>
                </td>
            }
        </tr>
    )
}

export default AccessoryPoItem
