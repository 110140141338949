import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faClose, faUserPlus, faEdit, faTrashCan, faPlusCircle, faRefresh, faPrint, faSearch } from "@fortawesome/free-solid-svg-icons";
import { useState } from 'react';
import excelIcon from "../assets/icons8-microsoft-excel-48.png"
import { REFRESH_ICON } from '../icons';

export const RefreshButton = ({ onClick, disabled = false }) => {

    return (
        <button type='button' className='text-gray-200 py-2 px-4 rounded focus:outline-none focus:shadow-outline' onClick={() => onClick()} disabled={disabled}>
            {REFRESH_ICON} Refresh
        </button>
    )
}

export const AddNewButton = ({ onClick, disabled = false }) => {

    return (
        <button type='button' className='text-green-600 py-2 px-4 rounded focus:outline-none focus:shadow-outline' onClick={() => onClick()} disabled={disabled}>
            {<FontAwesomeIcon icon={faUserPlus} />} Add New
        </button>
    )
}

export const New = ({ name, setFormHidden }) => {
    return (
        <button type='button' className='text-green-600 py-2 px-4 rounded focus:outline-none focus:shadow-outline' onClick={() => { setFormHidden(false); }}>
            {<FontAwesomeIcon icon={faPlusCircle} />} Add {name}
        </button>
    )
}

export const GenerateButton = ({ onClick, hidden, name = "Generate" }) => {
    return (
        <button type='button' className='text-green-600 py-2 px-4 rounded focus:outline-none focus:shadow-outline' onClick={() => { onClick(); }} hidden={hidden}>
            {<FontAwesomeIcon icon={faRefresh} />} {name}
        </button>
    )
}

export const Delete = ({ onClick }) => {
    return (
        <button type='button' className='text-red-500 py-2 px-4 rounded focus:outline-none focus:shadow-outline' onClick={() => onClick()}>
            {<FontAwesomeIcon icon={faTrashCan} />}
        </button>
    )
}

export const NewButton = ({ onClick }) => {
    return (
        <button type='button' className='text-green-600 py-2 px-4 rounded focus:outline-none focus:shadow-outline' onClick={() => { onClick(); }}>
            {<FontAwesomeIcon icon={faUserPlus} />} New
        </button>
    )
}

export const EditButton = ({ onClick }) => {
    return (
        <button type='button' className='text-yellow-500 py-2 px-4 rounded focus:outline-none focus:shadow-outline' onClick={() => onClick()}>
            {<FontAwesomeIcon icon={faEdit} />} Edit
        </button>
    )
}

export const EditButtonOnly = ({ onClick }) => {
    return (
        <button type='button' className='text-yellow-500 px-2 rounded focus:outline-none focus:shadow-outline' onClick={() => onClick()}>
            {<FontAwesomeIcon icon={faEdit} />}
        </button>
    )
}

export const SaveButton = ({ onClick, saveButtonType }) => {
    const [isDisabled, setIsDisabled] = useState(false);

    const disableButton = () => {
        setIsDisabled(true);
        setTimeout(() => {
            setIsDisabled(false);
        }, 5000);
    };
    return (
        <button type={saveButtonType} disabled={isDisabled}
            className='text-blue-200 py-2 px-4 rounded focus:outline-none focus:shadow-outline'
            onClick={(saveButtonType === "submit") ? undefined : () => { onClick(); disableButton(); }}>
            {<FontAwesomeIcon icon={faSave} />} Save
        </button>
    )
}

export const CloseButton = ({ onClick }) => {
    return (
        <button type='button' className='text-orange-500 py-2 px-4 rounded focus:outline-none focus:shadow-outline' onClick={() => onClick()}>
            {<FontAwesomeIcon icon={faClose} />} Close
        </button>
    )
}

export const DeleteButton = ({ onClick }) => {
    return (
        <button type='button' className='text-red-500 py-2 px-4 rounded focus:outline-none focus:shadow-outline' onClick={() => onClick()}>
            {<FontAwesomeIcon icon={faTrashCan} />} Delete
        </button>
    )
}



export const CloseButtonOnly = ({ onClick }) => {
    return (
        <button type='button' className='text-black py-2 px-4 rounded focus:outline-none focus:shadow-outline' onClick={() => onClick()}>
            {<FontAwesomeIcon icon={faClose} />}
        </button>
    )
}

export const PrintButtonOnly = ({ onClick }) => {
    return (
        <button type='button' className='text-pink-500 py-2 px-4 rounded focus:outline-none focus:shadow-outline' onClick={() => onClick()}>
            {<FontAwesomeIcon icon={faPrint} />} Print
        </button>
    )
}

export const SearchButton = ({ onClick }) => {
    return (
        <button type='button' className='text-gray-300 py-2 px-4 rounded focus:outline-none focus:shadow-outline' onClick={() => onClick()}>
            {<FontAwesomeIcon icon={faSearch} />} Search
        </button>
    )
}

export const ExcelButton = ({ onClick, width = 18, height = 18 }) => {
    return (
        <button type='button' className='rounded focus:outline-none focus:shadow-outline' onClick={(e) => onClick(e)}>
            <img src={excelIcon} width={width} height={height} alt='' />
        </button>
    )
}
export const DoneButton = ({ onClick, width = 18, height = 18 }) => {
    return (
        <button type='button' className='bg-blue-500 text-white p-1 rounded' onClick={onClick}>
            Done
        </button>
    )
}
export const CancelButton = ({ onClick, width = 18, height = 18 }) => {
    return (
        <button type='button' className='bg-red-500 text-white p-1 rounded' onClick={onClick}>
            Cancel
        </button>
    )
}

export const CloneButton = ({ onClick }) => {
    return (
        <button type='button' className='text-pink-500 py-2 px-4 rounded focus:outline-none focus:shadow-outline' onClick={() => onClick()}>
            {<FontAwesomeIcon icon={faSave} />} Clone
        </button>
    )
}



export const Dropdown = ({ Types, selectedType, setSelectedType }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const select = (selectedValue) => {
        setSelectedType(selectedValue.name);
        setIsOpen(false);
    };

    return (
        <div className="relative">
            <button

                id="dropdownInformationButton"
                className="text-white bg-[#388087] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-[#388087] dark:focus:ring-blue-800"
                type="button"
                onClick={toggleDropdown}
            >
                {selectedType || "Type"}
                <svg
                    className="w-2.5 h-2.5 ms-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 6"
                >
                    <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m1 1 4 4 4-4"
                    />
                </svg>
            </button>

            {isOpen && (
                <div className="z-10 absolute top-full left-0 mt-1 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600">
                    {Types.map((item) => (
                        <div key={item.id}>
                            <div className="px-4 py-1 text-sm text-gray-900 dark:text-white">

                                <div
                                    className="block px-3 py-1 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer"
                                    onClick={() => select(item)}
                                >
                                    {item.name}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};



