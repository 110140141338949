import React, { useMemo } from 'react'
import { useEffect, useState } from "react";
import { getCommonParams, getDateFromDateTimeToDisplay } from "../../../Utils/helper";
import { showEntries } from '../../../Utils/DropdownData';
import secureLocalStorage from 'react-secure-storage';
import { pageNumberToReactPaginateIndex, reactPaginateIndexToPageNumber } from '../../../Utils/helper';
import ReactPaginate from 'react-paginate';
import { useGetCostingQuery } from '../../../redux/ErpServices/CostingServices';
import { getImageUrlPath } from '../../../Constants';

const FinalQuote = () => {
  const branchId = secureLocalStorage.getItem(
    sessionStorage.getItem("sessionId") + "currentBranchId"
  )
  const [searchDocId, setSearchDocId] = useState("");
  const [searchDocDate, setSearchDocDate] = useState("");
  const [searchPartyName, setSearchPartyName] = useState("");
  const [searchStyleRefNo, setSearchStyleRefNo] = useState("")
  const [searchUpdatedDate, setSearchUpdatedDate] = useState('');

  const [dataPerPage, setDataPerPage] = useState("10");
  const [totalCount, setTotalCount] = useState(0);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const handleOnclick = (e) => {
    setCurrentPageNumber(reactPaginateIndexToPageNumber(e.selected));
  }
  const searchFields = useMemo(() => ({ searchDocId, searchDocDate, searchPartyName, searchStyleRefNo, searchUpdatedDate }), [searchDocId, searchDocDate, searchPartyName, searchStyleRefNo, searchUpdatedDate])

  const { finYearId, userId } = getCommonParams()

  const { data: allData, isLoading, isFetching } = useGetCostingQuery({ params: { branchId, userId, ...searchFields, pagination: true, dataPerPage, pageNumber: currentPageNumber, finYearId, approvalReport: true, isApproved: true } });

  useEffect(() => {
    setCurrentPageNumber(1)
  }, [searchFields])

  useEffect(() => {
    if (allData?.totalCount) {
      setTotalCount(allData?.totalCount)
    }
  }, [allData, isLoading, isFetching])
  const columns = [
    { name: "S.No." },
    { name: "Doc No.", search: true, searchValue: searchDocId, setSearchValue: setSearchDocId },
    {
      name: "Updated.Date", search: true, searchValue: searchUpdatedDate, setSearchValue: setSearchUpdatedDate,
    },
    {
      name: "Customer", search: true, searchValue: searchPartyName, setSearchValue: setSearchPartyName,
    },
    {
      name: "Product Style/Desc", search: true, searchValue: searchStyleRefNo, setSearchValue: setSearchStyleRefNo,
    },
    { name: "Size Range / Ratio" },
    { name: "Order Qty" },
    { name: "Fabric Desc." },
    { name: "Quote" },
    { name: "Incoterms" },
    { name: "Remarks" },
  ]
  return (
    <div className='flex flex-col min-h-[90%]'>
      <div className="md:flex md:items-center md:justify-between page-heading p-1">
        <div className="heading text-center md:mx-10">Final Quote</div>
        <div className=" sub-heading justify-center md:justify-start items-center">
          <label className="text-white text-xs rounded-md m-1  border-none">Show Entries</label>
          <select value={dataPerPage}
            onChange={(e) => setDataPerPage(e.target.value)} className='h-6 w-40 border border-gray-500 rounded mr-9'>
            {showEntries.map((option) => <option value={option.value} >{option.show}</option>)}
          </select>
        </div>
      </div>
      <div className='w-full'>
        <table className="">
          <thead className=" ">
            <tr className='border-t border-gray-500'>
              {columns.map((i) =>
                <th
                  key={i.name}
                  scope="col"
                  className={`px-1 text-center bg-[#388087] text-gray-200 text-xs font-medium  tracking-wider border-r border-gray-500
                    `}
                >
                  <div>
                    {i.name}
                  </div>
                  {i.search &&
                    <input
                      type="text"
                      className="text-black h-6 mt-1 focus:outline-none md:ml-3 bg-gray-200 border-r border-gray-400 rounded-lg"
                      placeholder="Search"
                      value={i.searchValue}
                      onChange={(e) => {
                        i.setSearchValue(e.target.value);
                      }}
                    />
                  }
                </th>
              )}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {(allData?.data || []).map((dataObj, index) =>
              <tr key={dataObj.id}>
                <td className="px-6 py-4 whitespace-nowrap border-r border-gray-300 text-left ">
                  <div className="text-xs text-gray-900">
                    {(index + 1) + (dataPerPage * (currentPageNumber - 1))}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap border-r border-gray-300  left-10 stick-bg">
                  <div className="flex items-center">
                    <div className="ml-4">
                      <div className="text-xs font-medium text-gray-900">{dataObj.docId}</div>
                    </div>
                  </div>
                </td>
                {/* <td className={`px-6 py-4 whitespace-nowrap border-r border-gray-300  ${columns[2]?.['className']} stick-bg`}>
                  <div className="text-xs text-gray-900">
                    {getDateFromDateTimeToDisplay(dataObj.createdAt)}
                  </div>
                </td> */}
                <td className={`px-6 py-4 whitespace-nowrap border-r border-gray-300 ${columns[2]?.['className']} stick-bg`}>
                  <div className="text-xs text-gray-900">
                    {getDateFromDateTimeToDisplay(dataObj.updatedAt)}
                  </div>
                </td>
                <td className={`px-6 py-4 border-r border-gray-300 text-xs text-gray-500 ${columns[3]?.['className']} stick-bg`}>
                  {dataObj?.Party?.aliasName}
                </td>
                <td className={`px-6 py-4 whitespace-nowrap border-r border-gray-300 text-xs text-gray-500 ${columns[4]?.['className']} stick-bg`}>
                  <div className="flex items-center">
                    <div className="flex-shrink-0 h-10 w-10">
                      <img
                        className="h-10 w-10 rounded-full"
                        src={getImageUrlPath(dataObj.styleImage)}
                        alt=""
                      />
                    </div>
                    <div className="ml-4">
                      <div className="text-xs font-medium text-gray-900">
                        {dataObj?.styleRefNo}
                      </div>
                    </div>
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap border-r border-gray-300  text-xs font-medium">
                  {dataObj?.SizeTemplate?.name}/{dataObj?.Ratio?.name}
                </td>
                <td className="px-6 py-4 whitespace-nowrap border-r border-gray-300  text-xs font-medium text-right">
                  {dataObj?.orderQty}
                </td>
                <td className="px-6 py-4 border-r border-gray-300  text-xs font-medium">
                  {dataObj?.fabricDesc}
                </td>
                <td className="px-6 py-4 whitespace-nowrap border-r border-gray-300  text-xl text-right font-bold">
                  ₹ {dataObj?.quoteCost}
                </td>
                <td className="px-6 py-4 whitespace-nowrap border-r border-gray-300  text-xs font-bold">
                  {dataObj?.incoterms}
                </td>
                <td className="px-6 py-4 whitespace-nowrap border-r border-gray-300  text-xs font-medium ">
                  {dataObj?.remarks}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className='flex-1'>
      </div>
      <ReactPaginate
        previousLabel={"<"}
        nextLabel={">"}
        breakLabel={"..."}
        breakClassName={"break-me"}
        forcePage={pageNumberToReactPaginateIndex(currentPageNumber)}
        pageCount={Math.ceil(totalCount / dataPerPage)}
        marginPagesDisplayed={1}
        onPageChange={handleOnclick}
        containerClassName={"flex justify-center m-2 gap-5 items-center"}
        pageClassName={"border custom-circle text-center"}
        disabledClassName={"p-1 bg-gray-200"}
        previousLinkClassName={"border p-1 text-center"}
        nextLinkClassName={"border p-1"}
        activeClassName={"bg-[#388087] text-white px-2"} />
    </div>
  )
}

export default FinalQuote