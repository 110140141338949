import React, { Fragment } from "react";
import { useEffect, useState } from "react";
import {
    useGetPartyQuery,
} from "../../../redux/ErpServices/PartyMasterServices";

import { useGetStyleMasterQuery } from "../../../redux/ErpServices/StyleMasterServices";
import { Loader } from "../../../Basic/components";
import { getCommonParams, getDateFromDateTime } from "../../../Utils/helper";

import { pageNumberToReactPaginateIndex, reactPaginateIndexToPageNumber } from '../../../Utils/helper';
import ReactPaginate from 'react-paginate';
import { useGetCuttingOrderQuery } from '../../../redux/ErpServices/CuttingOrderServices'
import CuttingOrderDetails from "./CuttingOrderDetails"

import { showEntries } from "../../../Utils/DropdownData";
import { VIEW } from "../../../icons";
import Modal from "../../../UiComponents/Modal";
import CuttingDelivery from "./CuttingDelivery";
import Parameter from "./Parameter";
import parameterIcon from "../../../assets/icons8-filter.gif"

const CuttingRegister = () => {
    const [currentSelectedIndex, setCurrentSelectedIndex] = useState("")
    const [parameter, setParameter] = useState(false);
    const [filterParties, setFilterParties] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("")

    const { branchId, companyId, finYearId } = getCommonParams()
    const params = {
        branchId,
        companyId,
        finYearId
    };
    const { data: supplierList } = useGetPartyQuery({ params: { ...params } });
    const {
        data: styleList,
    } = useGetStyleMasterQuery({ params });

    const [searchDocId, setSearchDocId] = useState("");
    const [searchDocDate, setSearchDocDate] = useState("");
    const [searchStyle, setSearchStyle] = useState("");
    const [supplier, setSupplier] = useState("");

    const [dataPerPage, setDataPerPage] = useState("10");
    const [totalCount, setTotalCount] = useState(0);
    const [currentPageNumber, setCurrentPageNumber] = useState(1);

    const [deliveryDetailsPopupIndex, setDeliveryDetailsPopupIndex] = useState("")


    const handleOnclick = (e) => {
        setCurrentPageNumber(reactPaginateIndexToPageNumber(e.selected));
    }
    const searchFields = { searchDocId, searchDocDate, searchSupplierAliasName: supplier, searchStyle }
    const { data: allData, isLoading, isFetching } = useGetCuttingOrderQuery({ params: { branchId, ...searchFields, pagination: true, dataPerPage, pageNumber: currentPageNumber, finYearId, startDate, endDate, filterParties: filterParties.map(i => i.value) } });


    useEffect(() => {
        if (allData?.totalCount) {
            setTotalCount(allData?.totalCount)
        }
    }, [allData, isLoading, isFetching])
    if (!supplierList || !styleList || !allData) {
        return (
            <tr>
                <td>
                    <Loader />
                </td>
            </tr>
        );
    }
    return (
        <>
            <Modal isOpen={parameter} widthClass={"bg-gray-200"} onClose={() => { setParameter(false) }} >
                <Parameter onClose={() => { setParameter(false) }} startDate={startDate} setStartDate={setStartDate} allSuppliers={supplierList?.data ? supplierList?.data : []} endDate={endDate} setEndDate={setEndDate}
                    filterParties={filterParties} setFilterParties={setFilterParties}
                />
            </Modal>
            <div className="flex flex-col w-full h-[95%] overflow-auto">
                <div className="md:flex md:items-center md:justify-between page-heading p-2">
                    <div className="heading text-center md:mx-10">Cutting Register</div>
                    <div className="flex">
                        <button className='w-7 h-8' onClick={() => setParameter(true)}>
                            <img src={parameterIcon} alt='parameter' />
                        </button>
                        <div className="flex sub-heading justify-center md:justify-start items-center">
                            <label className="text-white text-sm rounded-md m-1  border-none">
                                Show Entries
                            </label>
                            <select
                                value={dataPerPage}
                                onChange={(e) => setDataPerPage(e.target.value)}
                                className="h-6 w-40 border border-gray-500 rounded"
                            >
                                {showEntries.map((option) => (
                                    <option value={option.value}>{option.show}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div
                    className="h-[500px] overflow-auto "
                >
                    <table className="table-fixed text-center w-full">
                        <thead className="border-2 table-header ">
                            <tr className='h-2'>
                                <th className="border-2  top-0 stick-bg ">
                                    <label>S.no.</label>
                                </th>
                                <th className="border-2  top-0 stick-bg ">
                                    <label>Doc.Id</label>
                                </th>
                                <th className="border-2  top-0 stick-bg ">
                                    <label>Doc.Date</label>
                                </th>
                                <th className="border-2  top-0 stick-bg ">
                                    <label>Style</label>
                                </th>
                                <th className="border-2  top-0 stick-bg ">
                                    <label>Supplier</label>
                                </th>
                                <th className="border-2  top-0 stick-bg ">
                                    <label>Delivery Details</label>
                                </th>
                            </tr>
                        </thead>
                        <tbody className="border-2">
                            {(allData?.data ? allData?.data : []).map((dataObj, index) => (
                                <Fragment
                                    key={index}
                                >
                                    <tr
                                        className={` table-row ${(currentSelectedIndex === index) ? "border-2 border-black" : ""}`}
                                        onClick={() => currentSelectedIndex === index ? setCurrentSelectedIndex("") : setCurrentSelectedIndex(index)}
                                    >
                                        <td className="py-1"> {index + 1}</td>
                                        <td className="py-1"> {dataObj.docId}</td>
                                        <td className="py-1">
                                            {getDateFromDateTime(dataObj.createdAt)}{" "}
                                        </td>
                                        <td className='py-1'>{dataObj?.Style?.name}</td>
                                        <td className='py-1'>{dataObj?.Party?.aliasName}</td>
                                        <td className='py-1' onClick={(e) => { e.stopPropagation(); setDeliveryDetailsPopupIndex(index) }}>{VIEW}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={6}>
                                            {(currentSelectedIndex === index) &&
                                                <CuttingOrderDetails id={dataObj.id} />
                                            }
                                        </td>
                                    </tr>
                                    <Modal onClose={() => setDeliveryDetailsPopupIndex("")} isOpen={Number.isInteger(deliveryDetailsPopupIndex)} widthClass={"w-[90%]"} >
                                        <CuttingDelivery id={dataObj.id} />
                                    </Modal>
                                </Fragment>
                            ))}
                        </tbody>
                    </table>
                </div>
                <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    forcePage={pageNumberToReactPaginateIndex(currentPageNumber)}
                    pageCount={Math.ceil(totalCount / dataPerPage)}
                    marginPagesDisplayed={1}
                    onPageChange={handleOnclick}
                    containerClassName={"flex justify-center m-2 gap-5 items-center"}
                    pageClassName={"border custom-circle text-center"}
                    disabledClassName={"p-1 bg-gray-200"}
                    previousLinkClassName={"border p-1 text-center"}
                    nextLinkClassName={"border p-1"}
                    activeClassName={"bg-[#388087] text-white px-2"} />
            </div>
        </>
    );
};

export default CuttingRegister;
