import React from 'react'
import { DropdownInput, MultiSelectDropdown } from '../../Inputs';
import { dropDownListObject, multiSelectOption } from '../../Utils/contructObject';
import { useGetProcessQuery } from '../../redux/ErpServices/processMasterServices';
import { getCommonParams } from '../../Utils/helper';

const ProcessDropdown = ({ multiSelect = true, withoutLabel = true, readOnly, name, selected, setSelected, isYarn, isFabric, isTrims, isFinish, isSpecialFinish, clear }) => {
    const { token, ...params } = getCommonParams();
    const { data: processList } = useGetProcessQuery({ params: { ...params, isYarn, isFabric, isTrims, isFinish, isSpecialFinish } });
    return (
        <>
            {withoutLabel ?
                <select value={selected} onChange={(e) => setSelected(e.target.value)} className='w-full table-data-input'>
                    <option value="">Select</option>
                    {(processList ? processList.data : []).map(item =>
                        <option key={item.id} value={item.id}>{item.name}</option>
                    )}
                </select>
                :
                <>
                    {multiSelect ?
                        <MultiSelectDropdown readOnly={readOnly} name={name} selected={selected} setSelected={setSelected}
                            options={multiSelectOption(processList ? processList.data : [], "name", "id")} />
                        :
                        <DropdownInput readOnly={readOnly} name={name} value={selected} setValue={setSelected} clear={clear}
                            options={dropDownListObject(processList ? processList.data : [], "name", "id")} />
                    }
                </>
            }
        </>
    )
}

export default ProcessDropdown
