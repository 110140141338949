import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  useAddPoMutation,
  useDeletePoMutation,
  useUpdatePoMutation,
  useGetPoQuery,
  useGetPoByIdQuery
} from "../../../redux/ErpServices/PoServices";
import { useGetPartyQuery, useGetPartyByIdQuery } from "../../../redux/ErpServices/PartyMasterServices";
import { useGetPaytermMasterQuery } from "../../../redux/ErpServices/PayTermMasterServices";
import { useGetTaxTemplateQuery } from '../../../redux/ErpServices/TaxTemplateServices';
import FormHeader from "../../../Basic/components/FormHeader";
import { toast } from "react-toastify";
import { LongDropdownInput, DisabledInput, DropdownInput, DateInput } from "../../../Inputs";
import { dropDownListObject, } from '../../../Utils/contructObject';
import { poTypes } from '../../../Utils/DropdownData';
import YarnPoItems from "./YarnPoItems";
import FabricPoItems from "./FabricPoItems";
import AccessoryPoItems from "./AccessoryPoItems"

import moment from "moment";
import PoSummary from "./PoSummary";
import Modal from "../../../UiComponents/Modal";
import { useReactToPrint } from "@etsoo/reactprint";
import PrintFormatGreyYarnPurchaseOrder from "../PrintFormat-PurchaseOrder"
import { useGetBranchQuery } from "../../../redux/services/BranchMasterService";
import PurchaseOrderFormReport from "./PurchaseOrderFormReport";
import { deliveryTypes } from "../../../Utils/DropdownData";

import { getCommonParams, isGridDatasValid } from "../../../Utils/helper";
const MODEL = "Purchase Order";


export default function Form() {

  const today = new Date()
  const componentRef = useRef();

  const [summary, setSummary] = useState(false);
  const [readOnly, setReadOnly] = useState(false);
  const [poItems, setPoItems] = useState([]);
  const [docId, setDocId] = useState("")
  const [id, setId] = useState("");
  const [date, setDate] = useState(moment.utc(today).format('YYYY-MM-DD'));
  const [taxTemplateId, setTaxTemplateId] = useState("");
  const [payTermId, setPayTermId] = useState("");
  const [dueDate, setDueDate] = useState("");
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: docId,
    pageStyle: ` `
  });

  const [transType, setTransType] = useState("GreyYarn");
  const [supplierId, setSupplierId] = useState("");

  const [discountType, setDiscountType] = useState("Percentage");
  const [discountValue, setDiscountValue] = useState(0);

  const [remarks, setRemarks] = useState("")

  const [formReport, setFormReport] = useState(false);

  const [searchValue, setSearchValue] = useState("");
  const [deliveryType, setDeliveryType] = useState("")
  const [deliveryToId, setDeliveryToId] = useState("")

  const childRecord = useRef(0);

  const { branchId, companyId, finYearId, userId } = getCommonParams()

  const branchIdFromApi = useRef(branchId);
  const params = {
    branchId, companyId, finYearId
  };

  const { data: supplierList } =
    useGetPartyQuery({ params: { ...params } });


  const { data: taxTypeList } =
    useGetTaxTemplateQuery({ params: { ...params } });

  const { data: supplierDetails } =
    useGetPartyByIdQuery(supplierId, { skip: !supplierId });

  const { data: payTermList } =
    useGetPaytermMasterQuery({ params: { ...params } });

  const { data: allData, isLoading, isFetching } = useGetPoQuery({ params, searchParams: searchValue });
  const { data: branchList } = useGetBranchQuery({ params: { companyId } });

  const getNextDocId = useCallback(() => {
    if (id || isLoading || isFetching) return
    if (allData?.nextDocId) {
      setDocId(allData.nextDocId)
    }
  }, [allData, isLoading, isFetching, id])

  useEffect(getNextDocId, [getNextDocId])

  const {
    data: singleData,
    isFetching: isSingleFetching,
    isLoading: isSingleLoading,
  } = useGetPoByIdQuery(id, { skip: !id });

  const [addData] = useAddPoMutation();
  const [updateData] = useUpdatePoMutation();
  const [removeData] = useDeletePoMutation();

  const syncFormWithDb = useCallback((data) => {
    if (id) {
      setReadOnly(true);
    } else {
      setReadOnly(false);
    }
    setTransType(data?.transType ? data.transType : "GreyYarn");
    setDate(data?.createdAt ? moment.utc(data.createdAt).format("YYYY-MM-DD") : moment.utc(new Date()).format("YYYY-MM-DD"));
    setPoItems(data?.PoItems ? data.PoItems.map(item => {
      let newItem = structuredClone(item);
      if (data.transType === "GreyYarn" || data.transType === "DyedYarn") {
        newItem["weightPerBag"] = parseFloat(newItem.qty) / parseFloat(newItem.noOfBags)
      }
      return newItem
    }) : []);
    if (data?.docId) {
      setDocId(data?.docId)
    }
    if (data?.date) setDate(data?.date);
    setTaxTemplateId(data?.taxTemplateId ? data?.taxTemplateId : "");
    setPayTermId(data?.payTermId ? data?.payTermId : "");
    setDiscountType(data?.discountType ? data?.discountType : "Percentage")
    setDiscountValue(data?.discountValue ? data?.discountValue : "0")
    setSupplierId(data?.supplierId ? data?.supplierId : "");
    setDueDate(data?.dueDate ? moment.utc(data?.dueDate).format("YYYY-MM-DD") : "");
    setDeliveryType(data?.deliveryType ? data?.deliveryType : "")
    if (data) {
      setDeliveryToId(data?.deliveryType === "ToSelf" ? data?.deliveryBranchId : data?.deliveryPartyId)
    } else {
      setDeliveryToId("")
    }
    setRemarks(data?.remarks ? data.remarks : "")
    if (data?.branchId) {
      branchIdFromApi.current = data?.branchId
    }
  }, [id]);




  useEffect(() => {
    if (id) {
      syncFormWithDb(singleData?.data);
    } else {
      syncFormWithDb(undefined);
    }
  }, [isSingleFetching, isSingleLoading, id, syncFormWithDb, singleData]);

  const data = {
    transType, supplierId, dueDate, taxTemplateId, payTermId,
    branchId, id, userId,
    remarks,
    poItems: poItems.filter(po => po.yarnId || po.fabricId || po.accessoryId),
    deliveryType, deliveryToId,
    discountType,
    discountValue,
    finYearId
  }

  function isSupplierOutside() {
    if (supplierDetails) {
      return supplierDetails?.data?.City?.state?.name !== "TAMIL NADU"
    }
    return false
  }
  const validateData = (data) => {
    let mandatoryFields = ["uomId", "colorId", "qty", "price", "taxPercent"];
    if (transType === "GreyYarn" || transType === "DyedYarn") {
      mandatoryFields = [...mandatoryFields, ...["yarnId", "noOfBags"]]
    } else if (transType === "GreyFabric" || transType === "DyedFabric") {
      mandatoryFields = [...mandatoryFields, ...["fabricId", "designId", "gaugeId", "loopLengthId", "gsmId", "kDiaId", "fDiaId"]]
    } else if (transType === "Accessory") {
      mandatoryFields = [...mandatoryFields, ...["accessoryId", "sizeId"]]
    }
    return data.transType && data.supplierId && data.dueDate && data.taxTemplateId && data.payTermId
      && isGridDatasValid(data.poItems, false, mandatoryFields) && data.poItems.length !== 0
  }

  const handleSubmitCustom = async (callback, data, text) => {
    try {
      let returnData;
      if (text === "Updated") {
        returnData = await callback(data).unwrap();
      } else {
        returnData = await callback(data).unwrap();
      }

      if (returnData.statusCode === 1) {
        toast.error(returnData.message);
        return
      }
      setId("")
      syncFormWithDb(undefined)
      toast.success(text + "Successfully");
    } catch (error) {
      console.log("handle");
    }
  };


  const saveData = () => {
    if (!validateData(data)) {
      toast.info("Please fill all required fields...!", { position: "top-center" })
      return
    }
    if (id) {
      handleSubmitCustom(updateData, data, "Updated");
    } else {
      handleSubmitCustom(addData, data, "Added");
    }
  }

  const deleteData = async () => {
    if (id) {
      if (!window.confirm("Are you sure to delete...?")) {
        return;
      }
      try {
        await removeData(id)
        setId("");
        onNew();
        toast.success("Deleted Successfully");
      } catch (error) {
        toast.error("something went wrong");
      }
    }
  };

  const handleKeyDown = (event) => {
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if ((event.ctrlKey || event.metaKey) && charCode === "s") {
      event.preventDefault();
      saveData();
    }
  };

  const onNew = () => {
    setId("");
    setSearchValue("");
    setReadOnly(false);
    syncFormWithDb(undefined)
    getNextDocId()
  };


  const tableHeadings = ["PoNo", "PoDate", "transType", "DueDate", "Supplier"]
  const tableDataNames = ['dataObj?.id', 'dataObj.active ? ACTIVE : INACTIVE']
  useEffect(() => {
    if (id) return
    setPoItems([]);
    setSupplierId("")
  }, [transType, id])

  const allSuppliers = supplierList ? supplierList.data : []

  function filterSupplier() {
    let finalSupplier = []
    if (transType.toLowerCase().includes("yarn")) {
      finalSupplier = allSuppliers.filter(s => s.yarn)
    } else if (transType.toLowerCase().includes("fabric")) {
      finalSupplier = allSuppliers.filter(s => s.fabric)
    } else {
      finalSupplier = allSuppliers.filter(s => s.PartyOnAccessoryItems.length > 0)
    }
    return finalSupplier
  }
  let supplierListBasedOnSupply = filterSupplier()

  return (
    <div
      onKeyDown={handleKeyDown}
      className="md:items-start md:justify-items-center grid h-full bg-theme overflow-auto">
      <Modal isOpen={summary} onClose={() => setSummary(false)} widthClass={"p-10"}>
        <PoSummary
          remarks={remarks}
          setRemarks={setRemarks}
          discountType={discountType}
          setDiscountType={setDiscountType}
          discountValue={discountValue}
          setDiscountValue={setDiscountValue}
          poItems={poItems} taxTypeId={taxTemplateId} readOnly={readOnly} isSupplierOutside={isSupplierOutside()} />
      </Modal>
      <Modal isOpen={formReport} onClose={() => setFormReport(false)} widthClass={"px-2 h-[90%] w-[90%]"}>
        <PurchaseOrderFormReport
          heading={MODEL}
          tableHeaders={tableHeadings}
          tableDataNames={tableDataNames}
          loading={
            isLoading || isFetching
          }
          tableWidth="100%"
          data={allData?.data}
          onClick={(id) => {
            setId(id);
            setFormReport(false);
          }
          }
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
      </Modal>
      <div className="hidden">
        <PrintFormatGreyYarnPurchaseOrder
          remarks={remarks}
          discountType={discountType}
          poType={transType}
          discountValue={discountValue}
          innerRef={componentRef}
          poNumber={docId} poDate={date} dueDate={dueDate} payTermId={payTermId}
          poItems={poItems.filter(item => item.yarnId || item.accessoryId || item.fabricId)}
          supplierDetails={supplierDetails ? supplierDetails.data : null} singleData={singleData ? singleData.data : null}
          deliveryType={deliveryType} deliveryToId={deliveryToId} taxTemplateId={taxTemplateId} />
      </div>
      <div className="flex flex-col frame w-full h-full">
        <FormHeader
          onNew={onNew}
          model={MODEL}
          saveData={saveData}
          setReadOnly={setReadOnly}
          deleteData={deleteData}
          onPrint={id ? handlePrint : null}
          openReport={() => { setFormReport(true) }}
          childRecord={childRecord.current}
        />
        <div className="flex-1 grid gap-x-2">
          <div className="col-span-3 grid overflow-auto">
            <div className='col-span-3 grid overflow-auto'>
              <div className='mr-1'>
                <div className={`grid`}>
                  <div className={"flex flex-col"}>
                    <fieldset className='frame rounded-tr-lg rounded-bl-lg w-full border border-gray-600 px-3 overflow-auto'>
                      <legend className='sub-heading'>Purchase Info</legend>
                      <div className='flex flex-col justify-center items-start flex-1 w-full'>
                        <div className="grid grid-cols-5 w-full">
                          <DisabledInput name="Po no." value={docId} required={true}
                          />
                          <DateInput name="Po Date" value={date} type={"date"} required={true} readOnly={readOnly} disabled />
                          <DropdownInput name="Po Type"
                            options={poTypes}
                            value={transType} setValue={setTransType} required={true} readOnly={id} />
                          <div className="col-span-2">
                            <LongDropdownInput name="Supplier" options={dropDownListObject(supplierListBasedOnSupply, "aliasName", "id")} value={supplierId} setValue={setSupplierId} required={true} readOnly={id} />
                          </div>
                          <DateInput name="Due Date" value={dueDate} setValue={setDueDate} required={true} readOnly={readOnly} />
                          <DropdownInput name="Pay Terms" options={dropDownListObject(payTermList ? payTermList.data : [], "name", "id")} value={payTermId} setValue={(value) => { setPayTermId(value); }} required={true} readOnly={readOnly} />
                          <DropdownInput name="Tax Type" options={dropDownListObject(taxTypeList ? taxTypeList.data : [], "name", "id")} value={taxTemplateId} setValue={setTaxTemplateId} required={true} readOnly={readOnly} />
                          <DropdownInput name="Delivery Type"
                            options={deliveryTypes}
                            value={deliveryType}
                            setValue={setDeliveryType}
                            required={true} readOnly={readOnly} />
                          {deliveryType
                            ?
                            <DropdownInput name="Delivery To" options={(deliveryType === "ToSelf") ? dropDownListObject(branchList ? branchList.data : [], "branchName", "id") : dropDownListObject(supplierListBasedOnSupply, "aliasName", "id")} value={deliveryToId} setValue={setDeliveryToId} required={true} readOnly={readOnly} />
                            :
                            <DropdownInput name="Delivery To" options={[]} value={deliveryToId} setValue={setDeliveryToId} required={true} readOnly={readOnly} />
                          }
                        </div>
                      </div>
                    </fieldset>
                    <fieldset className='frame rounded-tr-lg rounded-bl-lg rounded-br-lg my-1 w-full border border-gray-600 md:pb-5 flex flex-1 overflow-auto'>
                      <legend className='sub-heading'>Purchase Details</legend>
                      {transType.toLowerCase().includes("yarn")
                        ?
                        <YarnPoItems greyFilter={transType.toLowerCase().includes("grey")} id={id} transType={transType} taxTypeId={taxTemplateId} params={params} poItems={poItems} setPoItems={setPoItems} readOnly={readOnly} isSupplierOutside={isSupplierOutside()} />
                        :
                        (
                          transType.toLowerCase().includes("fabric")
                            ?
                            <FabricPoItems greyFilter={transType.toLowerCase().includes("grey")} id={id} transType={transType} taxTypeId={taxTemplateId} params={params} poItems={poItems} setPoItems={setPoItems} readOnly={readOnly} isSupplierOutside={isSupplierOutside()} />
                            :
                            <AccessoryPoItems id={id} transType={transType} taxTypeId={taxTemplateId} params={params} poItems={poItems} setPoItems={setPoItems} readOnly={readOnly} isSupplierOutside={isSupplierOutside()} />
                        )
                      }
                    </fieldset>
                    <div>
                      <button className="text-sm bg-sky-500 hover:text-white font-semibold hover:bg-sky-800 transition p-1 ml-5 rounded"
                        onClick={() => {
                          if (!taxTemplateId) {
                            toast.info("Please Select Tax Template !", { position: "top-center" })
                            return
                          }
                          setSummary(true)
                        }}>
                        View Po Summary
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}