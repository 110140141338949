import React, { useEffect, useState, useCallback, useRef, useMemo } from "react";
import {
  useGetCostingQuery,
  useGetCostingByIdQuery,
  useAddCostingMutation,
  useUpdateCostingMutation,
  useDeleteCostingMutation,
  useUploadCostingImageMutation,
  useRemoveCostingImageMutation,
} from "../../../redux/ErpServices/CostingServices";
import FormHeader from "../../../Basic/components/FormHeader";
import { toast } from "react-toastify";
import { DateInput, DisabledInput, DropdownInput, TextInput } from "../../../Inputs";
import PartyDropdown from "../../ReusableComponents/PartyDropdown";
import StyleItems from "./StyleItems";
import StyleWiseDetails from "./StyleWiseDetails";
import { useGetTemplateMasterByIdQuery } from "../../../redux/ErpServices/TemplateMasterService";
import ConsumptionTemplateDropdown from "../../ReusableComponents/ConsumptionTemplateDropdown";
import Modal from "../../../UiComponents/Modal";
import SizeTemplateDropdown from "../../ReusableComponents/SizeTemplateDropdown";
import moment from "moment";
import { getCommonParams, getDateFromDateTime, getDateFromDateTimeToDisplay, imageFormatter, isGridDatasValid } from "../../../Utils/helper";
import IncoTermDropdown from "../../ReusableComponents/IncoTermDropdown";
import SearchReport from "./SearchReport";
import BrowseSingleImage from "../../ReusableComponents/BrowseSingleImage";
import AttachmentsList from "./AttachmentsList";
import OverallCostingSummary from "./OverallCostingSummary";
import CurrencyDropdown from "../../ReusableComponents/CurrencyDropdown";
import { converterOnline } from "../../../Utils/exchangeRates";
import { useGetCurrencyMasterByIdQuery } from "../../../redux/ErpServices/CurrencyMasterServices";
import { CLOCK_ICON, REFRESH_ICON, TICK_ICON, EMPTY_IMAGE_ICON, VIEW, VIEW_LITE } from "../../../icons";
import { useGetPartyByIdQuery } from "../../../redux/ErpServices/PartyMasterServices";
import RatioDropdown from "../../ReusableComponents/RatioDropdown";
import { useDispatch, useSelector } from "react-redux";
import { push } from "../../../redux/features/opentabs";
import { sendNotificationToAdmin } from "../../../CustomHooks/useNotification";
import useGetUserDetails from "../../../CustomHooks/useGetUserDetails";
import AttachmentsListForCreate from "./AttachmentsListForCreate";

const MODEL = "Costing";

export default function Form() {
  const openTabs = useSelector((state) => state.openTabs);
  const previewCostingId = useMemo(() => openTabs.tabs.find(i => i.name === "COSTING")?.previewId, [openTabs]);

  const [readOnly, setReadOnly] = useState(false);
  const [id, setId] = useState("");
  const [docId, setDocId] = useState("");
  const [docDate, setDocDate] = useState(moment(new Date()).format("DD-MM-YYYY"))
  const [styleRefNo, setStyleRefNo] = useState('');
  const [targetRate, setTargetRate] = useState("");
  const [partyId, setPartyId] = useState("");
  const [orderQty, setOrderQty] = useState(0);
  const [styleItemDetails, setStyleItemDetails] = useState([]);
  const [conversionValue, setConversionValue] = useState(0);
  const [consumptionTemplateId, setConsumptionTemplateId] = useState("");
  const [cameraOpen, setCameraOpen] = useState(false);
  const [sizeTemplateId, setSizeTemplateId] = useState("");
  const [commercialCostingDetails, setCommercialCostingDetails] = useState([]);
  const [incoTermId, setIncoTermId] = useState("");
  const [ratioId, setRatioId] = useState('');
  const [styleImage, setStyleImage] = useState("");
  const [formReport, setFormReport] = useState(false);
  const [attachmentsOpen, setAttachmentsOpen] = useState(false);
  const [currencyId, setCurrencyId] = useState("");
  const [fabricDesc, setFabricDesc] = useState("");
  const [currentVersion, setCurrentVersion] = useState("");
  const [garmentLossPercent, setGarmentLossPercent] = useState(0);
  const [transportCostPercent, setTransportCostPercent] = useState(0);
  const [userDocDate, setUserDocDate] = useState('');
  const [costingRemarks, setCostingRemarks] = useState('')

  const [attachments, setAttachments] = useState([]);


  const isVersionChanged = useRef(false)

  const { data: templateDetails } = useGetTemplateMasterByIdQuery(consumptionTemplateId, { skip: !consumptionTemplateId });

  const [currentSelectedStyleIndex, setCurrentSelectedStyleIndex] = useState("");


  const [searchValue, setSearchValue] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (!previewCostingId) return
    setId(previewCostingId);
    dispatch(push({ name: "COSTING", previewId: null }))
  }, [previewCostingId, dispatch])

  const { token, ...params } = getCommonParams();

  const { data: allData, isLoading, isFetching } = useGetCostingQuery({ params, searchParams: searchValue });
  const {
    data: singleData,
    isFetching: isSingleFetching,
    isLoading: isSingleLoading,
  } = useGetCostingByIdQuery(id, { skip: !id });

  const getNextDocId = useCallback(() => {
    if (id || isLoading || isFetching) return
    if (allData?.nextDocId) {
      setDocId(allData.nextDocId)
    }
  }, [allData, isLoading, isFetching, id])

  useEffect(getNextDocId, [getNextDocId])

  const [addData] = useAddCostingMutation();
  const [updateData] = useUpdateCostingMutation();
  const [removeData] = useDeleteCostingMutation();

  const syncFormWithDb = useCallback(
    (data) => {
      if (id) setReadOnly(true);
      else setReadOnly(false);
      if (data?.docId) {
        setDocId(data?.docId);
      }
      setAttachments([]);
      setStyleImage(data?.styleImage || '');
      setDocDate(getDateFromDateTimeToDisplay(data?.docDate || new Date()))
      setStyleRefNo(data?.styleRefNo ? data.styleRefNo : "");
      setConsumptionTemplateId(data?.consumptionTemplateId || "");
      setPartyId(data?.partyId || '');
      setOrderQty(data?.orderQty || 0);
      setTargetRate(data?.targetRate || 0);
      setConversionValue(data?.conversionValue || '1');
      setSizeTemplateId(data?.sizeTemplateId || '');
      setIncoTermId(data?.incoTermId || '');
      setRatioId(data?.ratioId || '');
      setStyleItemDetails(data?.CostingStyleItemDetails || []);
      setCurrentSelectedStyleIndex(data?.CostingStyleItemDetails ? 0 : '');
      setCommercialCostingDetails(data?.CommercialCostingDetails || []);
      setCurrencyId(data?.currencyId || "");
      setFabricDesc(data?.fabricDesc || '');
      setTransportCostPercent(data?.transportCostPercent || '1.5');
      setGarmentLossPercent(data?.garmentLossPercent || '2');
      setCostingRemarks(data?.costingRemarks || '');
      setUserDocDate(data?.userDocDate ? getDateFromDateTime(data?.userDocDate) : getDateFromDateTime(new Date()))
      if (data) {
        setCurrentVersion(data?.id || '');
      }
      // if ((isVersionChanged.current)) {
      //   console.log(isVersionChanged.current, "current")
      //   // isVersionChanged.current = false;
      // } else {
      //   if (data?.AmendmentCosting?.id) {
      //     setId(data?.AmendmentCosting?.id)
      //   }
      // }
      if (!data) {
        setCurrentSelectedStyleIndex('')
      }
    },
    [id]
  );

  useEffect(() => {
    syncFormWithDb(singleData?.data);
  }, [isSingleFetching, isSingleLoading, id, syncFormWithDb, singleData]);

  const { branchId, userId, finYearId } = getCommonParams()

  const data = {
    id, branchId, userId, finYearId, styleRefNo, consumptionTemplateId, partyId, orderQty, targetRate, conversionValue, sizeTemplateId, ratioId, incoTermId, currencyId, fabricDesc, styleItemDetails, commercialCostingDetails, garmentLossPercent: garmentLossPercent.toString(), transportCostPercent: transportCostPercent.toString(), userDocDate, costingRemarks
  }
  const isApproved = singleData?.data?.isApproved;
  const isOrderConfirmed = singleData?.data?.isOrderConfirmed;

  const validateData = (data) => {
    const mandatoryFields = ["processId", "rate"];
    if (!data?.styleRefNo) { toast.info("Style Ref is Required...!"); return false }
    if (!data?.consumptionTemplateId) { toast.info("Consumption Template is Required...!"); return false }
    if (!data?.partyId) { toast.info("Customer is Required...!"); return false }
    if (!data.styleItemDetails.length > 0) { toast.info("Should Have Atleast one Style Item...!"); return false }
    if (!isGridDatasValid(data.commercialCostingDetails, false, [...mandatoryFields, "type"])) { toast.info("Some Commercial Detail missing"); return }
    if (!data.styleItemDetails.every(i => (i?.yarnCostingDetails || []) && isGridDatasValid(i.yarnCostingDetails, false, [...mandatoryFields, 'articleId', "uomId"]))) { toast.info("Some Yarn Detail Missing...!"); return }
    if (!data.styleItemDetails.every(i => (i?.fabricCostingDetails || []) && isGridDatasValid(i.fabricCostingDetails, false, [...mandatoryFields, "uomId"]))) { toast.info("Some Fabric Detail Missing...!"); return }
    if (!data.styleItemDetails.every(i => (i?.accessoryCostingDetails || []) && isGridDatasValid(i.accessoryCostingDetails, false, [...mandatoryFields, 'articleId', "uomId", 'consumptionQty']))) { toast.info("Some Trim Detail Missing...!"); return }
    if (!data.styleItemDetails.every(i => (i?.cmtCostingDetails || []) && isGridDatasValid(i.cmtCostingDetails, false, [...mandatoryFields, "uomId", 'consumptionQty']))) { toast.info("Some CMP Detail Missing...!"); return }
    return true
  }

  const handleSubmitCustom = async (callback, data, text) => {
    try {
      let formData = data;
      if (text === "Added") {
        formData = new FormData();
        const { commercialCostingDetails, styleItemDetails, ...newObj } = data;
        const fields = Object.keys(newObj);
        for (let index = 0; index < fields.length; index++) {
          const field = fields[index];
          formData.append(field, data[field]);
        }
        formData.append("image", styleImage);
        formData.append("commercialCostingDetails", JSON.stringify(commercialCostingDetails))
        formData.append("styleItemDetails", JSON.stringify(styleItemDetails))
        for (let index = 0; index < attachments.length; index++) {
          const attachment = attachments[index];
          const file = new File([attachment.filePath], attachment.name, { type: attachment.filePath.type });
          formData.append('attachments', file);
        }
      }
      let returnData = await callback(formData).unwrap();
      if (returnData.statusCode === 1) {
        return toast.error(returnData.message)
      } else {
        if (text === "Updated") {
          // setId("")
          // syncFormWithDb(undefined)
        } else {
          setId(returnData.data.id)
        }
        toast.success(text + "Successfully");
      }
    } catch (error) {
      console.log("handle");
    }
  };

  const saveData = () => {
    if (!validateData(data)) {
      return;
    }
    if (!window.confirm("Are you sure save the details ...?")) {
      return;
    }
    if (id) {
      handleSubmitCustom(updateData, data, "Updated");
    } else {
      handleSubmitCustom(addData, data, "Added");
    }
  };

  const deleteData = async () => {
    if (id) {
      if (!window.confirm("Are you sure to delete...?")) {
        return;
      }
      try {
        await removeData(id)
        setId("");
        toast.success("Deleted Successfully");
      } catch (error) {
        toast.error("something went wrong");
      }
    }
  };

  const handleKeyDown = (event) => {
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if ((event.ctrlKey || event.metaKey) && charCode === "s") {
      event.preventDefault();
      saveData();
    }
  };

  const onNew = () => {
    setId("");
    setSearchValue("");
    syncFormWithDb(undefined)
  };

  const [uploadImageApi] = useUploadCostingImageMutation();
  const [removeImageApi] = useRemoveCostingImageMutation();

  const isAdmin = useGetUserDetails()

  async function uploadImage() {
    const formData = new FormData();
    if (!(styleImage instanceof File)) return toast.info("Image is Empty");
    formData.append("imageType", "styleImage");
    formData.append("image", styleImage);
    const returnData = await uploadImageApi({ id, data: formData }).unwrap();
    if (returnData.statusCode === 0) {
      toast.success("Uploaded Successfully")
    } else {
      toast.error("Failed")
    }
  }
  async function removeImage() {
    if (id) {
      const returnData = await removeImageApi({ id, imageType: "styleImage" }).unwrap();
      if (returnData.statusCode === 0) {
        toast.success("Deleted Successfully")
      } else {
        toast.error("Failed")
      }
    } else {
      setStyleImage('')
    }
  }

  async function uploadAttachment(image, name) {
    const formData = new FormData();
    if (!(image instanceof File)) {
      toast.info("Attachment is Empty");
      return false
    }
    if (!(name)) {
      toast.info("Name is Empty");
      return false
    }
    formData.append("imageType", "attachment");
    formData.append("name", name);
    formData.append("image", image);
    const returnData = await uploadImageApi({ id, data: formData }).unwrap();
    if (returnData.statusCode === 0) {
      toast.success("Uploaded Successfully")
      return true
    } else {
      toast.error("Error is Upload")
      return false
    }
  }
  async function removeAttachment(id) {
    const returnData = await removeImageApi({ id, imageType: "attachment" }).unwrap();
    if (returnData.statusCode === 0) {
      toast.success("Deleted Successfully")
    } else {
      toast.error("Error is Upload")
    }
  }

  const { data: PartyData } = useGetPartyByIdQuery(partyId, { skip: !partyId })

  useEffect(() => {
    if (id) return
    if (PartyData?.data?.currencyId) {
      setCurrencyId(PartyData?.data?.currencyId || '')
    }
    if (PartyData?.data?.incoTermId) {
      setIncoTermId(PartyData?.data?.incoTermId || '')
    }
  }, [id, PartyData])

  const { data: currencyData } = useGetCurrencyMasterByIdQuery(currencyId, { skip: !currencyId })

  const fetchConversionValue = useCallback(async () => {
    const value = await converterOnline("INR", currencyData?.data?.code)
    setConversionValue(value ? value.toString() : '1');
  }, [currencyData])

  const fetchRate = useCallback(async () => {
    setStyleItemDetails(prev => {
      let newItems = structuredClone(prev);
      newItems = newItems.map(styleItem => {
        let newStyleItem = structuredClone(styleItem)
        const arr = ["yarnCostingDetails", "fabricCostingDetails", "accessoryCostingDetails", "cmtCostingDetails"];
        for (let index = 0; index < arr.length; index++) {
          const costingDetail = arr[index];
          newStyleItem[costingDetail] = styleItem[costingDetail].map(i => ({ ...i, refetch: true }))
        }
        return newStyleItem
      })
      return newItems
    })
  }, [])


  useEffect(() => {
    if (id) return
    fetchConversionValue()
  }, [id, fetchConversionValue])

  const isSubmittedToApproval = (singleData?.data?.submitToApproval)

  async function handleSubmitToApproval() {
    let returnData = await updateData({ id, submitToApproval: true, userId }).unwrap();
    if (returnData.statusCode === 1) {
      return toast.error(returnData.message)
    } else {
      sendNotificationToAdmin()
      toast.success(returnData.message);
    }
  }

  async function handleCloneCosting() {
    let returnData = await updateData({ id, cloneCosting: true, finYearId, branchId, userId }).unwrap();
    if (returnData.statusCode === 1) {
      return toast.error(returnData.message)
    } else {
      setId(returnData?.data?.id)
      toast.success(returnData.message);
    }
  }


  return (
    <div className="w-full h-full">
      <Modal isOpen={cameraOpen} onClose={() => setCameraOpen(false)} widthClass={'p-0'}>
        <BrowseSingleImage image={styleImage} setImage={setStyleImage} uploadImage={id ? uploadImage : null} removeImage={removeImage} />
      </Modal>
      <Modal isOpen={attachmentsOpen} onClose={() => setAttachmentsOpen(false)}
      >
        {id ?
          <AttachmentsList readOnly={readOnly} attachments={singleData?.data?.CostingAttachments || []} uploadAttachment={uploadAttachment} removeAttachment={removeAttachment} />
          :
          <AttachmentsListForCreate attachments={attachments} setAttachments={setAttachments} />
        }
      </Modal>
      <Modal isOpen={formReport} onClose={() => setFormReport(false)} widthClass={"px-2 h-[90%] w-[90%]"}>
        <SearchReport
          heading={MODEL}
          onClick={(id) => {
            setId(id);
            setFormReport(false);
          }
          }
        />
      </Modal>
      <form
        onKeyDown={handleKeyDown}
        onSubmit={(e) => { e.preventDefault(); saveData() }}
        className="md:items-start md:justify-items-center grid h-full bg-theme"
      >
        <div className="flex flex-col frame w-full h-[580px] overflow-auto relative">
          <div className="sticky top-0 z-30 ">
            <FormHeader
              onNew={onNew}
              openReport={() => { setFormReport(true) }}
              onRefresh={() => {
                dispatch({
                  type: `Costing/invalidateTags`,
                  payload: ['Costing'],
                });
              }}
              model={MODEL}
              saveData={readOnly ? null : saveData}
              saveButtonType="submit"
              setReadOnly={(value) => {
                if (isApproved) return toast.info("Costing is Approved Cannot be Changed...!!!")
                if (!isAdmin) {
                  if (isSubmittedToApproval) return toast.info("Costing is Already Submitted to Approval...!!!")
                }
                setReadOnly(value)
              }
              }
              deleteData={deleteData}
              onClone={(id && readOnly) ? handleCloneCosting : null}
            />
          </div>
          <div className="grid gap-x-2">
            <div className={`grid grid-cols-12 `}>
              <fieldset className={`${id ? 'col-span-10' : 'col-span-10'} frame rounded-tr-lg rounded-bl-lg w-full border border-gray-600 overflow-auto`}>
                <legend className='sub-heading'>Costing</legend>
                <div className="grid grid-cols-4">
                  <DisabledInput name="Doc.Id" value={docId} />
                  <TextInput name={"Updated Date"} value={docDate} disabled />
                  <PartyDropdown required multiSelect={false} readOnly={readOnly} withoutLabel={false} userId={userId} name={"Customer"} selected={partyId} setSelected={setPartyId} />
                  <div className="flex">
                    <DateInput name={"User Doc Date"} value={userDocDate} setValue={setUserDocDate} disabled={readOnly} />
                    {!readOnly &&
                      <button onClick={() => fetchRate()}>{REFRESH_ICON}</button>
                    }
                  </div>
                  <TextInput name={"Style Ref No / Desc."} value={styleRefNo} setValue={setStyleRefNo} disabled={readOnly} required />
                  {/* <TextInput name={"Fabric Desc."} value={fabricDesc} setValue={setFabricDesc} disabled={readOnly} /> */}
                  <ConsumptionTemplateDropdown required key={id} multiSelect={false} withoutLabel={false} id={id} name={"Cons.Temp."} selected={consumptionTemplateId} setSelected={setConsumptionTemplateId} readOnly={readOnly} />
                  <TextInput name={"Order Qty"} value={orderQty} setValue={setOrderQty} disabled={readOnly} />
                  {/* <TextInput name={"Target Rate"} value={targetRate} setValue={setTargetRate} disabled={readOnly} /> */}
                  <SizeTemplateDropdown multiSelect={false} withoutLabel={false} readOnly={readOnly} name={"Size Template"}
                    selected={sizeTemplateId} setSelected={setSizeTemplateId} />
                  <RatioDropdown multiSelect={false} sizeTemplateId={sizeTemplateId} withoutLabel={false} readOnly={(!sizeTemplateId) || readOnly} name={"Ratio"}
                    selected={ratioId} setSelected={setRatioId} />
                  <IncoTermDropdown multiSelect={false} withoutLabel={false} readOnly={true} name={"IncoTerm"}
                    selected={incoTermId} setSelected={setIncoTermId} />
                  <CurrencyDropdown multiSelect={false} withoutLabel={false} readOnly={true} name={"Conversion Currency"}
                    selected={currencyId} setSelected={setCurrencyId} />
                  <div className="flex">
                    <TextInput name={"Conversion Value"} value={conversionValue} setValue={setConversionValue} disabled={readOnly} />
                    {/* {!readOnly &&
                      <button onClick={() => fetchConversionValue()}>{REFRESH_ICON}</button>
                      } */}
                  </div>
                  <TextInput name={"Remarks"} value={costingRemarks} setValue={setCostingRemarks} disabled={readOnly} />

                  {id && (((singleData?.data?.versions) || []).length > 1) &&
                    <DropdownInput name={"Version"} value={currentVersion}
                      setValue={(value) => {
                        setId(value);
                        isVersionChanged.current = true;
                      }} options={(singleData?.data?.versions) || []} disabled={readOnly} />
                  }
                  <div className="flex col-span-2">
                    {!readOnly &&
                      <div className="flex items-center justify-center p-5">
                        <button type="button" onClick={() => { setCameraOpen(true) }} className="bg-[#388087] text-white p-1 text-sm rounded">
                          Upload&nbsp;Image
                        </button>
                      </div>
                    }

                    <div className="flex items-center justify-center p-5">
                      <button type="button" onClick={() => { setAttachmentsOpen(true) }} className="bg-[#388087] text-white px-1 p-1 text-sm rounded">
                        Attachments
                        {((singleData?.data?.CostingAttachments || []).length > 0) && <VIEW_LITE />}
                      </button>
                    </div>
                    {id && (!isSubmittedToApproval) &&
                      <div className="flex items-center justify-center">
                        <button type="button" onClick={handleSubmitToApproval} className="bg-green-900 text-white text-sm rounded p-1">
                          Submit to Approval
                        </button>
                      </div>
                    }
                    {
                      <>
                        {isOrderConfirmed ?
                          <div className="flex items-center justify-center">
                            <button disabled className="bg-green-600 text-white rounded text-sm p-1">
                              Order Confirmed {TICK_ICON}
                            </button>
                          </div>
                          :
                          <>
                            {isApproved ?
                              <div className="flex items-center justify-center">
                                <button disabled className="bg-green-600 text-white rounded text-sm p-1">
                                  Approved {TICK_ICON}
                                </button>
                              </div>
                              :
                              <>
                                {isSubmittedToApproval &&
                                  <div className="flex items-center justify-center">
                                    <button disabled className="bg-yellow-700 text-white text-sm rounded p-1">
                                      Waiting For Approval...!!! {CLOCK_ICON}
                                    </button>
                                  </div>
                                }
                              </>
                            }
                          </>
                        }
                      </>
                    }
                  </div>
                </div>
              </fieldset>
              <div className="col-span-2 h-[150px] frame rounded-tr-lg rounded-bl-lg w-full flex items-center justify-center">
                {
                  (styleImage)
                    ?
                    <img src={imageFormatter(styleImage)} alt="" className="w-full h-full" />
                    :
                    <div className="w-full h-full flex items-center justify-center">
                      {EMPTY_IMAGE_ICON}
                    </div>
                }
              </div>
            </div>
            {consumptionTemplateId &&
              <fieldset className={"frame rounded-tr-lg rounded-bl-lg w-full border border-gray-600 overflow-auto p-1"}>
                <legend className='sub-heading'>Style Details</legend>
                <StyleItems singleData={singleData} id={id} readonly={readOnly} currentSelectedStyleId={currentSelectedStyleIndex} setCurrentSelectedStyleId={setCurrentSelectedStyleIndex} styleItemDetails={styleItemDetails} setStyleItemDetails={setStyleItemDetails} templateDetails={templateDetails} />
              </fieldset>
            }
            <div>
              {Number.isInteger(currentSelectedStyleIndex) &&
                <StyleWiseDetails userDocDate={userDocDate} PartyData={PartyData} id={id} targetRate={targetRate} readOnly={readOnly} commercialCostingDetails={commercialCostingDetails} setCommercialCostingDetails={setCommercialCostingDetails} conversionValue={conversionValue} key={currentSelectedStyleIndex} currentSelectedStyleId={currentSelectedStyleIndex} styleItemDetails={styleItemDetails} setStyleItemDetails={setStyleItemDetails} templateDetails={templateDetails} consumptionTemplateId={consumptionTemplateId} setConsumptionTemplateId={setConsumptionTemplateId} />
              }
              <OverallCostingSummary garmentLossPercent={garmentLossPercent} setGarmentLossPercent={setGarmentLossPercent}
                transportCostPercent={transportCostPercent} setTransportCostPercent={setTransportCostPercent}
                commercialCostingDetails={commercialCostingDetails} styleItemDetails={styleItemDetails} conversionValue={conversionValue} targetRate={targetRate} setTargetRate={setTargetRate} readOnly={readOnly} />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
