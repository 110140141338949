import React, { useEffect } from 'react'
import { DropdownInput, MultiSelectDropdown } from '../../Inputs';
import { dropDownListObject, multiSelectOption } from '../../Utils/contructObject';
import { getCommonParams } from '../../Utils/helper';
import { useGetTemplateMasterQuery } from '../../redux/ErpServices/TemplateMasterService';

const ConsumptionTemplateDropdown = ({ multiSelect = true, withoutLabel = true, readOnly, name, selected, setSelected, id, required }) => {
    const { token, ...params } = getCommonParams();
    const { data: templateList } = useGetTemplateMasterQuery({ params });
    useEffect(() => {
        if (!templateList?.data) return
        if (selected) return
        if (readOnly) return
        if (id) return
        if (templateList?.data?.length > 0) {
            setSelected(templateList?.data[0].id)
        }

    }, [setSelected, selected, readOnly, templateList, id])
    return (
        <>
            {withoutLabel ?
                <select value={selected} required={required} onChange={(e) => setSelected(e.target.value)} className='w-full table-data-input'>
                    <option value="">Select</option>
                    {(templateList ? templateList.data : []).map(item =>
                        <option key={item.id} value={item.id}>{item.name}</option>
                    )}
                </select>
                :
                <>
                    {multiSelect ?
                        <MultiSelectDropdown required={required} readOnly={readOnly} name={name} selected={selected} setSelected={setSelected}
                            options={multiSelectOption(templateList ? templateList.data : [], "name", "id")} />
                        :
                        <DropdownInput required={required} readOnly={readOnly} name={name} value={selected} setValue={setSelected}
                            options={dropDownListObject(templateList ? templateList.data : [], "name", "id")} />
                    }
                </>
            }
        </>
    )
}

export default ConsumptionTemplateDropdown
